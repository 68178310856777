@import 'variables.scss';

.outerContainer {
    span {
        color: $dark;
        font-size: small;
        font-weight: 500;
    }

    .container {
        // display: flex;
        // justify-content: center;

        .star {
            color: $warning;
            margin-right: 0.1rem;
        }

        .emptyStar {
            @extend .star;

            color: $lightGrey;
        }
    }
}

.hover {
    &:hover {
        cursor: pointer;
    }
}
