@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  margin-right: 20px;
  max-width: 175px;
  width: 100%;
  @media (max-width: 960px) {
    max-width: none;    
  }

  h2 {
    font-size: 12px;
    line-height: 14.4px;
    font-weight: 400;
    color: $black;
    text-transform: uppercase;
  }

  .first_section {
    margin-bottom: 30px;
  }

  .menu_list_container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: 960px) {
      flex-direction: row;   
      margin-bottom: 15px;
      overflow-y: auto;
      width: 100%;
    }

    .menu_item_container {
      display: flex;

      span {
        font-size: 14px;
        line-height: 16.8px;
        font-weight: 500;
      }
    }
  }
}