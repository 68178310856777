@import 'variables.scss';

.support {
    width: 46px;
    height: 46px;
    background-color: $darkPurple5;
    border: 2px solid $darkPurple5;
    border-radius: 25px;
    position: fixed;
    right: 20px;
    bottom: 30px;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 14px $lighterBorder;
    cursor: pointer;

    &:hover {
        background-color: $darkPurple7;
    }

    svg {
        color: $white;
        font-size: 24px;
    }
}