@import 'variables.scss';

.container {
  margin: 30px 20px 0px 30px;
  word-break: normal;
  max-width: 422px;

  .info {
    display: grid;
    border: 1px solid $lightBorder;
    border-radius: 8px;
    min-width: 300px;
    white-space: pre-wrap;

    .images_display {
      display: flex;
      flex-direction: column;

      .carousel_container {
        width: 300px;
        transition: height 0.5s;
        text-align: center;

        .carousel {
          .image {
            height: 200px;
            width: 100%;
          }
        }

        .agency_general_no_image {
          background-color: $lightPurple3;
          width: 100%;
        }

        img {
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          object-fit: contain;
        }

        .btnUpload {
          display: flex;
          width: 93%;
          justify-content: flex-end;
          align-items: center;

          .reports_pro_card_button {
            background-color: #5a00ff;
            height: 50px;
            width: 50px;
            position: absolute;

            svg {
              width: 20px;
              height: auto;
              color: white;
              stroke-width: 2.5;
            }
          }
        }
      }

      .images_dots {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 10px;

        .image_dot {
          width: 10px;
          cursor: pointer;
          height: 10px;
          border-radius: 50%;
          background-color: $white;
          border: 1px solid #5a00ff;

          &.selected {
            background-color: #5a00ff;
          }
        }
      }
    }

    .detailedInfo {
      padding: 20px;

      .marketValueSpan {
        color: $lightPurple1;
        font-weight: 600;
        cursor: pointer;
      }

      tr {
        vertical-align: inherit;
        font-size: 14px;
        color: $dark;

        td a {
          text-decoration: none;
        }

        td:first-child {
          font-weight: 400;
          padding-right: 26px;
          padding-bottom: 7px;
        }

        td:last-child {
          font-weight: 700;
          text-align: left;

          img {
            width: 15px;
            margin-right: 4px;
          }
        }
      }

      .socialButtons {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        // margin-left: calc(1rem + 90px);

        button {
          margin: 0 0.2rem 0.25rem;
        }
      }

      .agencyContent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 20px;

        h5 {
          margin: 0 0 1px 0;
          color: $black;
          font-size: 14px;
          font-weight: 400;
        }

        .agencyName {
          display: grid;
          color: $darkPurple9;

          &:hover {
            cursor: pointer;
            color: $darkPurple7;
          }

          span {
            display: flex;
            font-weight: 500;

            button: {
              width: 10px;
              padding-left: 10px;
            }

            .verifiedIco {
              position: relative;
              left: 5px;
              bottom: 2px;
              height: 22px;
              width: 22px;
            }
          }
        }

        .agencyButton {
          display: flex;
          justify-content: flex-end;

          .button {
            display: flex;
            background-color: $white;
            color: $lightPurple1;
            border: 1px solid $lightPurple1;
            font-size: 14px;
            font-weight: 700;

            .ico {
              position: relative;
              right: 10px;
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
  }
}