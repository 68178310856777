@import 'variables.scss';

.container {
    background-color: $light;
    width: 100%;
    height: calc(100% - 65px);
    position: absolute;
    top: 60px;
    left: 0;
    overflow-y: hidden;
}

.filter {
    position: fixed;
    bottom: 0;
    left: 0;
    top: 160px;
    width: 315px;
    z-index: 10;

}

.content {
    box-sizing: border-box;
    height: calc(100% - 100px);
    //margin: 0.6rem;
    margin-top: 0;
    padding-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $maxMedium) {
        padding-top: 150px;
    }
}

.TopBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100px;
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    border-bottom: 1px solid $lighterBorder;
    overflow: hidden;
    position: absolute;
    left: 0;
    z-index: 11;

    @media (max-width: $maxMedium) {
        flex-direction: column;
        height: 150px;
    }
}

.tableContainer {
    background-color: #ffffff;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}