@import 'variables.scss';

.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: $dark;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms;

  &.visible {
    opacity: 0.5;
    visibility: visible;
  }
}