@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 400px;
  min-width: 200px;  
  border: 1px solid $lightBorder;  
  border-radius: 8px 0px 0px 8px;
}

.content {
  border-top: 1px solid #bdbdbd;
  flex: 1 1 auto;
  overflow-y: auto;
  height: 100px;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.noChatSelected {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
