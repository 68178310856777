.container {
  // width: 300px;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.subTitle {
  font-size: 16px;
  font-weight: 400;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.item {
  display: flex;
}

.rank {
  font-size: 18px;
  font-weight: bold;
}

.rankImg {
  width: 48px;
  height: 32px;
  border-radius: 4px;
}

.athletes {
  color: #555;
}
