@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 20px;

  .header_container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .header {
      font-size: 24px;
      font-weight: 700;
      color: $black;
    }

    .sub_header {
      font-size: 16px;
      font-weight: 400;
      color: $black;
    }
  }

  img {
    object-fit: cover;
  }

  .reports_pro_header_container {
    h2 {
      font-weight: 700;
      margin-bottom: 5px;
    }
  }

  .reports_pro_cards_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
  }

  .reports_pro_no_reports {
    display: flex;
  }

  .reports_pro_loading {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dialog {
    width: 850px;
    overflow-y: auto;

    .dialogBody {
      width: auto;
      padding: 0;
      overflow: unset;
      border-radius: 8px;

      .body_container {
        display: flex;
        flex-direction: row;

        .left_container {
          display: flex;
          flex-direction: column;
          height: fit-content;
          width: 400px;
          padding: 20px;

          .avatar_container {
            display: flex;
            gap: 20px;

            .club_avatar {
              width: 90px;
              height: 90px;
            }

            .avatar_side {
              display: flex;
              flex-direction: column;
              gap: 10px;

              span {
                font-weight: 600;
                font-size: 16px;
                color: $black;
              }

              .avatar_button {
                background-color: white;
                border-radius: 8px;
                border: 1px solid $lightPurple1;
                color: $lightPurple1;
                font-weight: 700;
              }
            }
          }

          .images_container {
            display: flex;
            flex-direction: column;

            h1 {
              font-weight: 600;
              font-size: 16px;
            }

            .images_display {
              display: flex;
              flex-direction: row;
              height: 120px;

              .reports_pro_modal_thumbnail_image_container {
                height: 120px;
                width: 180px;

                .reports_pro_modal_thumbnail_image {
                  width: 180px;
                  height: 100px;
                }

                .reports_pro_modal_thumbnail_image_replacement {
                  width: 180px;
                  height: 100px;
                  background-color: $lightPurple3;
                }
              }

              .reports_pro_modal_thumbnail_delete_image_button {
                border: 1px solid $lightPurple1;
                background-color: $white;
                border-radius: 8px;
                width: 50px;
                height: 40px;
                align-self: end;
                margin-left: 10px;

                svg {
                  color: $lightPurple1;
                  width: 20px;
                  height: 20px;
                }
              }
            }

            .reports_pro_modal_thumbnail_add_image_button {
              background-color: $white;
              border-radius: 8px;
              border: 1px solid $lightPurple1;
              color: $lightPurple1;
              font-weight: 700;
              font-size: 14px;
              width: 140px;
              height: 40px;
            }

            .reports_pro_modal_thumbnail_update_image_button {
              background-color: $white;
              border-radius: 8px;
              border: 1px solid $lightPurple1;
              color: $lightPurple1;
              font-weight: 700;
              font-size: 14px;
              width: 70px;
              height: 40px;
            }
          }
        }

        .right_container {
          display: flex;
          flex-direction: column;
          height: fit-content;
          max-width: 379px;
          width: 100%;
          gap: 15px;
          border-left: 1px solid $lightBorder;

          h1 {
            padding-left: 20px;
          }

          input {
            background-color: $white;
          }

          span {
            color: $black;
            align-self: center;
            width: 150px;
            font-weight: 600;
            font-size: 14px;
          }

          .reports_pro_modal_title {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid $lightBorder;
            padding: 0px 20px 10px 20px;
            height: 40px;

            label {
              width: 350px;
            }
          }

          .reports_pro_modal_file_type {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid $lightBorder;
            padding: 0px 20px 10px 20px;
            height: 40px;

            label {
              margin-right: 10px;
            }
          }

          .reports_pro_modal_file {
            display: flex;
            flex-direction: row;
            padding: 0px 20px 0 20px;
            height: 40px;

            .reports_pro_modal_file_inner_container {
              span {
                color: $lightPurple1;
                font-weight: 600;
              }

              .reports_pro_modal_add_file_button {
                background-color: $white;
                border-radius: 8px;
                border: 1px solid $lightPurple1;
                color: $lightPurple1;
                font-weight: 600;
                font-size: 14px;
                width: 150px;
                height: 40px;
              }

              .reports_pro_modal_edit_file_button {
                background-color: $white;
                border-radius: 8px;
                border: 1px solid $lightPurple1;
                color: $lightPurple1;
                font-weight: 600;
                font-size: 14px;
                width: 70px;
                height: 40px;
                margin-left: 10px;
              }
            }
          }

          .reports_pro_modal_view {
            display: flex;
            flex-direction: row;
            padding: 10px 20px 10px 20px;
            border-top: 1px solid $lightBorder;
            height: 40px;

            label {
              margin-right: 10px;
            }
          }
        }
      }

      .dialog_footer {
        display: flex;
        border-top: 1px solid $lightBorder;
        padding: 10px;
        justify-content: flex-end;
        gap: 10px;

        .dialog_footer_button_delete {
          background-color: $red;
          border-radius: 8px;
          color: $white;
          font-weight: 600;
          width: 80px;
          margin-right: auto;
        }

        .dialog_footer_button_cancel {
          background-color: $white;
          border-radius: 8px;
          border: 1px solid $lightPurple1;
          color: $lightPurple1;
          font-weight: 600;
          width: 100px;
        }

        .dialog_footer_button_save {
          background-color: $lightPurple1;
          border-radius: 8px;
          border: 1px solid $lightPurple1;
          color: $white;
          font-weight: 600;
          width: 80px;
        }
      }
    }
  }

  .dialog_delete {
    border: solid 1px $darker;

    .dialog_delete_body {
      border-radius: 0 0 8px 8px;
    }

    .dialog_delete_footer {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
}
