@import 'variables.scss';

.dialogContainer {
    width: 80%;
    max-height: 80%;
}

.menu {
    background-color: $lightBorder;
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;
    padding: 0.65rem 0.95rem;
}

.usersList {
    margin: 20px 0;

    .userItem {
        border-bottom: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
    
        &:last-of-type {
            border-bottom: none;
        }  
        
        .button {            
            width: 200px !important;
        }
    }
}

.inviteOptions {
    display: flex;
    align-items: center;
    width: 100%;

    .inputContainer {
        flex-grow: 3;
        display: flex;
    }
}

.buttonSendMail {
    box-shadow: none;
    background-color: transparent;
    border: 0;
    padding: 6px 5px 0px 10px;

    &:hover {
        box-shadow: none;
        color: #000000;
    }
}