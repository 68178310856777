@import 'variables.scss';

.brflag,
.usflag,
.ptflag,
.spflag {
  width: 30px;
  height: 30px;
  position: relative;
  left: 80px;
  z-index: 13;

  @media (max-width: $maxSmall) {
    display: none;
  }
}

.languageButton {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 120px;

  button {
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    color: #ffffff;
    border-color: $purple7;
    background-color: transparent;
    height: 26px;
    width: 90px;
    margin-left: 60px;
    padding-left: 10px;
    font-size: 12px;
    position: relative;
    z-index: 2;

    &:focus {
      border: 1px solid white;
    }
  }
}

.arrowDown {
  color: white;
  position: relative;
  right: 8px;
  font-size: 16px;
  margin-left: -16px;
}