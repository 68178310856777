@import 'variables.scss';

.TopBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100px;
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    border-bottom: 1px solid $lighterBorder;
    overflow: hidden;
    position: absolute;
    left: 0;
    z-index: 11;

    @media (max-width: $maxMedium) {
        flex-direction: column;
        height: 150px;
    }
}

.container {
    background-color: $white;
    width: 100%;
    height: calc(100% - 65px);
    position: absolute;
    top: 60px;
    left: 0;
}

.content {
    margin: 40px auto;
    max-width: 1440px;
    box-sizing: border-box;
    padding: 30px;
    padding-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;

    @media (max-width: $maxMedium) {
        padding-top: 150px;
    }
}