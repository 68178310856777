@import 'variables.scss';

.buttons {
  padding: 0.5rem;
  float: left;
  font-size: 18px;
  font-weight: 600;

  @media (max-width: $maxMedium) {
    margin-top: 0.5rem;
  }

  // .saveButton {
  //   // margin-right: 1rem;
  // }
}

.containerWithoutSubfields {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;
}

.containerWithSubfields {
  display: grid;
}

.hasChartForEach {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;

  .radarChart {
    margin-top: 13rem;
  }
}
