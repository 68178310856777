@import 'variables.scss';

.sideSection {
  flex-basis: 55%;
  display: flex;
  flex-direction: column;
  align-self: stretch;

  @media (max-width: $maxSmall) {
    flex-basis: 100%;
    border-right: none;
  }
}
