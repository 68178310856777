@import 'variables.scss';

.label {
  display: flex;
  color: $dark;
  align-items: center;

  .select {
    padding: 0.375rem;
    color: $dark;
    outline-color: $lightPurple1;
    border: 1px solid $lightBorder;
    background-color: #fff;
    border-radius: 8px;

    &:focus {
      outline-color: $lightPurple1;
    }
  }
}