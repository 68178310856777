@import 'variables.scss';

.videoDescriptionPage {
  position: relative;
  height: calc(100vh - 60px);
  width: 100%;
  color: white;  
  opacity: 0.99;

  .backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh - 60px);
    width: 100%;
    object-fit: cover;
    filter: brightness(0.8);
    z-index: -2;
  }

  .colorOverlay {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh - 60px);
    width: 100%;
    background-color: #000;
    opacity: 0.5;
    z-index: -1;
  }

  .content {
    position: relative;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 6.25rem;
    padding-bottom: 5rem;
    max-width: 60%;
    max-height: calc(100% - 11.25rem);    
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .logo {
      width: 100px;
      margin-bottom: 10px;
    }

    h1 {
      font-size: 3.25rem;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .details {
      font-size: 1rem;
      font-weight: 500;
      margin-top: 20px;
      margin-bottom: 30px;
      span {
        margin: 0 5px;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .watchButton {
        background-color: $lightPurple1;
        color: white;
        padding: 20px 40px;
        border: none;
        border-radius: 8px;
        display: flex;
        align-items: center;      
        cursor: pointer;      
        margin-right: 10px;   
        font-size: 18px;
        font-weight: 800;
        line-height: 21px;
        text-decoration: none;

        .playIcon {
          font-size: 0.9rem;
          margin-left: 10px;
        }
      }

      .restartButton {
        background-color: transparent;
        color: $white;
        padding: 20px 40px;
        border: 1px solid $white;
        border-radius: 8px;
        display: flex;
        align-items: center;      
        cursor: pointer;      
        margin-right: 10px;   
        font-size: 18px;
        font-weight: 800;
        line-height: 21px;
        text-decoration: none;

        svg {
          font-size: 1.2rem;
          margin-left: 10px;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
      }

      .likeButton {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 17.778px;
        border-radius: 8px;
        font-size: 20px;
        line-height: 21px;
        cursor: pointer;                
      }

      .active {
        background-color: white;
        color: $green2;
        border: 1px solid white;
      }
    }

    .description {
      text-align: left;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.6;
      margin-bottom: 10px;
    }
  }
}
