@import 'variables.scss';

.container {
  padding: 30px;

  & .title {
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 10px;
  }

  & .subTitle {
    font-size: 16px;
  }

  .buttonsTop {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;

    .spanMale,
    .spanFemale {
      margin-right: 5px;
      font-size: 16px;
    }

    .spanFemale {
      margin-left: 20px;
    }
  }
}

.professionalCast {
  padding: 0 !important;
  height: calc(100% + 100px);

  .tableContainer {
    max-width: 100%;
    overflow-x: auto;
  }

  .targetingHeader {
    display: flex;
    text-align: left;
  }
  .clubColumn {
    text-align: left;
  }

  table {
    thead tr th {
      &:first-child {
        width: 0;
      }
      div {
        justify-content: left !important;
        font-weight: 600;
        font-size: 14px;

        &:first-child {
          margin-left: 1px;
        }
      }
    }

    tbody tr td div {
      text-align: left !important;
      float: left;

      &:first-child {
        margin-left: 2px;
      }
    }

    td {
      padding: 0.4rem !important;

      &:last-child {
        max-width: 185px;
        div {
          float: right;
          margin-right: 10px;
        }
      }
    }
  }

  .checkbox {
    color: $light;
    transform: translateY(-1px);
  }

  .targetingRow {
    display: flex;
    align-items: center;

    .menu {
      margin-right: 0.5rem;
    }

    button {
      font-size: small;
      padding: 0.3rem;
      white-space: nowrap;
      box-shadow: none;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(50vh);
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.lowPriority {
  @extend .circle;

  background-color: $info;
}

.mediumPriority {
  @extend .circle;

  background-color: $warning;
}

.highPriority {
  @extend .circle;

  background-color: $danger;
}

.errorContainer {
  p {
    margin: 0;
  }
}

.teamContainer {
  text-align: left;

  .contractDate {
    color: black;
    font-weight: normal;
  }
}

.nationality {
  text-align: center;
}

.loanedContract {
  text-align: left;
}

.athleteImg {
  max-width: 30px;
  max-height: 30px;
  margin: auto !important;
}

.nationalityFlag {
  text-align: left;

  img {
    height: 20px;
    width: auto;
  }
}

.buttonContainer {
  max-width: 193px;
}
