@import 'variables.scss';

.inputNumberContainer {
  display: flex;
  align-items: center;

  .button {
    width: 37px;
    height: 37px;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    padding: 11px;
    cursor: pointer;

    &:first-child {
      margin-right: 3px;
    }

    &:last-child {
      margin-left: 3px;
    }    

    &:hover {
      background-color: $lightPurple3;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #f5f5f5;
    }
  }

  .input{
    padding: 0.575rem 0.75rem;
    width: 100%;
    line-height: 1.5;
    border-radius: 8px;
    border: 1px solid $lightBorder;
    background-color: $light;
    color: $black;
    font-size: 0.9rem;
    outline-color: $lightPurple1;
    transition: border-color 300ms, background-color 300ms, box-shadow 200ms;
    margin: 0 5px;
  }

  .withButtons {
    padding: 0.575rem 0.75rem;
    width: 25px;
    line-height: 1.5;
    border-radius: 8px;
    border: 1px solid $lightBorder;
    background-color: $light;
    color: $black;
    font-size: 0.9rem;
    outline-color: $lightPurple1;
    transition: border-color 300ms, background-color 300ms, box-shadow 200ms;
    margin: 0 5px 0 5px;
    text-align: center;
  }
}
