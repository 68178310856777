@import 'variables.scss';

.videoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}

.playerWrapper {
    position: relative;
    background-color: $black;
    height: 100vh;
    overflow: hidden;
}

.player {
    // border: 2px solid #7b2cbf;
    object-fit: cover;
    padding: 0;
    margin: 0;
}

h2 {
color: #000000;
}