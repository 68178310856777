@import 'variables.scss';

.reports_pro_card_main_container {
  background-color: $white;
  margin: 20px 0px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid $lightBorder;

  .reports_pro_card_top_buttons {
    display: flex;
    justify-content: space-between;
    height: 0;
    position: relative;

    .reports_pro_card_settings_button {
      display: flex;
      height: 50px;
      width: 50px;
      border-radius: 100%;
      border: none;
      background-color: $lightPurple4;
      align-items: center;
      justify-content: center;
      color: $lightPurple1;
      margin: 10px;
      cursor: pointer;

      svg {
        width: 26px;
        height: 26px;
      }
    }

    .reports_pro_card_lock_button {
      display: flex;
      height: 19px;
      font-size: 13px;
      font-family: 'Barlow';
      font-weight: 500;
      border-radius: 11px;
      padding: 3px 10px;
      background-color: $light;
      margin: 10px;

      svg {
        font-size: 16px;
        margin-right: 4px;
      }
    }
  }

  .reports_pro_card_image_section {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $lightPurple3;
    height: 200px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    img {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }

  .reports_pro_card_button_container {
    display: flex;
    justify-content: flex-end;
    height: 0;

    .reports_pro_card_button {
      margin-top: -25px;
      margin-right: 15px;
      width: 50px;
      height: 50px;
      background-color: $lightPurple1;
      
      &:disabled {
        background-color: $lightPurple3;
      }

      svg {
        width: 20px;
        height: auto;
        color: white;
        stroke-width: 2.5;
      }
    }
  }

  .reports_pro_card_info {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .reports_pro_card_title {
      color: $black;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .reports_pro_card_group {
      a {
        color: $lightPurple1;
        font-weight: 600;
        font-size: 16px;
      }
    }

    .reports_pro_card_last_modified {
      color: $gray2;
      font-size: 14px;
      font-weight: 400px;
    }
  }
}

.reports_pro_card_avatar {
  border: 2px solid $white;
  height: 40px;
  width: 40px;
  margin: 5px 5px 5px -15px;
  font-weight: 600;
  background-color: $lightPurple3;
}

.reports_pro_card_avatar_number {
  border: 2px solid $white;
  height: 40px;
  width: 40px;
  margin: 5px 5px 5px -15px;
  font-weight: 600;
  background-color: $white;
}
