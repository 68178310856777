@import 'variables.scss';

.label_container {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0 15px 0 15px;
  width: 120px;
}

.connections {
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: $red2;
    border-radius: 4px;

    .icon {
      border-radius: 4px 0px 0px 4px !important;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $red;
    border-radius: 4px;
    height: 38px;
    width: 38px;

    img {
      height: 25px;
      width: 25px;
    }
  }
}

.negociacoes {
  display: flex;
  cursor: pointer;

  &.active {
    background-color: $orange3;
    border-radius: 4px;

    .icon {
      border-radius: 4px 0px 0px 4px !important;
    }
  }

  &:hover {
    background-color: $orange3;
    border-radius: 4px;

    .icon {
      border-radius: 4px 0px 0px 4px !important;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $orange2;
    border-radius: 4px;
    height: 38px;
    width: 38px;

    img {
      height: 25px;
      width: 25px;
    }
  }
}

.counter {
  position: relative;
  display: flex;
  justify-content: center;
  left: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 100%;
  padding: 2.5px;
  background-color: $danger;
  font-size: 9px;
  user-select: none;
  color: $light;
}

.negociacoes_active {
  @extend .negociacoes;

  background-color: $orange3;
  border-radius: 4px;

  .icon {
    border-radius: 4px 0px 0px 4px !important;
  }
}

.atletas {
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: $pink2;
    border-radius: 4px;

    .icon {
      border-radius: 4px 0px 0px 4px !important;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $pink1;
    border-radius: 4px;
    height: 38px;
    width: 38px;

    img {
      height: 25px;
      width: 25px;
    }
  }
}

.atletas_active {
  @extend .atletas;

  background-color: $pink2;
  border-radius: 4px;

  .icon {
    border-radius: 4px 0px 0px 4px !important;
  }
}

.clubes {
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: $green5;
    border-radius: 4px;

    .icon {
      border-radius: 4px 0px 0px 4px !important;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $green2;
    border-radius: 4px;
    height: 38px;
    width: 38px;

    img {
      height: 25px;
      width: 25px;
    }
  }
}

.clubes_active {
  @extend .clubes;

  background-color: $green5;
  border-radius: 4px;

  .icon {
    border-radius: 4px 0px 0px 4px !important;
  }
}

.agentes {
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: $gray6;
    border-radius: 4px;

    .icon {
      border-radius: 4px 0px 0px 4px !important;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray;
    border-radius: 4px;
    height: 38px;
    width: 38px;

    img {
      height: 25px;
      width: 25px;
    }
  }
}

.agentes_active {
  @extend .agentes;

  background-color: $gray6;
  border-radius: 4px;

  .icon {
    border-radius: 4px 0px 0px 4px !important;
  }
}