@import 'variables.scss';

.appBar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background-color: $lightPurple1;
  z-index: 12;

  @media print {
    display: none;
  }

  .leftItems {
    display: flex;
    align-items: center;
    flex: 0.7;
    z-index: 101;

    .barMenu {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 40px;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 5px;
      background-color: transparent;
      transition: background-color 300ms;

      .menuNotification {
        width: 6px;
        height: 6px;
        position: relative;
        right: -7px;
        top: -4px;
        margin-left: -6px;
        background-color: $red;
        border-radius: 50%;
        align-self: start;
      }

      svg {
        color: $light;
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    .brandLogo {
      margin-left: 1rem;

      @media (max-width: $maxSmall) {
        display: none;
      }
    }
  }

  .centerItems {
    flex: 2 1;

    @media (max-width: $maxSmall) {
      margin: 0 0.5rem;
    }
  }

  .rightItems {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    &.reallyRight {
      justify-content: end;
    }

    .chatIcon {
      margin: 20px;
    }

    .notificationButton {
      margin-left: 30px;

      @media (max-width: $maxSmall) {
        margin-right: 0.5rem;
      }
    }

    .chatButton {
      margin-right: 30px;

      @media (max-width: $maxSmall) {
        margin-right: 0.5rem;
      }
    }

    .monitoringButton {
      margin-left: 1rem;

      @media (max-width: $maxSmall) {
        margin-right: 0.5rem;
      }
    }
  }
}

.darkAppBar {
  background-color: $darkPurple1;
}

.support {
  height: 28px;
  width: 28px;
  background-color: $darkPurple5;
  border-radius: 16px;
  border: 2px solid $darkPurple5;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;

  &:hover {
    background-color: $darkPurple7;
  }

  svg {
    color: $white;
    font-size: 18px;
  }
}
