@import 'variables.scss';

.container {
  @media print {
    page-break-after: avoid;
  }

  width: 100%;
  height: 100%;

  .table {
    border-spacing: 0;
    width: 100%;

    @media print {

      &,
      &>*,
      &:hover &>*:hover {
        border: none !important;
      }
    }

    &.flat {
      thead {
        tr {
          th {
            color: black;
            background-color: #ffffff;
            border-top: 1px solid $lighterGrey;
            border-bottom: 1px solid $lighterGrey;

            &:first-child {
              border-top-left-radius: 8px;
              background-color: #ffffff;
              border-left: 1px solid $lighterGrey;
              border-top: 1px solid $lighterGrey;
              border-bottom: 1px solid $lighterGrey;
              height: 32px;
            }

            &:last-child {
              border-top-right-radius: 8px;
              background-color: #ffffff;
              border-right: 1px solid $lighterGrey;
              border-top: 1px solid $lighterGrey;
              border-bottom: 1px solid $lighterGrey;
              height: 32px;
            }
          }
        }
      }

      tfoot {
        tr {
          th {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }

    &.left {
      thead tr th {

        &:nth-child(1) {
          padding-left: 18px;
          width: 20%;
        }

        &:nth-child(2) {
          width: 15%;
        }

        &:nth-child(5) {
          width: 20%;
        }
      }

      .thWrapper {
        display: flex;
        align-items: center;
        justify-content: left;
      }


      tbody tr td div {
        display: flex;
        align-items: center;
        justify-content: left;
        padding-left: 5px;
      }
    }

    thead {
      @media print {

        &,
        tr:first-child {
          border: none !important;
        }
      }

      tr {
        background-color: $dark;
        color: $light;

        th {
          padding: 0.5rem;
          font-size: small;
          font-weight: 400;

          .thWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &:first-child {
            border-top-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
          }

          &.fullhead {
            margin-top: 0;
            padding: 0;
            text-align: left;
            background-color: white;
            color: black;
            border: none !important;
            border-radius: 0 !important;
          }
        }
      }
    }

    tbody {
      color: $dark;

      tr {
        height: 40px;
        width: 100%;
        border: 1px solid transparent;

        td {
          padding: 7px !important;
          text-align: center;
          border-bottom: 1px solid $lightBorder;
          font-size: small;
          //---letter-spacing: -0.5px;
          min-height: 50px;

          &:first-child {
            border-left: 1px solid $lightBorder;
          }

          &:last-child {
            margin-left: -1px;
            border-right: 1px solid $lightBorder;
          }
        }
      }
    }

    tfoot {
      color: $dark;

      tr {
        th {
          padding: 0.8rem;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border: 1px solid $lightBorder;
          border-top: unset;
          background-color: $light;
          text-align: center;
        }
      }
    }
  }
}

.sortWrapper {
  display: inline-flex;
  transition: all 350ms;
  margin-left: 0.35rem;

  :hover {
    cursor: pointer;
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.4));
  }
}