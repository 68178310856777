@import 'variables.scss';


.flagImage {
  height: 20px;
  width: 30px;
}

.spanFlag {
  margin-left: 6px;
  margin-right: 0 !important;
  position: relative;
  bottom: 5px;
}

.content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;

  label {
    line-height: 1.5;
  }

  .spanError {
    margin-top: 3px;
    font-size: 13px;
    color: $red;
  }

  .subTitleDadosPessoais {
    font-weight: 700;
    margin-bottom: 0;
  }

  .fileInput {
    display: flex;
    gap: 10px;

    .avatar {
      background-color: $darkPurple9;

      span {
        color: $white;
        font-size: x-large;
      }

      width: 80px;
      height: 80px;
    }

    .fileButton {
      display: flex;
      gap: 10px;
      flex-direction: column;
    }

    .avatarInput {
      display: none;
    }

    .avatarLabel {
      border: 1px solid $lightPurple1;
      color: $lightPurple1;
      padding: 7px 15px;
      border-radius: 8px;
      text-transform: none;
      font-weight: 400;
      margin-bottom: 0px !important;

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
  }

  .nomeEmailInput {
    display: flex;
    gap: 20px;
  }

  .telephoneContainer {
    display: flex;
    gap: 20px;

    p {
      display: flex;
      font-size: 12px;
      align-items: center;
      gap: 5px;
      margin-top: 5px;
    }

    .telephone {
      display: flex;

      .number {
        display: flex;
        margin-top: 18px;

        .ddd {
          width: 70px;
          margin-right: 10px;
        }

        .phone {
          width: 120px;
          margin-right: 10px;
        }
      }

      .ddiLabel {
        display: flex;
        margin-bottom: 5px;
        text-transform: uppercase;
        color: $dark;
        font-size: 12px;
        font-weight: bold;
        line-height: 15px;
      }

      .ddi {
        padding-left: 5px;
        padding-right: 5px;
        height: 38px;
        border: 1px solid $lighterGrey;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }

  .newPasswordContainer {
    margin-top: 10px;

    h1 {
      margin: 0;
    }

    .newPassSubTitle {
      margin: 10px 0 10px 0;
    }

    p {
      display: flex;
      font-size: 12px;
      align-items: center;
      gap: 5px;
      margin-top: 5px;
    }

    .newPassword {
      display: flex;
      margin-bottom: 13px;
      gap: 20px;
    }
  }

  .checkboxItem {
    margin: 10px 0 10px 0;
  }
}
