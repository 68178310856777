@import 'variables.scss';

.competition {
  padding: 25px;
  height: calc(100% + 100px);

  .tableContainer {
    max-width: 100%;
    overflow-x: auto;

    .link {
      color: $dark;
      text-decoration: none;
    }
  }

  .targetingHeader {
    display: flex;
    text-align: left;
  }

  table {
    thead tr th {
      padding: 5px 15px !important;

      div {
        font-weight: 600;
        justify-content: left !important;
      }
    }

    tbody tr td {
      padding: 5px 15px !important;
      div {
        text-align: left;

        img {
          height: 20px !important;
          width: auto;
        }
      }
    }
  }

  .checkbox {
    color: $light;
    transform: translateY(-1px);
  }

  .targetingRow {
    display: flex;
    align-items: center;

    .menu {
      margin-right: 0.5rem;
    }

    button {
      font-size: small;
      padding: 0.3rem;
      white-space: nowrap;
      box-shadow: none;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(50vh);
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.lowPriority {
  @extend .circle;

  background-color: $info;
}

.mediumPriority {
  @extend .circle;

  background-color: $warning;
}

.highPriority {
  @extend .circle;

  background-color: $danger;
}

.errorContainer {
  p {
    margin: 0;
  }
}

.actionBar {
  padding: 0;
}
