.container {
  position: relative;
  height: 40px;
  width: 40px;
  margin-bottom: 5px;

  .club {
    position: absolute;
    height: 20px;
    width: 20px;
    bottom: -10px;
    right: -10px;
  }
}