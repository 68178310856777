.LineChart {
    display: flex;
    align-items: flex-start;
    .chartStars {
        display: flex;
        flex-direction: column;
        justify-content: space-between;        
        width: 90px;
        min-width: 90px;
        margin-top: 25px;
        height: 160px;

        @media (max-width: 670px) {
            height: 145px;
        }
    }
    .chart {
        flex-grow: 1;
    }
}