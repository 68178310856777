@import 'variables.scss';

.stepThreeBody {
  height: 540px;
  .stepThreeText {
    font-weight: 600;
    font-size: 18px;
    margin: 30px 0;
    padding: 0 25px;
  }

  .stepThreeAuction {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 25px;
    border-top: 1px solid $lightBorder;

    .stepThreeAuctionSpan {
      display: flex;
      width: 280px;

      span {
        font-weight: 600;
        font-size: 14px;
      }

      svg {
        margin-left: 4px;
        font-size: 17px;
        color: $lightPurple1;
      }
    }

    .stepThreeAuctionSelect {
      display: flex;
      flex-direction: row;
      gap: 15px;
    }
  }

  .stepThreeVisibility {
    display: flex;
    flex-direction: column;
  }

  .setpThreeShowAgents {
    margin-bottom: 10px;
  }

  .stepThreeFilters {
    display: flex;
    flex-direction: row;
    padding: 10px 25px;
    border-top: 1px solid $lightBorder;
    gap: 15px;

    .stepThreeInputContainer {
      font-weight: 400;
      font-size: 14px;

      svg {
        color: $lightPurple1;
        transform: scale(-1, 1);
      }

      .stepThreeInput {
        background-color: $white;
        border-radius: 8px;
        border: 1px solid $lightBorder;
        height: 35px;
        width: 208px;
        padding: 3px 15px;

        &::placeholder {
          color: $black;
          font-weight: 500;
        }
      }
    }

    .stepThreeCountryMenu {
      font-weight: 500;
      font-size: 14px;
      background-color: $white;
      border-radius: 8px;
      border: 1px solid $lightBorder;
      padding: 10px;
    }

    .stepThreeLeagueMenu {
      font-weight: 500;
      font-size: 14px;
      background-color: $white;
      border-radius: 8px;
      border: 1px solid $lightBorder;
      padding: 10px;
    }

    .stepThreeSelectedClubs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      padding-right: 15px;
      font-size: 14px;
      font-weight: 600;
      color: $lightPurple1;
      cursor: pointer;
    }
  }

  .stepThreeCountryFlag {
    width: 21px;
    height: 15px;
  }

  .stepThreeTableContainer {
    padding: 5px 15px 0 25px;
    height: 253px;
    overflow-y: scroll;

    .spinner {
      margin: 60px auto;
      text-align: center;
    }

    .stepThreeSwitchContainer {
      display: flex;
      justify-content: flex-end;
    }

    .stepThreeSwitch {
      border: 1px solid $lightPurple1;
    }

    table {
      padding-bottom: 20px;

      thead tr th {
        background-color: $white;
        padding: 15px;
        border-top: 1px solid $lightBorder;
        border-bottom: 1px solid $lightBorder;

        &:first-child {
          border-left: 1px solid $lightBorder;
        }

        &:last-child {
          border-right: 1px solid $lightBorder;

          div {
            justify-content: flex-end !important;
            display: grid;
          }
        }

        div {
          justify-content: left !important;
          color: $black;
          font-weight: 500;
        }
      }

      tbody tr td {
        padding: 15px;

        div {
          text-align: start;
        }
      }
    }
  }
}
