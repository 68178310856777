@import 'variables.scss';

.container {
    & .containerRow {
        display: flex;
        flex-direction: row;
        border-top: 1px solid $lighterBorder;
        padding: 5px;

        & .leftButtons {
            display: flex;
            flex-direction: row;

            & > * {
                margin: 5px;
            }
        }
        & .center {
            flex: 1;

            & .messageInput {
                color: $darker;

                &::placeholder {
                    color: $darker;
                }
            }
        }
        & .rightButtons {
            display: flex;
            flex-direction: row;

            & > * {
                margin: 5px;
            }
        }
    }
}