@import 'variables.scss';

.dialogContainer {
  top: calc(100vh - 325px);
  left: calc(100vw - 300px);
  transform: none;
}

.dialogBody {
  border-radius: 8px;
}

.selectTeamFilters {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 0 5px;

  .selectTeamInputContainer {
    font-weight: 400;
    font-size: 14px;

    svg {
      color: $lightPurple1;
      transform: scale(-1, 1);
    }

    .selectTeamInput {
      background-color: $white;
      border-radius: 8px;
      border: 1px solid $lightBorder;
      height: 35px;
      width: 208px;
      padding: 3px 15px;

      &::placeholder {
        color: $black;
        font-weight: 500;
      }
    }
  }

  .selectTeamCountryMenu {
    font-weight: 500;
    font-size: 14px;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $lightBorder;
    padding: 10px;
  }

  .selectTeamLeagueMenu {
    font-weight: 500;
    font-size: 14px;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $lightBorder;
    padding: 10px;
  }

  .selectTeamSelectedClubs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding-right: 15px;
    font-size: 14px;
    font-weight: 600;
    color: $lightPurple1;
    cursor: pointer;
  }
}

.selectTeamTableContainer {
  margin: 15px 5px 0 5px;
  padding-right: 15px;
  height: 540px;
  overflow-y: scroll;

  .spinner {
    margin: 60px auto;
    text-align: center;
  }

  .selectTeamSwitch {
    border: 1px solid $lightPurple1;
  }

  table {
    padding-bottom: 20px;

    thead tr th {
      background-color: $white;
      padding: 15px;
      border-top: 1px solid $lightBorder;
      border-bottom: 1px solid $lightBorder;

      &:first-child {
        border-left: 1px solid $lightBorder;
      }

      &:last-child {
        border-right: 1px solid $lightBorder;

        div {
          justify-content: flex-end !important;
          display: grid;
        }
      }

      div {
        justify-content: left !important;
        color: $black;
        font-weight: 500;
      }
    }

    tbody tr td {
      padding: 15px;

      div {
        text-align: start;
      }

      button {
        font-size: 13px !important;
      }
    }
  }
}