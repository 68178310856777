@import 'variables.scss';

.container {
  background-color: $darkPurple2;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .languageButton {
    position: fixed;
    top: 20px;
    right: 30px;

    & > div {
      margin: 0;
    }
  }

  .innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 60%;
    // max-width: 400px;

    @media (max-width: $maxSmall) {
      width: 85%;
    }

    .cardContainer {
      display: flex;
      border-radius: 8px;
      width: 470px;
      //   height: 100%;
      overflow: hidden;
      //   padding: 20px 30px;
      padding: 35px;

      @media (max-width: $maxSmall) {
        width: 300px;
      }

      .resetContainer {
        display: flex;
        min-width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: $maxSmall) {
          min-width: 235px;
        }
      }
    }

    .purshaseText {
      color: #fff;
      font-size: small;
      line-height: 15px;
      text-align: center;
      margin: 1.5rem 0 0.5rem;
    }

    .feedback {
      font-size: small;
      margin: -1rem 0 0.5rem;
      color: $danger;
    }

    .icon {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.passwordLabel {
  display: flex;
  justify-content: space-between;
}

.inputContainer {
  margin: 0 0 0.5rem;
  line-height: 30px;
}

.passwordInput {
  min-height: 25px;
  background-color: $white !important;
}

.checkbox {
  margin: -0.5rem 0 1.5rem;
  width: 100%;
}

.passwordContainer {
  width: 100%;
  position: relative;
}

.buttonContainer {
  width: 100%;
  margin-top: 30px;
}

.link {
  color: $darkPurple9;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;
  transition: color 350ms;

  &:hover {
    cursor: pointer;
    color: $lightPurple1;
  }
}

.text {
  color: #ffffff;
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  width: 700px;
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
}

.textl {
  margin: 23px;
  margin-bottom: 38px;
}

.spacer {
  margin-top: 200px;
  background-color: transparent;
}

.toastContent {
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
}

.loading {
  margin-bottom: 1.5rem;
}
