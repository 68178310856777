@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-width: 400px;
  min-height: calc(100vh - 240px);
  width: 100%;
  border: 1px solid $lightBorder;
  border-left: none;  
  border-radius: 0px 8px 8px 0px;
}

.loading {
  position: relative;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.messageWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  .deleteButton {
    position: absolute;
    top: 30px;
    right: 56px;
    color: $white;
    background-color: $red;
    padding: 12px;
    font-size: 24px;
    border-radius: 24px;
    cursor: pointer;
    z-index: 1;

    &:hover {
      background-color: $danger;
    }
  }

  .deleting {
    opacity: 0.9;
    cursor: wait;
  }

  .noMessages {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 50%;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    box-sizing: border-box;
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    width: 100%;

    .dateWrapper {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      &:first-child {
        margin: 0;
      }
    }

    .messages {
      display: flex;
      flex-direction: column-reverse;
    }

    .date {
      display: flex;
      align-self: center;
      flex-direction: row;
      align-items: flex-start;
      padding: 6px 83px;
      border-radius: 8px;
      background: $lightPurple4;
      color: $lightPurple1;
      font-weight: 600;
    }
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}