@import 'variables.scss';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: none;
  width: 33px;
  height: 33px;
  padding: 0.5rem;
  border-radius: 100%;
  background-color: $white;
  outline: none;
  font-weight: 700;
  transition: background 300ms ease;
  color: $black;

  &.green {
    background-color: $darkPurple9;
    color: $light;
  }

  &.purple {
    color: $white;
    background-color: $lightPurple1;
    box-shadow: none;
  }

  &.vividGreen {
    margin-bottom: 15px;
    width: 150px;
    height: 40px;
    border-radius: 8px;
    background-color: $lightPurple1 !important;

    span {
      font-size: 14px;
      color: $white;
    }

    svg {
      margin-left: 10px;
      color: $white;
      width: 18px;
      height: 18px;
    }
  }

  &.invitePurple {
    width: 130px;
    height: 35px;
    margin: 10px 0;
    padding: 10px;
    margin: 20px 0;
    background-color: $lightPurple1;
    border-radius: 8px;

    span {
      font-size: 12px !important;
      font-weight: 400;
      color: $white;
      align-self: start;
    }

    svg {
      margin-left: 10px;
      color: $white;
      width: 18px;
      height: 18px;
    }
  }

  &.connectPurple {
    width: 110px;
    height: 40px;
    margin-left: 20px;
    padding: 10px;
    background-color: $lightPurple1;
    border: 2px solid $lightPurple1;
    border-radius: 8px;
    flex-direction: row-reverse;
    gap: 10px;

    span {
      font-size: 12px;
      font-weight: bold;
      color: $white;
      align-self: left;
    }

    svg {
      color: $white;
      width: 15px;
      height: 15px;
    }
  }

  &.connectedPurple {
    width: 100px;
    height: 40px;
    margin-left: 20px;
    padding: 10px;
    background-color: $lightPurple1;
    border: 2px solid $lightPurple1;
    border-radius: 8px;

    span {
      font-size: 12px;
      font-weight: bold;
      color: $white;
      align-self: left;
    }

    svg {
      margin-left: 5px;
      color: $white;
      width: 23px;
      height: 23px;
    }
  }

  &.connect {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    color: $white;
    font-weight: 700;
    width: 113px;
    height: 40px;
    border-radius: 8px;
    background-color: $lightPurple1;

    svg {
      color: $white;
      width: 16px;
      height: 16px;
    }
  }

  &.connectWhite {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    color: $lightPurple1;
    font-weight: 700;
    width: 113px;
    height: 40px;
    border-radius: 8px;
    border: 2px solid $lightPurple1;
    background-color: $white;
    margin-top: 15px;

    svg {
      color: $lightPurple1;
      width: 16px;
      height: 16px;
    }
  }

  &.sendMessage {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    color: $lightPurple1;
    font-weight: 700;
    width: 164px;
    height: 40px;
    border: 2px solid $lightPurple1;
    border-radius: 8px;

    svg {
      color: $lightPurple1;
      width: 16px;
      height: 16px;
    }
  }

  &.follow {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    color: $lightPurple1;
    font-weight: 700;
    width: 96px;
    height: 40px;
    border: 2px solid $lightPurple1;
    border-radius: 8px;

    svg {
      color: $lightPurple1;
      width: 14px;
      height: 14px;
    }
  }

  &.following {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    color: $white;
    font-weight: 700;
    width: 96px;
    height: 40px;
    border: 2px solid $lightPurple1;
    border-radius: 8px;
    background-color: $lightPurple1;

    svg {
      color: $white;
      width: 14px;
      height: 14px;
    }
  }

  &.headerArrow {
    box-shadow: none;
    width: auto;

    svg {
      width: 20px;
      height: 16px;
    }
  }

  &.instagram {
    border: 1px solid $lightBorder;
    box-shadow: none;

    svg {
      color: $pinkInstagram;
    }
  }

  &.twitter {
    border: 1px solid $lightBorder;
    box-shadow: none;

    svg {
      color: $blueTwitter;
    }
  }

  &.facebook {
    border: 1px solid $lightBorder;
    box-shadow: none;

    svg {
      color: $blueTwitter;
    }
  }

  &.tiktok {
    border: 1px solid $lightBorder;
    box-shadow: none;
  }

  &.linkedin {
    border: 1px solid $lightBorder;
    box-shadow: none;

    svg {
      color: $blueLinkedin;
    }
  }

  &.showCertification {
    width: 148px;
    height: 40px;
    border-radius: 8px;
    border: 2px solid $lightPurple1;
    color: $lightPurple1;
    font-weight: 700;
    gap: 10px;
  }

  &.lightBlue {
    background-color: $lightBlue;
    color: $light;
  }

  &.orange {
    background-color: $warning;
    color: $light;
  }

  &.dark {
    background-color: $lighterGrey;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

    &.green,
    &.vividGreen {
      box-shadow: 0px 0px 3px 1px $lightPurple1;
    }

    &.lightBlue {
      box-shadow: 0px 0px 3px 1px $lightBlue;
    }

    &.white {
      box-shadow: 0px 0px 3px 1px $white;
    }
  }

  &:disabled {
    background-color: rgb(216, 216, 216);
    border: 1px solid transparent;
    color: rgb(182, 182, 182);
    cursor: default;
    box-shadow: none;

    &.outline {
      background-color: transparent;
      border: 1px solid rgb(170, 170, 170);
      color: rgb(170, 170, 170);
    }

    &:hover {
      box-shadow: none;
    }
  }

  svg {
    width: 100%;
    height: auto;
    color: inherit;
  }
}

.tooltip {
  opacity: 1 !important;
}
