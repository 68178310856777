.withImg {
  display: flex;  
  align-items: center;

  .avatar {
    height: 40px;
    margin-right: 10px;
    width: 40px;
  }   
}
