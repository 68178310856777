@import 'variables.scss';

b {
  font-weight: 600;
  font-size: 16px;
  margin-right: 5px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  color: $dark;
  font-size: small;
  padding: 15px 30px;
  border-top: 1px solid $lightBorder;

  @media (max-width: $maxSmall) {
    margin: 0.75rem 0.5rem;

    & > div {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  p,
  label {
    margin-right: 0.5rem;
    color: $dark;
    font-size: larger !important;

    @media (max-width: $maxSmall) {
      margin-bottom: 0;
    }
  }

  .largeSelect {
    background-color: #ffffff;
    border: 1px solid $lightBorder;
    border-radius: 8px;
    height: 30px;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .generalRate {
    display: flex;
    align-items: center;
    margin-right: 0.65rem;

    svg {
      margin-right: 0.35rem !important;
    }

    @media (max-width: $maxSmall) {
      margin-bottom: 0.5rem;
    }
  }

  .targetingContainer {
    @media (max-width: $maxSmall) {
      margin: 0.5rem 0;
    }
  }

  .divider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0.8rem;
    height: 40px;

    @media (max-width: $maxSmall) {
      display: none;
    }
  }
}
