@import 'variables.scss';

.minMaxContainer {
  display: flex;
  align-items: center;

  .inputMinMaxContainer {
    display: flex;
    align-items: center;
    font-size: 0.8rem;

    span {
      margin-right: 5px;
    }

    .inputContainer {
      input {
        -moz-appearance: textfield;
        background-color: #ffffff;
        border-radius: 8px;
        height: 15px;
        padding: 10px 3px !important;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &:focus {
          outline: none;
          border: 1px solid $lightPurple1;
        }
      }

      width: 35px;
      margin-right: 10px;
    }

    .inputContainerError {
      input {
        border-color: $red;
        box-shadow: 0 0 8px $red;
        color: $red;
      }
    }
  }

  .searchBtn {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: $lightPurple1;
    color:#FFF;
  }
}