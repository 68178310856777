@import 'variables.scss';

.container {
    background-color: #ffffff;
    display: flex;
    height: calc(100% - 85px);
    justify-content: center;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;

    &>div {
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.TopBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100px;
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    border-bottom: 1px solid $lighterBorder;
    overflow: hidden;
    position: absolute;
    left: 0;
    z-index: 2;

    @media (max-width: $maxMedium) {
        flex-direction: column;
        height: 150px;
    }
}