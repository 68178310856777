.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px;
}

.selectChat {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.panel {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  background: #fff;
  min-height: calc(100vh - 240px);
}