@import 'variables.scss';

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-height: calc(100vh - 60px);
}

.content {
    box-sizing: border-box;
    padding: 0 20px;
    background-color: $white;
}

.container {
    background-color: $white;
    height: auto;
    min-height: 100%;
}