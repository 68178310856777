@import 'variables.scss';

.container {
    border-radius: 8px;
    top: 52px;
    width: 330px;
    height: 155px;
    padding: 0;
    color: $darker;
    display: flex;
    box-shadow: 0px 4px 10px 0px #00000033;
    flex-direction: column;
    //border: 1px solid $lightBorder;

    @media (max-width: $maxSmall) {
        right: 5px !important;
    }

}

.icon {
    height: auto;
    margin-right: 10px;
}


.link {
    font-weight: 500;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: auto;
    margin-top: 15px;
    margin-left: 20px;
    color: black;
    font-size: 14px;

    &:hover {
        cursor: pointer;
        color: $lightPurple1;
    }

    .checkIcon {
        position: relative;
    }

    .flag {
        position: relative;
        right: 20px;
    }

    .texto {
        display: flex;
        position: relative;
        align-items: center;
        width: 140px;
        right: 34px
    }

}