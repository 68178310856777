@import 'variables.scss';

.imageList {    
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    margin-top: 5px;

    .input div {
        margin-top: 4px;
        height: 39px;
    }

    .imageItem {
        margin: 10px;   
        background-color: $white;
        padding: 10px;
        position: relative;  
        border-radius: 8px;
        border: solid 1px $lightBorder !important;
        height: 245px;        
        width: 338px;

       .image {     
            height: 198px;        
            width: 317px;
            object-fit: contain;    
            object-position: top;
        }

        .removeImage {            
            position: absolute;
            top: -8px;
            right: -8px;  
            height: 40px;
            width: 40px;
            border-radius: 50%;
            svg {
                height: 18px;
                width: 18px;
                margin: auto;
            }
        }
    }
}