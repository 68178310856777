@import 'variables.scss';

.foreignClubs {
  padding: 0 !important;
  margin-bottom: 20px;

  .tableContainer {
    margin-top: 100px;
    max-width: 100%;
    overflow-x: auto;

    .link {
      color: $dark;
      text-decoration: none;
    }
  }

  .countryFlag {
    width: 30px;
    height: auto;
  }

  .teamName {
    text-align: left;
    display: flex;
    align-items: center;

    img {
      width: 30px;
      height: auto;
      margin-right: 10px;
    }

    a {
      top: 50%;
      font-size: small;
    }
  }

  table {
    padding-bottom: 100px;
    thead tr th {
      &:nth-child(1) {
        padding-left: 15px !important;
      }
      div {
        justify-content: left !important;
        font-weight: 600;
      }
    }

    tbody tr td {
      padding: 5px !important;

      font-weight: 400;
      font-size: 14px;

      &:nth-child(1) {
        font-weight: 600;
        font-size: 14px;
      }

      div {
        padding-left: 2px !important;
        text-align: left;
      }
    }
  }
}
