@import 'variables.scss';

.appContainer {
  height: 100%;  
}

[footlink-title]:before, [footlink-title-right]:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  top: 80%;
  border: 8px solid;
  border-color: transparent transparent $darkPurple2 transparent;
  visibility: hidden;
}
[footlink-title]:before {
  right: 4px;
}
[footlink-title-right]:before {
  left: 10px;
}
[footlink-title]:after, [footlink-title-right]:after {
  background-color: $darkPurple2;
  color: $white;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  padding: 8px 12px;
  opacity: 0;
  min-width: 80px;
  width: max-content;
  max-width: 200px;
  top: calc(80% + 15px);
  border-radius: 8px;
  visibility: hidden;
}
[footlink-title]:after {
  content: attr(footlink-title);
  right: -10px;
}
[footlink-title-right]:after {
  content: attr(footlink-title-right);
  left: 0;
}
[footlink-title],[footlink-title-right] {
  position: relative;
}
[footlink-title]:hover:after, 
[footlink-title]:hover:before, 
[footlink-title-right]:hover:before,
[footlink-title-right]:hover:after {
  opacity: 1;
  transition: all 0s ease 0.2s;
  visibility: visible;
  z-index: 99999999999999;
}