@import 'variables.scss';

.stepOneBody {
  height: 545px;
  
  .stepOneText {
    font-weight: 600;
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 15px;
    padding: 0 25px;
  }

  .largeSelect {
    background-color: #ffffff;
    border: 1px solid $lightBorder;
    border-radius: 8px;
    width: 200px;
    padding: 10px 15px 10px 15px;
    font-size: 0.8rem;
    line-height: 1.5;
  }

  .stepOneInfoCard {
    background-color: $light;
    border-top: 1px solid $lightBorder;
    border-bottom: 1px solid $lightBorder;
    margin: 10px 0;
  }

  .stepOneNegotiation {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 20px 8px 20px;
    height: 40px;    
    border-top: 1px solid $lightBorder;

    .stepOneNegotiationSpan {
      display: flex;
      width: 200px;

      span {
        font-weight: 600;
        font-size: 14px;
      }
    }

    .stepOneNegotiationSelect {
      display: flex;
      flex-direction: row;
      gap: 15px;
    }
  }

  .stepOneMaxMinAge {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 20px;
    height: 40px;    
    border-top: 1px solid $lightBorder;

    .stepOneMaxMinAgeSpan {
      display: flex;
      width: 200px;

      span {
        font-weight: 600;
        font-size: 14px;
      }
    }

    .stepOneMaxMinAgeInput {
      padding: 4px 0.75rem;
      background-color: $white;
      width: 30px;
      margin: 0 20px 0 5px;
    }
  }

  .stepOneSale {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    height: 40px;
    border-top: 1px solid $lightBorder;

    .stepOneSaleSpan {
      display: flex;
      width: 200px;

      span {
        font-weight: 600;
        font-size: 14px;
      }
    }

    .stepOneSaleInput {
      background-color: $white;

      &::placeholder {
        color: $black;
        font-weight: 500;
      }
    }
  }

  .stepOneSalary {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    height: 40px;
    border-top: 1px solid $lightBorder;

    .stepOneSalarySpan {
      display: flex;
      width: 200px;

      span {
        font-weight: 600;
        font-size: 14px;
      }
    }

    .stepOneSalaryInput {
      background-color: $white;

      &::placeholder {
        color: $black;
        font-weight: 500;
      }
    }

    .stepOneSalarySpan2 {
      font-weight: 600;
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .stepOneCheckbox {
    margin-right: 15px;

    &:nth-last-of-type(1) {
      margin-right: 30px;
    }
  }
  
  .calendarInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $lightBorder;
    border-radius: 8px;
    background-color: white;
    margin-left: 6px;

    .calendarIcon {
      color: #3b4758;
      padding-right: 10px;
    }

    input {
      padding: 0 10px !important;
    }
  }

  .stepOneObservation {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 20px 0 20px;
    height: 90px;
    border-top: 1px solid $lightBorder;

    .stepOneObservationSpan {
      display: flex;
      width: 200px;

      span {
        font-weight: 600;
        font-size: 14px;
      }
    }

    .stepOneObservationInput {
      font-family: inherit;
      font-weight: 400;
      font-size: 14px;
      background-color: $white;
      padding: 10px;
      vertical-align: top;
      height: 70px;
      width: 30rem;
      resize: none;
      border-radius: 8px;
      border: 1px solid $lightBorder;
    }
  }
}
