@import 'variables.scss';

.graph_container {
  background-color: $darkPurple2;
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: fit-content;
  border-radius: 8px;

  .spinner {
    position: absolute;
    margin-top: -35px;
    margin-left: -35px;
  }

  .player_name {
    color: $white;
    font-size: 12px;
    position: absolute;
    display: flex;
    width: max-content;
    transform: translate(-50%, -110%);
  }

  .titles {
    display: flex;
    flex-direction: row;
    margin: 0 48px 0 74px;

    span {
      font-weight: 600;
      font-size: 16px;
      margin-right: 4px;
    }

    .development {
      display: flex;
      align-items: center;
      place-content: center;
      color: $blue3;
    }

    .cutting {
      display: flex;
      align-items: center;
      place-content: center;
      color: $green3;
    }

    .performance_peak {
      display: flex;
      align-items: center;
      place-content: center;
      color: $green2;
    }

    .sustain {
      display: flex;
      align-items: center;
      place-content: center;
      color: $orange2;
    }

    .tutor {
      display: flex;
      align-items: center;
      place-content: center;
      color: $red;
    }
  }

  .graph_border {
    width: 75vw;
    height: 60vh;
    margin: 14px 48px 42px 74px;
    position: relative;
    background-color: $darkPurple2;
    border: 2px solid white;
  }

  .minutes_played_label {
    position: absolute;
    transform: rotate(-90deg) translate(7%, -100%);
    top: 30vh;
    left: -140px;
    font-size: 18px;
    color: $white;
    font-weight: 600;
  }

  .age_label {
    position: absolute;
    top: calc(60vh + 50px);
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: $white;
    font-weight: 600;
  }

  .info_label {
    position: absolute;
    top: calc(60vh + 50px);
    left: -100px;
    font-size: 12px;
    color: $lightGrey2;
  }

  .x_line {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: $white;
    opacity: 0.25;
  }

  .y_line {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-top: 1px solid white;
    opacity: 0.25;
  }

  .data_points {

    &:hover {
      .data_point {
        opacity: 0.7;
      }
    }

    .data_point {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-50%, -50%); // Center the data point
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px solid black;
      background-color: $lightBorder;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }


  .x_labels {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: -20px;
    left: -5px;
    right: -5px;
    color: $white;
    font-size: 12px;
  }

  .y_labels {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -55px;
    padding: 0 10px;
    color: $white;
    font-size: 12px;
    text-align: right;
  }

  .titles_container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;

    .title_container {
      height: 100%;

      &:hover {
        background-color: $green3;
        opacity: 0.1;
      }
    }
  }


}