@import 'variables.scss';

.navigationItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.3rem;
  border-bottom: 3.5px solid transparent;
  //color: $dark;
  font-size: 15px;
  font-weight: 500;
  //---letter-spacing: -1px;
  text-align: center;
  transition: all 350ms;

  &:hover {
    cursor: pointer;
    border-bottom: 3.5px solid $lightPurple1;
  }

  p {
    margin: 0;
    white-space: nowrap;
  }

  span {
    font-weight: 500;
    color: $lightPurple1;
  }

  &.active {
    border-bottom: 3.5px solid $lightPurple1;
    background-color: $white !important;
  }

  &.notimplemented {
    color: $lighterGrey;
  }
}
