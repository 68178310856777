@import 'variables.scss';

.navDrawer {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 60px;
  background-color: #ffffff;
  width: 320px;
  max-width: 100vw;
  height: calc(100vh - 60px);
  transition: transform 300ms linear;
  transform: translateX(-100%);
  z-index: 101;
  border-top: 1px solid rgba(255, 255, 255, 0.3);

  &.open {
    transform: translateX(0%);
  }
}

.menuNotification {
  width: 8px;
  height: 8px;
  position: absolute;
  right: 10%;
  margin-top: 16px;
  background-color: $red;
  border-radius: 50%;
}

.profileSection {
  height: 250px;
  margin-bottom: 15px;

  .profileCover {
    width: 320px;
    height: 87px;
    background-color: #eee5ff;
  }

  .profilePicture {
    position: relative;
    left: 50%;
    transform: translate(-50%, -80%);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: blueviolet;

    .avatar {
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80px;
      height: 80px;
    }
  }

  .profileInfo {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 50%;
    transform: translate(-50%, -150%);

    .centeredName {
      top: 13px;
      position: relative;
    }

    span {
      color: black;
      font-weight: 600;
      transition: color 300ms;
    }

    a {
      color: $lightPurple1;
      font-weight: 600;
      transition: color 300ms;

      &:link {
        text-decoration: none;
      }

      &:visited {
        text-decoration: none;
      }

      &:hover {
        text-decoration: none;
        color: $lightPurple2;
      }

      &:active {
        text-decoration: underline;
      }
    }
  }

  .profileClub {
    position: relative;
    transform: translate(0, -40px);

    .clubSpan {
      padding-left: 1rem;
      text-transform: uppercase;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }

    .orgContainer {
      display: flex;
      flex-direction: column;
      padding: 0.7rem 1rem;

      .org_section {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 15px;

        .org_section_inner_container {
          display: flex;

          .org_section_texts {
            align-self: center;
            margin-left: 1rem;
            color: black;
            font-weight: 600;
            font-size: 18px;
            line-height: 19px;

            &:hover {
              cursor: pointer;
            }
          }

          div {
            color: $lightPurple1;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.nav_section_container {
  .moduleSpan {
    margin-top: 6px;
    padding-left: 1rem;
    text-transform: uppercase;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}