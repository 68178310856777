@import 'variables.scss';

.infoCard {
  div, aside {
    padding: 0 !important;
  }

  div img {
    width: 14px !important;
    margin: 0 2px !important;
  }

  aside img {
    height: 32px !important;
    width: 32px !important;
    margin: 0 10px !important;
  }

  h4 {
    font-size: 14px !important;
    color: $lightPurple1 !important;
  }

  span {
    font-size: 14px !important;
  }
}

.cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $white;

  .isExpiredDate {
    color: #d7d6d2;
  }

  .tableContainer {
    margin-bottom: 20px;

    .loader {
      position: absolute;
      top: 60%;
      right: 50%;
    }

    .errorContainer {
      position: absolute;
      top: 50%;
      right: 50%;
    }
  }

  .titulo {
    margin-top: 30px;
    margin-left: 30px;
  }

  .listTable {
    padding-left: 30px;
    padding-right: 30px;

    thead tr {
      background-color: $white;
    }

    thead tr th {
      &:nth-child(8) {
        div {
          justify-content: center !important;
        }
      }

      div {
        color: $black;
        font-weight: bold;
        justify-content: left !important;
      }
    }

    tbody tr td {
      &:nth-child(8) {
        padding-right: 0;
      }

      div {
        color: $black;
        text-align: left;
      }
    }
  }

  .interestedVisualizedContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .progressBarContainer {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
  }

  .progressBar {
    height: 100%;
    background-color: $lightPurple1;
    transition: width 0.3s ease-in-out;
  }

  .stepThreeSwitchContainer {
    display: flex;
    justify-content: center;
  }

  .stepThreeSwitch {
    border: 1px solid $lightPurple1;
  }

  .editAd {
    cursor: pointer;
    border: 1px solid $lightPurple1;
    color: $lightPurple1 !important;
    font-weight: 600;
    display: flex;
    height: 27px;
    // width: 120px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;

    &:hover {
      color: $lightPurple2 !important;
      border-color: $lightPurple2;
    }
  }

  .filters {
    position: relative;
    display: flex;
    padding: 15px 0;
    margin-left: 30px;
    margin-right: 30px;
    background-color: $white;
    align-items: center;

    .input {
      padding-left: 10px;
      display: flex;
      align-items: center;
      width: 180px;

      .searchIcon {
        transform: scaleX(-1);
        color: $lightPurple1;
      }
    }

    .menu {
      border: 1px solid $lightBorder;
      margin-left: 10px;
      background-color: $white;
      font-size: 14px;
      padding: 0.65rem 0.95rem;
      margin-top: 1px !important;
      height: 19px;
    }

    .lastButton {
      position: absolute;
      right: 0;
    }
  }

  .dialog {
    display: flex;
    border-radius: 8px;
    background-color: $white;
    width: fit-content;
    max-width: none;
    min-width: 745px;

    .dialogHeader {
      border-bottom: 0;

      h3 {
        font-size: 16px;
        font-weight: 600;
        margin-left: 5px;
      }

      button svg {
        color: $lightPurple1;
        width: 30px;
        height: 30px;
      }
    }

    .dialogBody {
      padding: 0;
      border-radius: 0 0 8px 8px;
      height: 700px;
      max-height: 80vh;      
      display: flex;
      flex-direction: column;
      overflow: unset;


      > div:nth-child(2) {
        flex: 1 1 auto; 
        overflow: auto;
        height: 580px !important;
      }

      .navbar_container {
        display: flex;
        flex-direction: row;
        cursor: pointer;
  
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $lightBorder;
          background-color: $lightPurple4;
          border-left: none;
          height: 50px;
          width: 25%;
  
          &:first-child {
            border-left: none;
            border-radius: 0;
          }
  
          &:last-child {
            border-right: none;
          }
  
          svg {
            color: $lightPurple1;
          }
  
          span {
            color: $lightPurple1;
            font-size: 14px;
            font-weight: 500;
            margin-left: 5px;
          }
        }
  
        .selected {
          background-color: $white;
  
          span {
            color: $lightPurple1;
          }
  
          svg {
            color: $white;
            border: 2px solid $lightPurple1;
            border-radius: 50%;
          }
        }
  
        .will_select {
          background-color: $lightGrey6;
  
          svg {
            color: $light;
            border: 2px solid $dark2;
            border-radius: 50%;
          }
  
          span {
            color: $dark2;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .dialogFooter {
        flex: 0 0 auto; /* Impede que o cabeçalho e o rodapé encolham ou cresçam */
        padding: 10px;
        background-color: #f1f1f1;
        display: flex;
        justify-content: flex-end;
        // padding: 10px 25px;
        border-top: 1px solid $lightBorder;
        border-radius: 8px;
  
        .footerButtons {
          display: flex;
          flex-direction: row;
          gap: 10px;
  
          .dialogBack {
            background-color: $white;
            color: $lightPurple1;
            width: 110px;
            height: 40px;
            border-radius: 8px;
            flex-flow: row-reverse;
            gap: 5px;
            border: 1px solid $lightPurple1;
  
            svg {
              color: $lightPurple1;
              width: 16px;
              height: 16px;
            }
  
            &:hover {
              color: $lightPurple2;
              border-color: $lightPurple2;
            }
          }
  
          .dialogContinue {
            background-color: $lightPurple1;
            color: $white;
            width: 110px;
            height: 40px;
            border-radius: 8px;
            gap: 5px;
  
            svg {
              color: $white;
              width: 16px;
              height: 16px;
            }
  
            &:hover {
              background-color: $lightPurple2;
            }
          }
        }
      }
    }
  }

  .dialog_sm{
    @extend .dialog;
    height: 450px;
    max-width: none;
    min-width: 745px;
  }

  .successModal {
    position: fixed;
    display: flex;
    top: 100%;
    width: 100%;
    height: 60px;
    align-items: center;
    background-color: #27ae60;
    border-radius: 0;

    div {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: flex;
        align-items: center;
        background-color: #27ae60;
        border-radius: 0;
        padding: 5px;
        gap: 10px;

        svg {
          color: $white;
          font-size: 14px;
          font-weight: 500;
        }

        span {
          color: $white;
        }
      }
    }
  }

  .errorModal {
    position: fixed;
    display: flex;
    top: 100%;
    width: 100%;
    height: 60px;
    align-items: center;
    background-color: #e95c4b;
    border-radius: 0;

    div {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: flex;
        align-items: center;
        background-color: #e95c4b;
        border-radius: 0;
        padding: 5px;
        gap: 10px;

        svg {
          color: $white;
          font-size: 14px;
          font-weight: 500;
        }

        span {
          color: $white;
        }
      }
    }
  }  
}

.announceTypeDialog {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    margin-top: 10px;

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border-color: $lightBorder;
      border-width: 2px;
      border-style: solid;
      padding: 0px 29px;
      margin: 0 10px;
      text-align: center;
      background-color: transparent;
      
      svg {
        margin: 52px 73px;
        margin-bottom: 10px;
        align-self: center;
        color: $lightPurple1;
        font-size: 117px;
      }

      span {
        font-family: Barlow;
        font-size: 16px;
        font-weight: bold;
        line-height: 19.2px;
        text-align: center;
        margin-bottom: 30px;
      }

      &:hover {
        border-color: $lightPurple1;
        background-color: $lightPurple4;
      }

      &:active {
        background-color: $lightPurple3;
      }
    }
  }
}
