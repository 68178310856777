@import 'variables.scss';

.button {
    background-color: $light;
    border: 1px solid $lightBorder;
    color: $dark;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
    transition: background 300ms ease;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    user-select: none;
    align-items: center;

    &.evaluationB {

        span {
            color: #5A00FF;
        }

        &.green:not(:disabled) {
            background-color: transparent;
            border: transparent;

            &.smaller {
                font-size: 13px;
            }

            &:hover {
                background-color: transparent;
            }
        }

        // &.green:not(:disabled):hover{
        //     background-color:transparent;
        // }
    }

    &:disabled {
        background-color: rgb(216, 216, 216);
        border: 1px solid transparent;
        color: rgb(182, 182, 182);
        cursor: default;
        box-shadow: none;

        &.outline {
            background-color: transparent;
            border: 1px solid rgb(170, 170, 170);
            color: rgb(170, 170, 170);
        }

        &:hover {
            box-shadow: none;
        }
    }

    &.fluid {
        width: 100%;
    }

    &.rounded {
        border-radius: 8px;
    }

    &:hover {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    }

    &.danger:not(:disabled) {
        color: $spanishWhite;
        background-color: $danger;
        border: 1px solid rgba(151, 151, 151, 0.2);
        font-weight: 500;
        box-shadow: none;

        &.outline {
            background-color: transparent;
            color: $danger;
            border: 1px solid $danger;

            &:hover {
                color: $spanishWhite;
            }
        }

        &:hover {
            box-shadow: none;
            background-color: $danger;
        }
    }

    &.orange:not(:disabled) {
        color: $spanishWhite;
        background-color: $orange;
        border: 1px solid rgba(151, 151, 151, 0.2);
        font-weight: 500;
        box-shadow: none;

        &.outline {
            background-color: transparent;
            color: $orange;
            border: 1px solid $orange;

            &:hover {
                color: $spanishWhite;
            }
        }

        &:hover {
            box-shadow: none;
            background-color: $orange;
        }
    }

    &.white:not(:disabled) {
        display: flex;
        background-color: #fff;
        color: $lightPurple1;
        border: 1px solid $lightPurple1;

        &.outline {
            background-color: transparent;
            color: $lightPurple1;
            border: 1px solid $lightPurple1;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    &.transparent:not(:disabled) {
        background-color: transparent;
        color: #fff;
        border: 1px solid #fff;

        &:hover {
            opacity: 0.7;
        }
    }

    &.link:not(:disabled) {
        background-color: transparent;
        margin-left: 15px;
        border: none;
        color: $lightPurple1;
        padding: 0;
        font-size: 16px;
        font-weight: 500;

        &.smaller {
            font-size: 13px;
        }
    }

    &.smaller {
        padding: 7px 22px;
        line-height: unset;
    }

    &.noShadow {
        box-shadow: none;

        &:hover {
            box-shadow: none;
        }
    }

    &.green:not(:disabled) {
        color: $spanishWhite;
        background-color: $lightPurple1;
        border: 1px solid rgba(151, 151, 151, 0.2);
        font-weight: 500;
        box-shadow: none;

        &.outline {
            background-color: transparent;
            color: $lightPurple1;
            border: 1px solid $lightPurple1;

            &:hover {
                color: $spanishWhite;
            }
        }

        &.smaller {
            font-size: 13px;
        }

        &:hover {
            box-shadow: none;
            background-color: $darkPurple9;
        }
    }

    &.purple:not(:disabled) {
        color: $spanishWhite;
        background-color: $lightPurple1;
        border: 1px solid rgba(151, 151, 151, 0.2);
        border-radius: 8px;
        font-weight: 700;
        box-shadow: none;

        &.outline {
            background-color: transparent;
            color: $spanishWhite;
            border: 1px solid $spanishWhite;

            &:hover {
                background-color: $lightPurple1;
                color: $spanishWhite;
            }
        }

        &.smaller {
            font-size: 13px;
        }

        &:hover {
            box-shadow: none;
            background-color: $lightPurple1;
        }
    }

}