@import 'variables.scss';

.monitoring {
  padding: 0 !important;
  height: calc(100% + 100px);

  .tableContainer {
    max-width: 100%;
    overflow-x: auto;
  }

  .targetingHeader {
    display: flex;
    text-align: left;
  }

  .monitoringTable {
    thead tr th {
      padding: 5px !important;

      &:first-child {
        padding-left: 20px !important;
      }

      &:nth-child(3) {
        width: 20%;
      }

      &:nth-child(4) {
        width: 8%;
      }

      &:nth-child(6) {
        div {
          justify-content: center !important;
        }
      }

      div {
        font-weight: 600;
        justify-content: left !important;
      }
    }

    tbody tr td {
      padding: 5px !important;

      &:first-child {
        padding-left: 20px !important;
      }

      &:nth-child(2) {
        div {
          img {
            height: 30px;
          }
        }
      }

      &:nth-child(6) {
        div {
          text-align: center;
        }
      }

      div {
        text-align: left;

        img {
          height: 20px;
        }
      }
    }
  }

  .checkbox {
    color: $white;
    transform: translateY(-1px);
  }

  .targetingRow {
    display: flex;
    align-items: center;

    .menu {
      margin-right: 0.5rem;
    }

    button {
      font-size: small;
      padding: 0.3rem;
      white-space: nowrap;
      box-shadow: none;
    }
  }
}

.teamContainer {
  text-align: left;

  .contractDate {
    color: black;
    font-weight: normal;
  }
}

.nationality {
  text-align: center;
}

.contract {
  text-align: left;
}

.content {
  box-sizing: border-box;
  padding: 0 20px;
  background-color: $white;

  @media (max-width: $maxMedium) {
    padding-top: 150px;
  }
}

.container {
  background-color: $white;
}

.buttonContainer {
  max-width: 182px;

  div {
    height: 28px;
  }
}

.teamContainer {
  text-align: left;

  .contractDate {
    color: black;
    font-weight: normal;
  }
}

.nationality {
  text-align: center;
}

.loanedContract {
  text-align: left;
}

.athleteImg {
  max-width: 30px;
  max-height: 30px;
}

.nationalityFlag {
  text-align: left;

  img {
    height: 20px;
    width: auto;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(50vh);
}
