@import 'variables.scss';

.timer {
  font-weight: 700;
}

.btnSolicite {
  cursor: pointer;
  font-weight: 700;
  color: $lightPurple1;
}
