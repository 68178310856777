@import 'variables.scss';

.container {
  position: absolute;
  box-sizing: border-box;
  top: 44px;
  border-radius: 3px;
  width: 450px;
  padding: 1rem;
  opacity: 0.0;
  visibility: hidden;
  transition: all 500ms;
  z-index: 200;

  @media (max-width: $maxSmall) {
    width: 350px;
    position: fixed;
    top: 60px;
  }

  &.show {
    visibility: visible;
    opacity: 1.0;
  }
  
  &.left {
    left: 10px;

    @media (max-width: $maxSmall) {
      left: 15px;
    }
  }

  &.right {
    right: 5px;

    @media (max-width: $maxSmall) {
      right: 15px;
    }
  }
}