@import 'variables.scss';

.profileIcon {
  position: relative;
  display: flex;
  align-items: center;
  color: $light;

  svg {
    margin-left: 0.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  .avatar {
    &:hover {
      cursor: pointer;
    }
  }
}