@import 'variables.scss';

.competitionInfo {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 40px 0 10px 0;
  margin-bottom: 25px;

  & > *,
  & > .detailsContainer > * {
    padding-left: 20px;
    color: $dark;
    display: flex;
    align-items: center;
    font-size: small;
    // padding-right: 20px;

    svg {
      color: $darkPurple9;
    }

    .title {
      padding-left: 5px;
    }
  }

  .detailsContainer {
    .competitionOrganizer {
      align-self: flex-end;

      .avatar {
        background-color: white;
        height: 30px;
        width: 30px;
      }
    }

    .location {
      padding: 0;

      img {
        padding-left: 5px;
        width: 25px;
      }
    }
  }

  .competitionName {
    padding-left: 0;
    align-self: start;

    .avatar {
      background-color: white;
      height: 30px;
      width: 30px;
    }
  }
}
