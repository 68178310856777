@import 'variables.scss';

$grey: #00000099;

.monitoringButtonDiv {
  max-width: 165px;
  display: flex;
  align-items: center;
  background-color: #F2994A;
  border-radius: 4px;
  border: 1px solid #F2994A;
  height: 42px;

  &.filled {
    border-radius: 8px;
  }

  

  &.notMonitoringButtonDiv {
    background-color: transparent;
    border-color: $lightPurple1;

    &.filled {
      background-color: $lightPurple1;

      span {
        font-size: 16px;
      }

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  .monitoringStatus {
    align-items: center;
    color: white;
    display: flex;
    font-size: 0.85rem;
    padding: 0.3rem 0 0.3rem 0.8rem;
    width: 112px;
    border-radius: 15px;

    span {
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
      font-weight: 600;
      padding-left: 10px;
      font-size: 0.85rem;

      &.filled {
        font-size: 16px;
        font-weight: 600;
      }
    }

    &.notMonitoring {
      background-color: transparent;
      color: $lightPurple1 !important;
      border-color: 1px solid $lighterBorder;

      &.filled {
        color: $white !important;
        margin-bottom: 2px;
        font-size: 16px;
      }
    }
  }

  button {
    background-color: #F2994A;
    border: none;
    border-left: 2px solid white;
    color: white;
    height: 30px;
    line-height: 26px;
    padding: 0.3rem 0.8rem;

    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;

    &:hover {
      cursor: pointer;
    }

    &.notMonitoring {
      background-color: transparent;
      border-left: 1px solid $lighterBorder;
      color: $lightPurple1;
      border-color: 1px solid $grey;

      &.filled {
        color: $white;
      }
    }

    &.filled {
      border-left: none;
    }
  }
}

.smallLoader {
  height: 13.5px;
  width: 13.5px;

  &.monitoringSpinner {
    svg {
      color: black;
    }
  }

  svg {
    margin-top: 7px;
    height: 13.5px;
    width: 13.5px;
  }
}

.popoverContainer {
  overflow: unset !important;
  z-index: 999;  

  @media (max-width: $maxSmall) {
    opacity: 1 !important;
  }

  label {
    font-size: small !important;
  }
}

.menu {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: $light;
  color: $dark;
  padding: 0.375rem 0.75rem;

  &.disabled {
    &:hover {
      cursor: unset !important;
    }
  }

  &.rounded {
    border-radius: 25px;
  }

  &:hover {
    cursor: pointer;
  }

  &.larger {
    // font-size: 12px;
    padding: 0.7rem;
    min-width: 150px;
  }

  &.darker {
    background-color: $darker;
  }

  &.warning {
    background-color: $warning;
  }

  &.danger {
    background-color: $danger;
  }

  &.info {
    background-color: $info;
  }

  span {
    margin-right: 0.5rem;
    line-height: 0;
    white-space: nowrap;
  }

  svg {
    transform: translateY(-2px);
  }
}
