@import 'variables.scss';

.stepTwoBody {
  height: 540px;
  .stepTwoText {
    font-weight: 600;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 0 25px;
  }

  .stepTwoInfoCard {
    background-color: $light;
    border-top: 1px solid $lightBorder;
    border-bottom: 1px solid $lightBorder;
    margin: 10px 0;
  }

  .stepTwoNegotiation {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    margin: 10px 0;
    height: 40px;    
    

    .stepTwoNegotiationSpan {
      display: flex;
      width: 200px;

      span {
        font-weight: 600;
        font-size: 14px;
      }
    }

    .stepTwoNegotiationSelect {
      display: flex;
      flex-direction: row;
      gap: 15px;
    }
  }

  .stepTwoSale {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    height: 40px;
    border-top: 1px solid $lightBorder;

    .stepTwoSaleSpan {
      display: flex;
      width: 200px;

      span {
        font-weight: 600;
        font-size: 14px;
      }
    }

    .stepTwoSaleInput {
      background-color: $white;

      &::placeholder {
        color: $black;
        font-weight: 500;
      }
    }
  }

  .stepTwoSalary {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    height: 40px;
    border-top: 1px solid $lightBorder;

    .stepTwoSalarySpan {
      display: flex;
      width: 200px;

      span {
        font-weight: 600;
        font-size: 14px;
      }
    }

    .stepTwoSalaryData {
      display: flex;
      
      label {
        min-width: 90px;
      }
    }

    .stepTwoSalaryInput {
      background-color: $white;
      margin-right: 20px;

      &::placeholder {
        color: $black;
        font-weight: 500;
      }
    }

    .stepTwoSalarySpan2 {
      font-weight: 600;
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .stepTwoObservation {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 20px;
    height: 160px;
    border-top: 1px solid $lightBorder;

    .stepTwoObservationSpan {
      display: flex;
      width: 200px;

      span {
        font-weight: 600;
        font-size: 14px;
      }
    }

    .stepTwoObservationInput {
      font-family: inherit;
      font-weight: 400;
      font-size: 16px;
      background-color: $white;
      padding: 10px;
      vertical-align: top;
      height: 140px;
      width: 30rem;
      resize: none;
      border-radius: 8px;
      border: 1px solid $lightBorder;
    }
  }
}
