@import 'variables.scss';

.content {
  background-color: $white;
  min-height: 100%;
  z-index: 1;

  &.appBarVisible {
    margin-top: 60px;
    @media print {
      margin-top: 0;
    }
  }
}

.excededPlanLimit {
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}