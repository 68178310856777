@import 'variables.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;

  .searchIconTop {
    filter: brightness(0) invert(1);
    position: relative;
    right: 35px;    
    &:hover {
      cursor: pointer;
    }
  }

  .input {
    color: #ffffff;
  }

  span {
    .advancedSearchIcon {
      filter: brightness(0) invert(1);
      position: relative;
      right: 25px;
    }
  }

  .link {
    margin-left: 0.7rem;
    white-space: nowrap;
    color: $spanishWhite;
    font-size: small;
    transition: opacity 300ms;

    &:hover {
      cursor: pointer;
      color: $lighterGrey;
    }
  }
}
