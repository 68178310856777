@import 'variables.scss';

.validationMsg {
  margin-bottom: 20px;
  width: auto;
  position: inherit;
}

.dialogContainer {
  width: 740px;
}

.content {
  display: flex;

  label {
    font-size: 14px !important;
  }

  input {
    padding: 7px 11px !important;
    font-size: 15px !important;
    border-radius: 8px;
  }

  .calendar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $lightBorder;
    border-radius: 8px;
    background-color: white;
    width: 197px;

    input {
      padding: 5px 6px !important;
    }

    .calendarIcon {
      color: #3b4758;
      padding-right: 10px;
    }
  }

  .athletePhotoDiv {
    margin-right: 10px;
    text-align: center;

    .athletePhoto {
      border-radius: 50%;
      margin-bottom: 10px;
      margin: 0 15px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-size: cover;
      height: 150px;
      min-height: 150px;
      min-width: 150px;
      -webkit-print-color-adjust: exact;
      align-self: center;
      border: 1px solid $lightBorder;
    }

    .editButton {
      margin-left: 0;
    }

    h5 {
      color: $darker;
      margin: 10px 0;
      text-align: start;
      font-weight: 600;
    }
    
    .formGroup {
      margin-bottom: 15px;

      input {
        padding: 7px 15px 7px 30px !important;
        font-family: 'Barlow';
      }

      svg {
        font-size: 12px;
        left: 12px;
        top: 14px; 
        width: auto;
      }
    }
  }

  .athleteInfo {
    width: 100%;

    .formGroup {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      label {
        min-width: 120px;
        margin-right: 15px;
        text-align: right;
      }
    }
  }
}

.teamRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    margin: 0 !important;
    padding: 5px 16px !important;
  }
}

.formGroupHeightWeight {
  display: flex;
  align-items: center;
  
  span label {
    min-width: auto !important;
    margin-right: 0 !important;
  }
}

.heightWeightUnit {
  position: absolute;
  right: 10px;
  top: 10px;
  font-weight: normal;
  font-size: 12px;
}

.largeSelect {
  border: 1px solid $lightBorder;
  border-radius: 8px;
  background-color: $white;
  width: 100%;
  font-size: 15px;
}

.large_select_no_scroll {
  border: 1px solid $lightBorder;
  border-radius: 8px;
  background-color: $white;
  width: 100%;
  font-size: 15px;

  div {
    &::-webkit-scrollbar {
      display: flex !important;
    }
  }
}

.loader {
  display: flex;
  margin: 6px 20px;
  justify-content: center;
}

.bottomMessage {
  color: $darker;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  font-size: 15px;

  small {
    font-weight: 500;
  }
}
