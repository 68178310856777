@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'variables.scss';
@import '../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';

html,
body,
#root {
    margin: 0;
    font-family: 'Barlow', sans-serif;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    height: auto;
    background-color: $white;

    min-height: 100%;
    letter-spacing: 0px;


    @media print {
        visibility: hidden;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

button {
    font-family: 'Barlow', sans-serif;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

h2 {
    font-weight: 500;
}

@page {
    size: A4 portrait;
    margin-top: 0.7cm;
    margin-bottom: 0.7cm;
}

@page :right {
    @bottom-right {
        content: counter(page);
    }
}

@page :left {
    @bottom-left {
        content: counter(page);
    }
}

@media print {

    html,
    body {
        width: 21cm;
        height: calc(29.7cm - 1px);
        font-size: 0.8em;
        margin: 0;
    }
}

.printable {
    @media print {
        visibility: visible !important;
        //height: calc(29.7cm - 1px);
        width: 21cm;
    }
}

.hideMobile {
    @media (max-width: $maxSmall) {
        display: none;
    }
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 1rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mr-1 {
    margin-right: 1rem;
}

.mr-2 {
    margin-right: 2rem;
}

.mr-3 {
    margin-right: 3rem;
}

.ml-1 {
    margin-left: 1rem;
}

.ml-2 {
    margin-left: 2rem;
}

.ml-3 {
    margin-left: 3rem;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    width: 5px !important;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
    height: 5px !important;
}

.softShadow {
    box-shadow: 1px 1px 3px $lighterBorder;
}

.overflowHidden {
    overflow: hidden;
}

.lightBg {
    background-color: $light;
}

.hoverable {
    &:hover {
        cursor: pointer;
    }
}