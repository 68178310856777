@import 'variables.scss';

.clubDiv {
    display: flex;
    align-items: center;

    .avatar {
        height: 40px;
        margin-right: 10px;
        width: 40px;
    }

    a {
        font-weight: 600 !important;
    }
}

.link {
    display: inline;
    text-decoration: none;
    color: $darkPurple9;
    font-size: 10pt;
    font-weight: 500;
    white-space: nowrap;

    @media print {
        color: $dark;
        font-size: 9pt;
    }
}