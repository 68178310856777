@import 'variables.scss';

.evaluations {
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  max-width: 1624px;
  margin: 0 auto;

  .header_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;

    .header {
      font-size: 24px;
      font-weight: 700;
      color: $black;
    }

    .sub_header {
      font-size: 16px;
      font-weight: 400;
      color: $black;
    }
  }

  &:hover {
    box-shadow: 2px 2px 5px transparent;
  }

  h3 {
    margin: 0;
    font-size: 1.25em;
    margin-bottom: 8px;
  }

  .filter_container {
    display: flex;
    gap: 10px;
    align-items: center;

    .menu {
      background-color: $white;
      border: 1px solid $lightBorder;
      font-size: 12px;
      font-weight: 500;
      padding: 0.65rem 0.95rem;
      display: inline-block;

      @media (max-width: $maxSmall) {
        margin-bottom: 10px;
      }

      svg {
        top: 0;
        right: 0;
      }
    }

    button {
      margin-left: auto;
    }    
  }

  .amountInfo {
    color: black;
  }

  .textHeader {
    margin-bottom: 25px;
    margin-top: 25px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(50vh);
}

.deleteButton {  
  width: 100px;
  height: 35px;
  border-radius: 8px;
  background-color: $lightPurple1 !important;
  margin: 0px;
  margin-left: 0px !important;

  span {
    font-size: 14px;
    color: $white;
  }

  svg {
    margin-left: 10px;
    color: $white;
    width: 18px;
    height: 18px;
  }
}

.fixEnd {
  margin-left: auto !important;
}