@import 'variables.scss';

.formGroup {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 10px;
  margin-bottom: 1rem;

  .formLabel {
    display: flex;
    // flex: 0 0 150px; // Ajuste este valor conforme necessário
    // text-align: right;
    margin-right: 1rem;
    font-weight: 500;
    font-size: 14px;
    align-self: flex-start;
    margin-top: 12px;
  }

  .formInput {
    flex: 1;
  }

  .formExample {
    display: block;
    margin-top: 0.25rem;
    font-size: 0.875rem;
    color: #6c757d;
  }

  .radioContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  width: 32%;

  input {
    padding: 5px 10px;
    border: 1px solid $lightBorder;
  }

  .calendarIcon {
    color: #3b4758;
    margin-left: 10px;

    svg {
      width: 25px;
      height: 25px;
    }
  }
}

.largeSelect {
  border: 1px solid $lightBorder;
  border-bottom: 1px solid $lightBorder;
  border-radius: 8px;
  background-color: $white;
  max-width: 100%;
  font-size: 15px;
  padding: 12px;

  span {
    line-height: 20px;
    white-space: nowrap;
    // max-width: 85px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.largeSelectError {
  border: 1px solid red;
}

.largeTextField {
  border: 1px solid $lightBorder;
  border-radius: 8px;
  background-color: $white;
  width: 100%;
  font-size: 15px;
  padding: 12px;

  span {
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.textAreaField {
  background-color: white !important;
  resize: none;
  border-radius: 8px;
  width: 100%;
  margin-top: 0;
}

.textAreaFieldDisabled {
  box-sizing: border-box;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  height: auto;
  padding: 0.6rem;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  background-color: #f5f5f5;
  width: auto;
  font-size: 13px;
}

.info {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;

  .infoIcon {
    color: #3b4758;
    margin-right: 5px;
  }
}

.numberField {
  border: 1px solid $lightBorder;
  border-radius: 8px;
  background-color: $white;
  display: flex;
  width: 80px;
  font-size: 1rem;
  padding: 5px 10px;
  line-height: 1.5;
}

.numberField2 {
  padding: 0.575rem 0.75rem;
  width: 25px;
  line-height: 1.5;
  border-radius: 8px;
  border: 1px solid $lightBorder;
  background-color: white;
  color: $black;
  font-size: 0.9rem;
  outline-color: $lightPurple1;
  transition: border-color 300ms, background-color 300ms, box-shadow 200ms;
  margin: 0 5px 0 5px;
  text-align: center;
}

.timeInputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  width: 32%;

  .timeInput {
    padding: 5px 10px;
    border: 1px solid $lightBorder;
    outline: none;
    width: 80px;
    font-size: 1rem;
    line-height: 1.5;
    color: #4a4a4a;
    text-align: center;
    border-radius: 8px;
  }

  .timeIcon {
    color: #3b4758;
    margin-left: 10px;

    svg {
      width: 25px;
      height: 25px;
    }
  }
}
