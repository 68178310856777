@import 'variables.scss';

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(100vh - 60px);
}

.content {
  box-sizing: border-box;
  // margin: 0.6rem;
  margin-top: 0;
  padding-top: 100px;
  background-color: $white;

  @media (max-width: $maxMedium) {
    padding-top: 150px;
  }
}

.container {
  background-color: $light;
}
