@import 'variables.scss';

.menu {
    background-color: #ffffff;
    margin-right: 10px;
    border: 1px solid $lightBorder;
    height: 19px;
    font-size: 14px;
    border-radius: 8px;
    padding: 10px 15px 10px 15px;
}