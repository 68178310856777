@import 'variables.scss';

.socialButton {
  background: transparent;
  border: 1px solid $lightBorder;

  &.large {
    width: 40px !important;
    height: 40px !important;

    svg {
      width: 20px;
    }
  }
  
  svg {
    width: 15px;
    height: auto;
  }
}