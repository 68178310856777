@import 'variables.scss';

.topBar {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 65px;
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    border-bottom: 1px solid $lighterBorder;
    overflow: hidden;
    position: fixed;
    left: 0;
    z-index: 2;

    @media (max-width: $maxMedium) {
        flex-direction: column;
        height: 150px;
    }

    .pageBack {
        display: flex;
        align-items: center;
        padding: 0;

        button,
        a {
            background-color: transparent;
            border: none;
            font-size: xx-large;
            font-weight: 400;
            padding: 0 10px;
            line-height: 1rem;

            &:hover,
            &:active,
            &:visited {
                color: $darker;
            }
        }

        span {
            padding-left: 20px;
        }
    }

    .titleDiv {
        display: flex;
        align-items: center;

        .title {
            color: $darker;
            font-size: x-large;
            padding-left: 15px;
            margin: 0;
            font-weight: 700;
        }

        .subtitle {
            padding-left: 15px;
            font-size: small;
            color: $dark;
        }
    }
}
