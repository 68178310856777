@import 'variables.scss';

.container {
    position: fixed;
    background-color: $white;
    border-top: 1px solid $lightBorder;
    bottom: 0;
    z-index: 9;

    .content {
        margin-top: 15px;
        padding-bottom: 15px;

        .pagination {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-size: smaller;
            }

            .divButtons {
                margin-left: 10px;

                button {
                    background-color: $lightPurple1;
                    border: none;
                    color: $white;
                    padding: 8px;
                    border-radius: 8px;
                    line-height: 0.5rem;
                    margin-right: 5px;

                    &:disabled {
                        background-color: #EEE5FF;
                        border: none;
                        color: $lightGrey;
                        border-radius: 8px;

                        &:hover {
                            opacity: inherit;
                        }
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }

        .itensPerPage {
            display: flex;
            align-items: center;
            right: 2pc;
            top: 16px;
            position: absolute;

            span {
                font-size: smaller;
                margin-right: 8px;
            }

            svg {
                position: relative;
                right: 15px;
                width: 10px;
                height: 10px;
            }
        }
    }
}

.spacer {
    height: 65px;
    background-color: $white;
}