@import 'variables.scss';

.languageButton {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-left: 40px;
  margin-right: 60px;

  &.femaleBackgroundColor {
    button {
      color: #ffffff;
      border-color: #c300ff;
      background-color: #c300ff;
    }
  }

  &.maleBackgroundColor {
    button {
      color: #ffffff;
      border-color: #360099;
      background-color: #360099;
    }
  }

  &.generalBackgroundColor {
    button {
      color: #000;
      justify-content: flex-start !important;
      border-color: #ffffff;
      background-color: #ffffff;
      padding-left: 6px !important;
    }
  }

  button {
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    height: 34px;
    width: 150px;
    padding-left: 10px;
    font-size: 12px;
    position: relative;

    &:focus {
      border: 1px solid white;
    }
  }

  .genderLabel {
    display: flex;
    gap: 5px;
    font-size: 10px;
    font-size: 14px;
    align-items: center;
  }

  svg {
    width: 20px;
    height: 26px;
  }
}

.noHover {
  border: 1px solid transparent;
  pointer-events: none;
  cursor: default;
}

.arrowDown {
  color: #fff;
  position: relative;
  right: 28px;
  font-size: 16px;
  pointer-events: none;
}

.iconGeneralGender {
  display: flex;
  align-items: center;
}

.arrowDownGeneral {
  color: #000;
  position: relative;
  right: 28px;
  font-size: 16px;
  pointer-events: none;
}
