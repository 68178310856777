//@import 'variables.scss';

.container {
    background-color: #fff;
}

.targetIcon {
    margin: 1px;
}

.plusIcon {
    margin: 1px;
}