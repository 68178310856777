@import 'variables.scss';

.content {
  position: relative;
  right: 0;
  left: 0;
  top: 150px;
  box-sizing: border-box;
  background-color: $white;
}

.contentContainer {
  left: 0;
  right: 0;
  height: auto;
  margin-left: 30px;
  margin-right: 30px;
}

.filterContainer {
  padding-left: 5px;
  text-align: left;
  left: 25px;
  position: absolute;
  right: 0;
}

.menu {
  border: 1px solid $lightBorder;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 0.65rem 0.95rem;
  display: inline-block;
  margin-left: 10px;

  @media (max-width: $maxSmall) {
    margin-bottom: 10px;
  }
}

.filterInputContainer {
  display: inline-block;
  max-width: 300px;
  padding: 10px 0;
  text-align: center;
}

.filterInput {
  font-size: 0.9em;
  height: 20px;
  background-color: white !important;
}

.searchIcon {
  color: $lightPurple1;
  height: 20px;
  width: auto;
  top: 10px !important;
  transform: scaleX(-1);
  margin-top: 2px;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(100vh - 60px);
}

.errorContainer {
  p {
    margin: 0;
  }
}

.title {
  color: #000000;
  position: relative;
  padding: 20px 0 0 25px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.subTitle {
  color: #000000;
  position: relative;
  left: 20px;
  padding: 0 0 0 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}