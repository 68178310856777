@import 'variables.scss';

.container {
  display: flex;

  .club_container {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 400;
      font-size: 14px;
    }

    .club_info_container {
      display: flex;

      img {
        height: 40px;
        width: auto;
      }

      .club_info_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;

        .club_name {
          font-size: 16px;
          font-weight: 600;
          color: $lightPurple1;
          display: flex;
        
          svg {
            cursor: pointer;
            margin-left: 5px;
            height: 22px;
            width: 22px;
            color: $darkPurple9;
          }
        }

        .club_contract {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .club_message {
      width: 150px;
      height: 40px;
      padding: 10px;
      margin-top: 10px;
      background-color: $lightPurple1;
      border-radius: 8px;
      flex-direction: row-reverse;
      gap: 15px;

      span {
        text-wrap: nowrap;
        font-size: 14px;
        font-weight: 600;
        color: $white;
      }

      svg {
        color: $white;
        width: 16px;
        height: 16px;
      }

      &:hover {
        background-color: $lightPurple2;
      }
    }
  }
}