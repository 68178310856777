@import 'variables.scss';

.telephone {
  display: flex;

  .number {
    display: flex;
    margin-top: 18px;

    .ddd {
      width: 120px;
      margin-right: 10px;
    }

    .phone {
      width: 120px;
      margin-right: 10px;
    }
  }

  .ddiLabel {
    display: flex;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: $dark;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
  }

  .ddi {
    padding-left: 5px;
    padding-right: 5px;
    height: 38px;
    border: 1px solid $lighterGrey;
    margin-right: 10px;
    vertical-align: middle;
  }
}
