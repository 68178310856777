@import 'variables.scss';

.menuList {
    display: flex;
    margin-bottom: 1rem;

    .menu {
        margin-right: 0.5rem;
        background-color: $lighterGrey;
        font-size: 12px;
        font-weight: 500;
        padding: 0.65rem 0.95rem;
    }
}

.evaluations {
    margin-bottom: 1.5rem;

    .evaluationTable {
        margin-top: 10px;

        .club_column {
            text-align: left;
        }

        thead tr {
            background-color: $white;

            th {
                border: 1px solid $lightBorder;
                border-right: none;
                border-left: none;
                padding: 5px !important;

                &:first-child {
                    padding-left: 20px !important;
                    border-left: 1px solid $lightBorder;
                }

                &:last-child {
                    border-right: 1px solid $lightBorder;
                }

                div {
                    justify-content: left !important;
                    color: $black;
                    font-weight: 600;
                }
            }
        }

        tbody tr td {
            padding: 5px !important;
      
            &:first-child {
              padding-left: 20px !important;
            }
      
            &:nth-child(2) {
              div {
                img {
                  height: 30px;
                  width: 30px;
                }
              }
            }
      
            &:last-child {
              div {
                text-align: center !important;
              }
            }
      
            div {
              text-align: left;
      
              img {
                height: 20px;
                width: auto;
              }
            }
          }
    }

    .withImage {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 25px;
            height: auto;
            margin-right: 0.3rem;
        }
    }

    .link {
        text-decoration: none;
        color: $dark;
        font-size: small;
        //---letter-spacing: -1px;
        font-weight: 500;
        transition: color 300ms;

        &:hover {
            color: $lightPurple1;
        }
    }

    button {
        font-size: small;
        padding: 0.3rem;
        white-space: nowrap;
    }

    .scoreboard {
        font-weight: 500;
        font-size: large;
        //---letter-spacing: 1px;
    }

    .flagsDiv {
        display: flex;
        align-items: center;

        svg {
            font-size: 25px;

            @media print {
                width: 18px;
            }
        }

        .countryFlag {
            margin-left: 10px;
            margin-right: 8px;
            width: 25px;

            @media print {
                width: 18px;
            }
        }
    }
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.priority{
    display: flex;
    align-items: center;
    flex-direction: row;

    div {
        margin-right: 5px;
    }
}

.lowPriority {
    @extend .circle;

    background-color: $info;
}

.mediumPriority {
    @extend .circle;

    background-color: $warning;
}

.highPriority {
    @extend .circle;

    background-color: $danger;
}