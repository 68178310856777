@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(100vh - 60px);
  background-color: $white;
}

.is_retired {
  height: 50px;
  background-color: $red;
  color: $white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg {
    margin-right: 8px;
    font-size: 24px;
  }

  .close_is_retired {
    font-size: 13px;
    cursor: pointer;
    margin-left: 2px;
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 50vh;
}

.errorContainer {
  margin: 0.6rem;
  padding: 2rem;
  text-align: center;

  h2 {
    color: $dark;
    margin: 0;
  }
}

.content {
  .navigationDiv {
    display: flex;
    justify-content: space-between;

    .navigationBar {
      width: 100%;
    }
  }

  // @media (min-width: $minVeryLarge) {
  //   width: 1300px;
  //   margin-left: auto;
  //   margin-right: auto;
  // }

  @media (max-width: $maxMedium) {
    padding-top: 220px;
  }
}

.customDialogBody {
  padding: 0px !important;
}

.migrationDialog {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  
  b {
    font-weight: 600;
    font-size: 16px;
    line-height: 22.4px;
  }
}

.searchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.formatH3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 25.6px;
  margin: 0px 15px;
}

.athleteContainer {  
  display: flex;
  flex-direction: row;
  margin: 15px;
  gap: 10px;

  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 25.6px;
    margin: 5px 0px;
  }
}

.loadingFootlinkAthlete {
  text-align: center;
  margin-bottom: 15px;
}

.errorMessage {
  margin-top: 10px;
  color: $red;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 600px;
}