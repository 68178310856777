@import 'variables.scss';

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(100vh - 60px);
}

.content {
  box-sizing: border-box;
  margin-top: 0;
  padding-top: 65px;
  min-height: calc(100vh - 60px);

  @media (max-width: $maxMedium) {
    padding-top: 150px;
  }

  .navigationDiv {
    display: flex;
    justify-content: space-between;
    padding: 0 25px 0 10px;
    border-bottom: 1px solid $lightBorder;
  }
}

.container {
  background-color: $white;
  height: auto;
  min-height: 100%;

  .navigationBar {
    border-bottom: none;
  }
}
