@import 'variables.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & .leftPanel,
  & .middlePanel,
  & .rightPanel {
    display: flex;
  }

  & .middlePanel {
    width: 55vw;
    padding: 0 20px;
  }

  & .leftPanel {
    display: block;
    padding-left: 1rem;
    width: 380px;
    justify-content: center;
  }

  & .rightPanel {
    padding-right: 10px;
  }

  & .latestNews {
    width: 100%;
    padding-top: 0px;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(50vh);
}

.dialog {
  width: 850px;
  height: auto;
  overflow-y: auto;

  .dialogBody {
    width: auto;
    padding: 0;
    overflow: unset;
    border-radius: 8px;

    .body_container {
      display: flex;
      flex-direction: row;
      height: 300px;

      .left_container {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 400px;
        padding: 20px;

        .avatar_container {
          display: flex;
          gap: 20px;

          .club_avatar {
            width: 90px;
            height: 90px;
          }

          .avatar_side {
            display: flex;
            flex-direction: column;
            gap: 10px;

            span {
              font-weight: 600;
              font-size: 16px;
              color: $black;
            }

            .avatar_button {
              background-color: white;
              border-radius: 8px;
              border: 1px solid $lightPurple1;
              color: $lightPurple1;
              font-weight: 700;
            }
          }
        }

        .images_container {
          display: flex;
          flex-direction: column;

          .verificationContainer {
            margin-top: 15px;

            .verificationCheckboxes {
              display: flex;
              flex-direction: column;
              gap: 10px;

              .checkboxe {
                font-size: 14px;
              }
            }
          }

          h1 {
            font-weight: 600;
            font-size: 16px;
          }

          .images_display {
            display: flex;
            flex-direction: row;
            height: 120px;

            .carousel_container {
              height: 120px;
              width: 180px;

              .image {
                width: 180px;
                height: 120px;
              }

              .agency_general_no_image {
                background-color: $lightPurple3;
                width: 100%;
                height: 100px;
              }
            }

            .delete_image_icon {
              border: 1px solid $lightPurple1;
              background-color: $white;
              border-radius: 8px;
              width: 50px;
              height: 40px;
              align-self: end;
              margin-left: 10px;

              svg {
                color: $lightPurple1;
                width: 20px;
                height: 20px;
              }
            }
          }

          .add_image_button {
            background-color: $white;
            border-radius: 8px;
            border: 1px solid $lightPurple1;
            color: $lightPurple1;
            font-weight: 700;
            margin-top: 15px;
            width: 160px;
          }

          .images_dots {
            display: flex;
            justify-content: center;
            gap: 10px;
            width: 180px;
            margin-top: 5px;

            .image_dot {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: $white;
              border: 1px solid $lightPurple1;

              &.selected {
                background-color: $lightPurple1;
              }
            }
          }
        }
      }

      .right_container {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        gap: 15px;
        border-left: 1px solid $lightBorder;

        h1 {
          padding-left: 20px;
        }

        input {
          background-color: $white;
        }

        span {
          color: $black;
          align-self: center;
          width: 200px;
          font-weight: 600;
          font-size: 14px;
        }

        .input_city {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid $lightBorder;
          padding: 0px 20px 10px 20px;
        }

        .input_email {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid $lightBorder;
          padding: 0px 20px 10px 20px;
        }

        .input_main_market {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid $lightBorder;
          padding: 0px 20px 10px 20px;

          .country_menu {
            font-weight: 500;
            font-size: 14px;
            background-color: $white;
            border-radius: 8px;
            border: 1px solid $lightBorder;
            padding: 12px;
            width: 93%;
          }
        }

        .input_site {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid $lightBorder;
          padding: 0px 20px 10px 20px;
        }

        .input_instagram {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid $lightBorder;
          padding: 0px 20px 10px 20px;
        }

        .input_twitter {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid $lightBorder;
          padding: 0px 20px 10px 20px;
        }

        .input_youtube {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid $lightBorder;
          padding: 0px 20px 10px 20px;
        }

        .input_linkedin {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid $lightBorder;
          padding: 0px 20px 10px 20px;
        }

        .input_facebook {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid $lightBorder;
          padding: 0px 20px 10px 20px;
        }
      }
    }

    .dialog_footer {
      display: flex;
      margin-top: 358px;
      border-top: 1px solid $lightBorder;
      padding: 10px;
      justify-content: flex-end;
      gap: 10px;

      .dialog_footer_button_cancel {
        background-color: $white;
        border-radius: 8px;
        border: 1px solid $lightPurple1;
        color: $lightPurple1;
        font-weight: 600;
        width: 100px;
      }

      .dialog_footer_button_save {
        background-color: $lightPurple1;
        border-radius: 8px;
        border: 1px solid $lightPurple1;
        color: $white;
        font-weight: 600;
        width: 80px;
      }
    }

    .recomendedDimension {
      margin-top: 5px;
      font-size: 11px;
      color: $gray2;
    }
  }
}
