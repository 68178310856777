@import 'variables.scss';

.container {
    color: black;
    background-color: $white;
    width: 100%;
    height: calc(100% - 65px);
    // position: absolute;
    top: 60px;
    left: 0;
}

.containerFlex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.TopNavigation {
    display: flex;
    flex: 0;
}

.negotiationNotification::after {
    content: '';
    width: 6px;
    height: 6px;
    position: relative;
    background-color: $red;
    border-radius: 50%;
    margin-top: -8px;
    margin-left: 6px;
    margin-right: -12px;
}

.content {
    display: flex;
    flex: 1;
}