@import 'variables.scss';

.athleteDiv {
  display: flex;
  align-items: center;

  .avatar {
    min-width: 30px;
    width: 30px;
    height: 30px;
    margin-right: 0.5rem;
  }
}

.link {
  display: inline;
  text-decoration: none;
  color: $lightPurple1;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;

  h5 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    text-align: start;

    b {
      color: #111111;
      font-weight: 500;
      font-size: 8pt;
    }
  }

  span {
    //---letter-spacing: -0.4px;
    font-weight: 400;
    font-size: 14px;
    overflow: hidden;

    color: $black;
    text-overflow: ellipsis;
  }
}
