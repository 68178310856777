@import 'variables.scss';

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(100vh - 60px);
}

.content {
  background-color: $white;
  box-sizing: border-box;
  margin-top: 0;
  padding-top: 100px;

  .contentMargin {
    margin: 0 1.3rem;
  }

  @media (max-width: $maxMedium) {
    padding-top: 150px;
  }
}

.navigationBar {
  border-bottom: 0;
}

.container {
  background-color: $light;
}
