@import 'variables.scss';

.container {
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: $black;
  font-weight: bold;
  line-height: 15px;
}

.inputContainer {
  display: flex;
  box-sizing: border-box;
  position: relative;
  // margin-top: 5px;
  //padding-bottom: 5px;

  &.hasIconLeft {
    svg {
      @extend .icon;

      left: 15px;
    }
  }

  &.hasIconRight {
    svg {
      @extend .icon;
      right: 15px;
    }
  }

  &.profileInput {
    margin-top: 5px;
  }

  .input {
    padding: 0.575rem 0.75rem;
    width: 100%;
    line-height: 1.5;
    border-radius: 8px;
    border: 1px solid $lightBorder;
    background-color: $light;
    color: $black;
    font-size: 0.9rem;
    outline-color: $lightPurple1;
    transition: border-color 300ms, background-color 300ms, box-shadow 200ms;

    &.hasIconLeft {
      padding-left: 38px;
    }

    &.hasIconRight {
      padding-right: 38px;
    }

    &.hasError {
      border-color: $danger;
      background-color: $dangerBg;
      outline-color: $danger;
    }

    &.smaller {
      font-size: small !important;
    }

    &.rounded {
      //height: 25px;
      border-radius: 8px;
      outline: none;
      padding-left: 1rem;
      font-size: 14px;
      font-weight: 400;
      background-color: $white;
      color: $black;

      &::placeholder {
        color: $black;
      }

      &:focus {
        border: 1px solid $lightPurple1;
      }
    }

    &.roundedBorderWhite {
      height: 25px;
      border-radius: 8px;
      outline: none;
      padding-left: 1rem;
      background-color: $lightPurple1;

      &:focus {
        border: 1px solid $white;
      }
    }

    &.profileInput {

      &:focus {
        border: 1px solid $white;
      }
    }

    &.darkMode{
      background-color: $darkPurple1 !important;
      border-color: $darkPurple7 !important;
    }
  }

  ::placeholder {
    color: $white;
    font-family: 'Barlow', sans-serif;
  }
}

.icon {
  position: absolute;
  top: 13px;
  height: auto;
  width: 15px;
}