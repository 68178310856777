@import 'variables.scss';

.formGroup {
  margin-bottom: 10px;

  label {
    color: $dark;
    font-size: 14px;
    margin-right: 5px;
  }

  span {
    font-size: small;
    font-weight: 500;
    margin-right: 5px;
  }

  &.starRating {
    display: flex;
    justify-content: space-between;
  }
}

.allInfo {
  border: solid $lightGrey 1px;
  border-spacing: 0;
  border-collapse: collapse;

  div {
    margin-bottom: 0;
  }

  div:last-child {
    // border-bottom: none;
    // margin-bottom: 0;
  }
}

.buttonAddFile {
  font-size: 14px;
  font-weight: 700;
  margin-left: auto;
}

.mainInfo {
  display: flex;
  align-items: center;
  break-after: avoid;
  page-break-after: avoid;
  -webkit-break-after: avoid;
  box-shadow: none;
  padding: 12px 0 0 0;
  margin: 0 20px;
  border-bottom: 1px solid $lightBorder;

  &:hover {
    box-shadow: none;
  }

  .athletePhotoDiv {
    margin-right: 30px;
    text-align: center;

    .athletePhoto {
      border-radius: 50%;
      margin-bottom: 10px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-size: cover;
      height: 150px;
      min-height: 150px;
      min-width: 150px;
      -webkit-print-color-adjust: exact;
      align-self: center;
    }
  }

  .athleteInfo {
    width: 100%;

    .generalData {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      padding-bottom: 5px;

      .leftContent {
        .athleteName {
          display: flex;
          align-items: baseline;

          p {
            font-size: 28px;
            font-weight: 700;
            margin-right: 10px;
            margin-bottom: 5px;
            margin-top: 0;
            line-height: 28px;
          }

          span {
            font-size: 16px;
            margin-right: 10px;
            font-weight: 400;
            line-height: 19.2px;
          }
        }

        .athleteAge {
          img {
            height: 15px;

            &:not(:last-child) {
              margin-right: 5px;
            }
          }

          .formGroup {
            display: flex;
            gap: 7px;
            align-items: center;
            font-size: 14px;
            line-height: 16.8px;

            :first-child {
              margin: 1px solid red;
            }

            div {
              display: flex;
              gap: 5px;
            }

            p {
              font-weight: 400;
            }

            span {
              font-size: 14px;
              font-weight: 600;
              margin-right: 0;
            }
          }
        }
      }

      .rightContent {
        .formGroup {
          display: flex;

          label {
            margin-left: 30px;
          }
        }
      }
    }

    .aditionalData {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;

      > div {
        display: inline-block;
        flex-direction: column;
        justify-content: center;
      }

      .formGroup {
        margin-bottom: 10px;
        line-height: 16.8px;
        font-size: 14px;
        white-space: nowrap;
        display: flex;
        flex-direction: column;

        p {
          font-weight: 400;
        }

        span {
          font-weight: 600;
        }

        .flags {
          color: black;
          font-weight: 500;
          font-size: small;
          display: flex;
          align-items: center;
          width: 100%;

          img {
            max-width: 21px;
            max-height: 20px;
            margin-right: 5px;
          }
        }
      }

      .birthDiv,
      .positionDiv {
        .formGroup {
          display: grid;
          grid-template-columns: 0.8fr 1fr;
          grid-template-rows: 35px;
          align-items: center;
          margin-right: 10px;
        }
      }

      .avatar {
        max-width: 25px;
        max-height: 25px;
        margin-right: 5px;

        span {
          margin: 2px;
        }
      }
    }
  }
}

.labelTitle {
  font-size: 24px;
  font-weight: bold;
  // color: $dark;
}

.athletePerformance {
  padding-top: 0;
  padding-bottom: 0;

  span {
    color: $dark;
    display: block;
    font-size: smaller;
    line-height: 1.5rem;
  }

  .generalData {
    border-bottom: 1px solid $lightBorder;
    padding: 20px 0;
    display: grid;
    grid-template-columns: 25% 75%;
  }

  .imagesContainer {
    grid-template-columns: 100% 0;

    .labelTitle {
      position: absolute;
    }
  }

  .negotiation {
    display: flex;
    margin-top: 20px;
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;

    div.info {
      width: 40%;
      padding-right: 30px;

      img {
        margin-right: 5px;
        width: 20px;
      }

      p {
        padding: 0;
        margin: 0;
      }
    }

    div.inputs {
      width: 60%;
    }

    span {
      font-size: medium;
    }
  }
}

.evaluation {
  padding: 20px 0;
  margin: 0 20px 20px 20px;
  // border-bottom: 1px solid $lightBorder;

  .evaluationtop {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 25px;

    .menuevaluation {
      background-color: white;
      border: 1px solid $lightBorder;
      font-size: small;
      font-weight: 500;
      padding: 0.5rem 1rem;
    }
  }

  .generalData {
    display: grid;
    grid-template-columns: 25% 75%;
    margin: 20px 0;

    .labelTitle {
      font-size: 16px;
    }
  }

  .generalDataEvaluation {
    display: flex;
    flex-direction: column;
    grid-template-columns: 25% 75%;
    margin: 25px 0;

    .labelTitle {
      font-size: 20px;
      margin-bottom: 12px;
      font-weight: bold;
    }

    .generalEvaluationInside {
      display: grid;
      grid-template-columns: 50% 50%;

      .evaluationResume {
        display: flex;
        flex-direction: column;

        h1 {
          font-weight: bold;
          font-size: 16px !important;
        }

        p {
          margin-bottom: 5px;
        }

        .generalEvaluationResume {
          display: flex;
          gap: 5px;
        }
      }
    }
  }

  h3 {
    font-size: 16px;
    margin-top: 0;
  }
}

.textWrapper {
  span {
    white-space: pre-wrap;
  }

  button {
    display: block;
    font-size: small;
    font-weight: 500;
  }
}

.performanceByTeam {
  table {
    tr {
      td {
        padding: 0.45rem !important;

        div {
          justify-content: center !important;
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
          div {
            justify-content: left !important;
            text-align: left !important;
          }
        }
      }

      th {
        justify-content: left;

        div {
          justify-content: center !important;

          img {
            @media print {
              height: auto;
              max-width: 1rem;
            }
          }
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
          div {
            justify-content: left !important;
            text-align: left !important;
          }
        }
      }
    }
  }
}

.transfers {
  .athleteTransfers {
    .flagsDiv {
      display: flex;
      align-items: center;

      svg {
        font-size: 25px;

        @media print {
          width: 18px;
        }
      }

      .countryFlag {
        margin-left: 10px;
        margin-right: 8px;
        width: 25px;

        @media print {
          width: 18px;
        }
      }
    }
  }
}

.contracts {
  .athleteContracts {
    .contractStatus {
      display: flex;
      align-items: center;

      .dotIcon {
        margin-right: 5px;
        width: 15px;
        height: 15px;
      }
    }

    .contractActive {
      color: $darker;
    }

    .contractInactive {
      color: $lightGrey;
    }
  }
}

.tableCard {
  border-bottom: 1px solid $lightBorder;
  margin: 20px;
  padding: 0 0 20px 0;

  h2 {
    @media print {
      page-break-after: avoid;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  div {
    @media print {
      page-break-before: avoid;
    }
  }

  table {
    thead {
      display: table-header-group;

      @media print {
        > * {
          // break-inside: avoid;
          break-after: avoid-page;
          break-before: avoid-page;
        }
      }
    }

    tr {
      td {
        @media print {
          padding: 0.6em;
          font-size: 0.9rem;
        }
      }

      th {
        div {
          img {
            @media print {
              height: auto;
              max-width: 1rem;
            }
          }
        }
      }
    }
  }

  @media print {
    padding: 10px;
    font-size: 0.7rem;
  }
}

.teamRow {
  display: flex;
  align-items: center;
  height: 32px;

  img {
    width: 28px;
    height: 30px;
    margin-right: 5px;
  }

  span {
    white-space: normal;
  }
}

.customFields {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 15px;

  .formGroup {
    margin-bottom: 0;
  }
}

.formGroupEvaluation {
  display: flex;
  flex-direction: column;
  // align-items: center;
  margin-bottom: 1rem;

  .formLabel {
    display: flex;
    // flex: 0 0 150px; // Ajuste este valor conforme necessário
    // text-align: right;
    margin-right: 1rem;
    font-weight: 500;
    font-size: 14px;
    align-self: flex-start;
    margin-top: 12px;
  }

  .textAreaField {
    width: 100%;
    background-color: white !important;
    resize: none;
    border-radius: 8px;
  }
}

.evaluationHeaderInfo {
  align-items: center !important;
  flex-direction: row;
  justify-content: center;
  display: flex;
  background-color: #f2f2f2;
  padding: 0.8rem;
  border-radius: 8px;
  justify-content: space-around;

  p {
    font-weight: bold;
    font-size: 18px;
  }

  div {
    display: flex;
    gap: 10px;
    color: $lightPurple1;
    align-items: center !important;
  }
}

.evaluationHeaderSubInfo {
  align-items: center !important;
  flex-direction: row;
  justify-content: center;
  display: flex;
  border: solid 1px #f2f2f2;
  padding: 0.8rem;
  border-radius: 8px;
  justify-content: space-between;
  margin-top: 16px;

  > div {
    display: flex;
    gap: 10px;
    align-items: center !important;
  }

  p {
    font-weight: bold;
    font-size: 18px;
  }
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.priority {
  display: flex;
  align-items: center;
  flex-direction: row;

  div {
    margin-right: 5px;
  }
}

.lowPriority {
  @extend .circle;

  background-color: $info;
}

.mediumPriority {
  @extend .circle;

  background-color: $warning;
}

.highPriority {
  @extend .circle;

  background-color: $danger;
}

.largeTextField {
  border: 1px solid $lightBorder;
  border-radius: 8px;
  background-color: $white;
  width: 100%;
  font-size: 15px;
  padding: 12px;

  span {
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.assignContainer {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  column-gap: 5% !important;
  margin-bottom: 20px !important;

  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 1rem;

    .formLabel {
      display: flex;
      margin-right: 1rem;
      font-weight: 500;
      font-size: 14px;
      align-self: flex-start;
      margin-top: 12px;
    }
  }
}

.assignContainerPalmeiras {
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 5% !important;
  margin-bottom: 20px !important;
  margin-top: 40px;

  .formGroup {
    display: grid;
    grid-template-columns: 30% 60%;
    // flex-direction: column;
    gap: 10px;
    margin-bottom: 1rem;

    .formLabel {
      display: flex;
      margin-right: 1rem;
      font-weight: 500;
      font-size: 14px;
      align-self: flex-start;
      margin-top: 12px;
    }

    .largeSelect {
      background-color: #ffffff;
      border: 1px solid $lightBorder;
      border-radius: 8px;
      width: calc(100% - 50px);
      padding: 10px 15px 10px 15px;
      font-size: 0.8rem;
      line-height: 1.5;
    }
  }
}
