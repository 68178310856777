@import 'variables.scss';

.container {
    border-radius: 8px;
    top: 52px;
    width: 250px;
    padding: 10px 0 10px 0;
    color: $darker;
    display: flex;
    box-shadow: 0px 4px 10px 0px #00000033;
    flex-direction: column;
    //border: px solid $lightBorder;

    @media (max-width: $maxSmall) {
        right: 5px !important;
    }

    h3 {
        color: $black;
        font-size: 14px;
    }

    .user {
        display: flex;
        align-items: center;
        padding: 1.5rem;
        border-bottom: 1px solid $lighterBorder;

        .userName {
            display: flex;
            flex-direction: column;
            margin-left: 1.5rem;

            h3 {
                margin: 0.2rem 0;
            }
        }
    }

    .organization {
        display: flex;
        align-items: center;
        padding: 1.5rem;
        border-bottom: 1px solid $lighterBorder;

        .organizationInfo {
            display: flex;
            flex-direction: column;
            margin-left: 1.5rem;

            .organizationLabel {
                color: $dark;
                font-size: 10px;
                text-transform: uppercase;
                font-weight: 500;
            }

            .organizationFullName {
                color: $dark;
                font-size: small;
            }

            .organizationLocation {
                display: flex;
                margin: 0.2rem 0 0.5rem;
                align-items: center;

                span {
                    color: $dark;
                    font-size: 11px;
                    margin-right: 0.5rem;
                    //---letter-spacing: -0.2px;
                }

                img {
                    max-width: 1.8em;
                }
            }

            h3 {
                margin: 0 0 0.1rem;
            }
        }
    }

    .avatar {
        transform: scale(1.3);
    }
}

.link {
    font-weight: 500;
    display: flex;
    width: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    color: black;
    font-size: 14px;
    align-items: center;

    &:hover {
        cursor: pointer;
        color: $lightPurple1;

        .icon {
            color: $lightPurple1;
        }
    }

    .icon {
        width: 24px;
        height: 24px;
        color: #3B4758;
        margin-right: 10px;
    }
}

.buttons {
    font-weight: 700;
    font-size: 14px;
    position: relative;
    width: 230px;
    color: #fff;
    margin-top: 16px;
    left: 10px;
    border-radius: 8px;
    box-shadow: none !important;
    background-color: $lightPurple1;

    .iconOut {
        position: relative;
        top: 2px;
        left: 10px;
    }

    &:hover {
        background-color: $lightPurple2;
    }
}