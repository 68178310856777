@import 'variables.scss';

.inputButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.iconSearch {
  cursor: pointer;
}
