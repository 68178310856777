@import 'variables.scss';

.container {
  border-bottom: 1px solid $lighterBorder;

  .containerRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 960px) {
      overflow: auto;
    }

    .athleteCard {
      display: flex;
      // flex: 1;
      flex-direction: row;
      align-items: center;
      position: relative;
      text-decoration: none;
      padding: 10px 5px;
      background: #fff;

      & .left {
        margin-right: 5px;
        width: 65px;

        .clubContainer {
          position: relative;
          height: 40px;
          width: 40px;
          margin-bottom: 5px;

          .athleteImg {
            position: absolute;
            top: 0;
            left: 5px;
          }
        }
      }

      & .center {
        display: flex;
        flex: 1;
        flex-direction: column;

        .name {
          color: $lightPurple1;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          letter-spacing: 0px;
        }

        .completeName {
          color: $darker;
          font-size: 14px;
          letter-spacing: 0px;
        }

        .info {
          display: flex;
          padding-top: 3px;

          & > * {
            margin-right: 8px;
          }

          img {
            width: 20px;
          }

          span {
            color: $darker;
            font-size: 12px;
          }
        }
      }
    }

    .headerTitle {
      display: block;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
    }

    .headerPanel {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      margin: 10px;

      & > p {
        margin-top: 8px;
      }
    }

    .athleteClub {
      font-size: 12px;

      & .athleteClubAvatar {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
      }

      & .club {
        width: 30px;
        height: auto;
        margin-right: 5px;
      }

      & .clubName {
        font-size: 14px;
        color: $lightPurple1;
        font-weight: 600;
      }
    }

    .athletePrice {
      & .priceText {
        margin: 0;
        color: $lightPurple1;
        font-size: 20px;
        font-weight: 600;
        height: 34px;
        align-content: center;
      }
    }

    .accordionTrigger {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      margin: 22px 20px;
      border-radius: 50%;

      &:hover {
        background-color: $lighterBorder;
        cursor: pointer;
      }
    }
  }

  .accordionContent {
    display: flex;
    flex-direction: row;
    padding: 20px 10px;

    &[hidden] {
      display: none;
    }

    & > div {
      margin: 0 10px;
    }

    .profile_button {
      width: 200px;
      height: 40px;
      padding: 10px;
      background-color: $white;
      border: 2px solid $lightPurple1;
      border-radius: 8px;
      flex-direction: row-reverse;
      gap: 10px;

      span {
        font-size: 14px;
        font-weight: 700;
        color: $lightPurple1;
      }

      img {
        filter: invert(1%) sepia(100%) saturate(7070%) hue-rotate(255deg) brightness(100%) contrast(126%);
        width: 25px;
        height: 25px;
      }
    }
  }

  .otherChatAlert {
    background: rgba(242, 201, 76, 0.3);
    color: $orange;
    border: 1px solid $orange;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & .otherChatAlertText {
      color: $darker;
      margin: 0 10px;
    }
  }

  .otherChatAlertLink {
    cursor: pointer;
  }

  .topBarBanner {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    gap: 10px;
    padding: 10px 0px 10px 20px;

    &.topBarBannerMessages {
      background-color: #f2c94c4d;
      border: 1px solid #f2994a;
      border-right: none;
      border-left: none;
    }

    &.topBarBannerInactive {
      background-color: #f6d8d7;
      border: 1px solid $red;
      border-right: none;
      border-left: none;
    }

    svg {
      color: #f2994a;
      width: 20px;
      height: 20px;
    }

    .spanButton {
      font-weight: 700;
      cursor: pointer;
      color: #f2994a;
    }
  }
}
