@import 'variables.scss';

.container {
  & .panelTitle {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border-top: 1px solid transparent;
    margin: 45px 0 30px;
    display: flex;

    p {
      position: relative;
      margin: -15px auto 0;
      padding: 6px 40px;
      border-radius: 8px;
      cursor: pointer;

      svg {
        margin-left: 8px;
      }
    }

    &.panelGroup-finished {
      border-color: $gray3;

      p {
        background: $gray3;
        cursor: default;
      }
    }

    &.panelGroup-danger {
      border-color: #eb5757;

      p {
        background: #eb5757;
      }
    }

    &.panelGroup-warning {
      border-color: #f2994a;

      p {
        background: #f2994a;
      }
    }

    &.panelGroup-ok {
      border-color: #27ae60;

      p {
        background: #27ae60;
      }
    }
  }

  .tableContainer {
    max-width: 100%;
    overflow-x: auto;
  }

  .tableEmpty {
    font-size: 16px;
    padding: 10px;
    text-align: center;
  }

  table {

    thead tr th,
    thead tr th>div {
      text-align: left;
      justify-content: start !important;
      font-weight: 600;
    }

    tbody tr td,
    tbody tr td>div {
      text-align: left;
      justify-content: start;
    }

    thead tr th:first-child {
      width: 40px;
    }

    tbody tr td:first-child>div {
      padding-left: 10px;
    }

    thead tr th:nth-child(2) {
      width: 300px;
    }

    thead tr th:nth-child(5) {
      width: 100px;

      &>div {
        justify-content: center !important;
      }
    }

    tbody tr td:nth-child(5) div div {
      text-align: center;
    }

    @media print {
      position: absolute !important;
      width: 100vw !important;
      max-width: unset !important;
      overflow-x: unset !important;
      visibility: visible !important;
    }

    td {
      padding: 0.4rem !important;
    }
  }
}

.teamContainer {
  text-align: left;

  .teamName {}

  .contractDate {
    color: black;
    font-weight: normal;
  }
}

.nationality {
  text-align: center;
}

.loanedContract {
  text-align: left;
}

.athleteImg {
  max-width: 30px;
  max-height: 30px;
}

.nationalityFlag {
  text-align: left;

  img {
    height: 20px;
    width: auto;
  }
}