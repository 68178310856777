@import 'variables.scss';

.container {
  background-color: $white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  position: fixed;
  left: 50vw;
  top: 50vh;
  transform: translate(-50%, -50%);
  width: 95vw;
  z-index: 999;

  .dialogHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: solid 1px $lightBorder;
    border-radius: 8px 8px 0 0;
    padding: 15px;

    .title {
      display: flex;
      align-items: center;

      h3 {
        font-weight: 600;                
        color: #000;        
        font-size: 1rem;
        line-height: 1.2rem;
        margin: 0;
        margin-right: 10px;
      }
    }
  }

  .dialogBody {
    background-color: #fff;
    overflow: auto;
    padding: 20px 10px;
  }

  .dialogFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid 1px $lightBorder;
    padding: 10px 10px 10px 0;
    border-radius: 0 0 8px 8px;

    .footerButtons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    button {
      display: flex;
      gap: 10px;
      margin-left: 10px;
      height: 40px;
      font-weight: 700 !important;
      font-size: 14px;
      line-height: 16.8px;
      align-items: center;    
    }
  }
}

.dialogButton {
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-size: 30px;
  color: $lightPurple1;
  font-weight: bold;
  align-self: flex-end;
  height: 30px;
  width: 30px;
}