@import 'variables.scss';

.actionBar {
  display: flex;
  padding: 0.8rem 0;
  align-items: center;
  flex-wrap: wrap;

  &>* {
    margin-top: 0.5rem;
  }

  .actionButtons {
    display: flex;
    height: 40px;

    button.disabled,
    button.disabled:hover {
      background-color: $purple9;
      cursor: default;
    }

    button {
      margin-right: 1rem;

      span {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 700;
        bottom: 2px;
        position: relative;
      }

      svg {
        margin-top: -2px;
      }
    }
  }

  .inputContainer {
    max-width: 200px;
    margin-right: 0.5rem;

    input {
      background-color: #fff;
      line-height: 1;
    }
  }

  .icon {
    top: 25% !important;
  }

  .menu {
    margin-right: 0.5rem;
    background-color: $white;
    font-size: 12px;
    font-weight: 500;
    padding: 0.65rem 0.95rem;
    border-radius: 8px;
    border: 1px solid $lightBorder;
    border-radius: 8px;
  }
}