@import 'variables.scss';

.calender_menu_card_container {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  max-height: 30vh;
  min-width: 200px;
  overflow: auto;
  box-shadow: 1px 1px 3px $lighterBorder !important;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $lighterGrey;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 51px;
    margin-bottom: 4px;
  }

  .calender_menu_card_title {
    position: absolute;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid $lightBorder;
    border-right: 1px solid $lightBorder;
    background-color: $white;
    color: $black;
    font-size: 16px;
    font-weight: 600;
    width: -webkit-fill-available;
    min-width: max-content;

    svg {
      margin-left: 1rem;
      width: 20px;
      height: auto;
      color: $lightPurple1;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.calender_menu_popover_container {
  overflow: unset !important;
  z-index: 999;

  @media (max-width: $maxSmall) {
    opacity: 1 !important;
  }
}

.calender_menu_select_container {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  border-radius: 8px;
  padding: 0.7rem;
  border: 1px solid $lightBorder;

  &.disabled {
    &:hover {
      cursor: unset !important;
    }
  }

  &:hover {
    cursor: pointer;
  }
  span {
    margin-right: 0.5rem;
    line-height: 0;
    white-space: nowrap;
  }

  svg {
    transform: translateY(-2px);
  }
}

.calender_menu_card_body {
  display: flex;
  flex-direction: column;
  margin-top: 55px;

  .calender_menu_card_clear_button {
    text-transform: none !important;
    font-weight: 600;
    font-size: 16px;
    color: $lightPurple1;
  }

  .calender_menu_card_body_calendar_container {
    display: flex;

    .calender_menu_calendar_class {
      input {
        border: 1px solid $lightBorder;
      }
    }

    .calender_menu_card_body_first_calendar {
      display: flex;
      flex-direction: column;
      padding: 10px;

      span {
        margin-bottom: 5px;
      }
    }

    .calender_menu_card_body_second_calendar {
      display: flex;
      flex-direction: column;
      padding: 10px;

      span {
        margin-bottom: 5px;
      }
    }
  }
}
