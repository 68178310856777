@import 'variables.scss';

.competition {
  padding: 15px 0 15px 0;
  height: auto;
  background-color: #ffffff;
  border: solid 1px #dddddd;


  .matchesContainer {
    padding: 0 15px 0 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
  }

  .itemContainer {
    flex: 1 1 250px;
    margin: 0 5px 20px 5px;
    box-sizing: border-box;
    max-width: calc((100% - 40px) / 5);
    min-width: 250px;
  }

  .datepicker {
    height: 130px;
    padding: 0 15px 0 15px;
    margin-bottom: 15px;
    border-bottom: solid 1px $lighterBorder;
    text-align: center;

    .monthpicker {
      background-color: transparent;
      font-size: 14px;
    }

    .daypicker,
    .daypickerselected {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      font-weight: 600;
    }

    .daypicker {
      width: 40px;
      height: 40px;
      background-color: $spanishWhite;
      color: #000000;
      margin-top: 5px;
      margin-bottom: 5px;
      cursor: pointer;
    }

    .daypickerselected {
      width: 50px;
      height: 50px;
      background-color: $darkPurple9;
      color: #ffffff;
    }

    .dayofweek {
      color: $lightGrey;
    }

    .dayofweekselected,
    .dayofweek {
      font-size: 12px;
    }
  }

}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(50vh);
}

.errorContainer {
  p {
    margin: 0;
  }
}

.matchDay {
  padding: 0 20px 0 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21.6px;
  color: #000;
}

.loadMore {
  border-color: $lightPurple1 !important;
  color: $lightPurple1 !important;

  &:hover {
    color: #fff !important;
  }

  &:active {
    color: #fff !important;
  }

  &:disabled {
    color: rgba($lightPurple1, 0.2) !important;
    border-color: rgba($lightPurple1, 0.2) !important;
    background-color: rgba($gray5, 0.5) !important;
  }
}