@import 'variables.scss';

.clubInfo {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex-basis: 30%;

  @media (max-width: $maxMedium) {
    flex-basis: unset;
  }

  @import 'variables.scss';

  img {
    width: 100%;
    height: auto;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: -8px;
  }

  .content {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 20px 0px;
    border-radius: 8px;
    border: 1px solid $lighterBorder;

    h3 {
      color: black;
      margin: 0;
      font-size: x-large;
    }

    .image {
      //   margin: -24px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      //   height: 150px;
      position: relative;
      border-radius: 8px 8px 0 0;
    }

    .fullName {
      color: $dark;
      font-size: medium;
      //---letter-spacing: -1px;
      font-weight: 400;
    }

    .locationInfo {
      display: flex;
      align-items: center;

      span {
        font-size: small;
        //---letter-spacing: -0.5px;
        color: $dark;
        margin-right: 0.5rem;
      }

      img {
        width: 24px;
        height: auto;
        box-shadow: 1px 1px 3px $lighterBorder;
      }
    }

    .marketValue {
      display: flex;
      flex-direction: column;
      color: $dark;
      margin-top: 1rem;
      text-align: right;

      h5 {
        margin: 0;
        //---letter-spacing: -0.5px;
        font-size: small;
        font-weight: 400;
      }

      span {
        font-weight: 500;
      }

      small {
        font-weight: 400;
        font-size: small;
      }
    }

    .socialButtons {
      display: flex;
      padding: 10px;

      button {
        margin-right: 0.5rem;
      }
    }

    .generalInfo {
      display: flex;
      flex-direction: row;
      padding: 10px;
      margin: 10px 0;

      .left_column {
        display: flex;
        flex-direction: column;
        margin-right: 15px;
        gap: 5px;

        span {
          font-weight: 400;
          font-size: 14px;
        }
      }

      .right_column {
        display: flex;
        flex-direction: column;
        gap: 5px;

        span {
          font-weight: 700;
          font-size: 12px;
        }
      }
    }

    .leaders {
      margin-top: 1.5rem;

      h3 {
        margin-bottom: 0.5rem;
      }

      a {
        text-decoration: none;
        color: $dark;
        font-size: 1em;
        //---letter-spacing: -1px;
        font-weight: 500;
        transition: color 300ms;

        &:hover {
          color: $lightPurple1;
        }
      }
    }

    .edit_button_container {
      display: flex;
      padding: 0px 0px 10px 10px;
      margin-top: 10px;

      .edit_button {
        background-color: $lightPurple1;
        height: 40px;
        width: 93px;
        border-radius: 8px;
        gap: 10px;

        span {
          color: $white;
          font-weight: 700;
          font-size: 14px;
        }

        svg {
          color: $white;
          height: 15px;
          width: 15px;
        }
      }
    }
  }
}
