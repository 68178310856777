@import 'variables.scss';

.container {
  position: relative;
  margin-left: 15px;

  .counter {
    position: absolute;
    display: flex;
    justify-content: center;
    right: -5px;
    top: -5px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 100%;
    padding: 2.5px;
    background-color: $danger;
    font-size: 9px;
    user-select: none;
    color: $light;
  }

  .dark{
    background-color: $darkPurple1;
  }
}