@import 'variables.scss';

.containerBackground {
  border: solid 1px $gray5;
  border-radius: 8px;
  background-color: $white;
}

.container {
  width: 714px;
  height: 504px;
  text-align: center;

  a {
    text-decoration: none;
  }
  
  .closeButton {
    display: block;
    margin: 10px 10px 1px auto;
    font-size: 30px;
    color: $lightPurple1;
    cursor: pointer;
  }

  h4 {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    padding: 0;
    margin: 21px 0 30px 0;
  }

  h5 {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    padding: 0;
    margin: 4px 0;
    color: $gray2;
  }

  h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
    margin: 8px 0;
    color: $gray2;
  }

  button {
    margin-top: 21px;
    padding: 10px 20px;
    background-color: $green4 !important;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    margin: 27px auto 0 auto;
    border: none !important;

    &:hover {
      background-color: $green2 !important;
    }

    .whatsIcon {
      color: $white;
      font-size: 24px;
      margin-right: 8px;
    }
  }
}