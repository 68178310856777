@import 'variables.scss';

.container {
  display: flex;
  flex-flow: column;
  margin-top: 30px;
  padding-right: 30px;

  h1 {
    color: $lightPurple1;
  }

  .card {
    display: flex;
    flex-flow: column;
    border: 2px solid $lightPurple1;
    border-radius: 8px;
    background-color: $lightPurple4;

    .rowInfo {
      display: flex;
      flex-flow: row;
      padding: 20px;

      .info {
        display: flex;
        flex-flow: row;
        gap: 20px;

        div {
          display: flex;
          flex-flow: column;
          width: 200px;

          &:nth-child(1) {
            background-color: $lightPurple4;

            span {
              &:nth-child(1) {
                color: black;
                font-size: 16px;
                font-weight: 600;
              }
            }

            span {
              &:nth-child(2) {
                color: $lightPurple1;
                font-size: 32px;
                font-weight: 600;
              }
            }
          }

          &:nth-child(2) {
            background-color: $lightPurple4;

            span {
              &:nth-child(1) {
                color: black;
                font-size: 16px;
                font-weight: 600;
              }
            }

            span {
              &:nth-child(2) {
                color: black;
                font-size: 18px;
                font-weight: 400;
              }
            }
          }

          &:nth-child(3) {
            background-color: $lightPurple4;

            span {
              &:nth-child(1) {
                color: black;
                font-size: 16px;
                font-weight: 600;
              }
            }

            span {
              &:nth-child(2) {
                color: black;
                font-size: 18px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .divButton {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .button {
          background-color: $lightPurple1;
          width: 150px;
          height: 40px;
          border-radius: 8px;
          color: $white;
          gap: 10px;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .lowCard {
      padding: 0 20px 20px 20px;

      div {
        display: flex;
        flex-flow: column;

        &:nth-child(1) {
          span {
            &:nth-child(1) {
              color: black;
              font-size: 16px;
              font-weight: 600;
            }
          }

          span {
            &:nth-child(2) {
              color: black;
              font-size: 16px;
              font-weight: 400;
            }            
          }
        }
      }
    }
  }
}

.wordBreak {
  word-break: normal;
  white-space: pre-wrap;
}