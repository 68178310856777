@import 'variables.scss';

.dialogContainer {
    width: 600px;
}

.content {
    .projectPhoto {
        margin-bottom: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        height: 150px;
        min-height: 150px;
        min-width: 300px;
        -webkit-print-color-adjust: exact;
        align-self: center;
    }

    .formGroup {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        label {
            min-width: 130px;
            text-align: right;
        }

        span {
            margin-left: 15px;
        }
    }
}