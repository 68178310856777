@import "variables.scss";

.competitionInfo {
    display: flex;
    align-items: center;

    &>* {
        color: $dark;
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        padding-left: 20px;

        svg {
            color: $darkPurple9;
        }

        .title {
            padding-left: 5px;
        }
    }

    .competitionName {
        .avatar {
            background-color: white;
            height: 30px;
            width: 30px;
        }
    }

    .location {
        img {
            padding-left: 5px;
            width: 25px;
        }
    }
}