@import 'variables.scss';

.container {
  padding: 100px 0 0 0 !important;
  display: flex;
  border-top: 1px solid $lightBorder;

  @media (max-width: $maxMedium) {
    flex-direction: column;
    padding-top: 180px !important;
  }
}

.noAthletesResult {
  padding: 12px 16px;
  border-radius: 8px;
  background-color: $danger;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-right: 28px;

  svg {
    font-size: 18px;
    margin-right: 10px;
  }
}

.athletesList {
  padding: 12px 16px;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.content {
  flex-basis: calc(100% - 372px);

  @media (max-width: $maxMedium) {
    flex-basis: unset;
    padding: 1rem;
  }

  h3 {
    font-size: x-large;
    margin: 0 0 1rem;
    // color: black;
    // margin: 1rem 0 0.7rem;
    // font-size: large;
    color: $dark;
    // font-weight: 700;
    letter-spacing: -1.2px;
    // white-space: nowrap;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(50vh);
}

.errorContainer {
  margin: 0.6rem;
  padding: 2rem;
  text-align: center;

  h2 {
    color: $dark;
    margin: 0;
  }
}
