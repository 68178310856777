@import 'variables.scss';

.chartContainer {
    display: flex;
    margin: 15px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}

.card {
    border: 1px solid $lightBorder;
    border-radius: 8px;
    flex: 1 1 250px;
    margin: 0 5px 20px 5px;
    padding: 0px;
    box-sizing: border-box;
    max-width: calc((100% - 30px) / 3);
    min-width: 300px;

    .header{
        background-color: $lightGrey2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 20px 15px 20px 15px;

        .number {
            font-size: 40px;
            font-weight: 500;
            line-height: 48px;
            text-align: left;
            margin-right: 10px;            
        }

        .text {
            font-size: 18px;
            font-weight: 400;
            line-height: 21.6px;
            text-align: left;
        }
    }

    .content {
        display: flex;
        flex-direction: column; 
        justify-content: center;
        padding: 15px;
    }
}