@import 'variables.scss';

.calendar {
  box-shadow: 1px 1px 3px $lighterBorder;
  right: 30px !important;
}

.input {
  outline: none;
  // display: flex;

  // &:focus {
  //     // box-shadow: 2px 2px $lightPurple1;
  //     border: 1px solid $lightPurple1;
  // }

  padding: 0.375rem 0.75rem;
  width: 80px;
  font-size: 1rem;
  line-height: 1.5;
  color: $dark;
  text-align: center;
  border: solid transparent;
  border-radius: 8px;
  // transition: border-color 300ms,
  // background-color 300ms,
  // box-shadow 200ms;
}
