@import 'variables.scss';

.linkDesired {
  text-decoration: none;
}
.container {
  display: flex;
  align-items: center;

  &:active {
    color: $darker;
  }

  &:visited {
    color: $darker;
  }

  .left {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 10px;

    .avatar {
      width: 50px;
      height: 50px;
      margin: 10px;
      div {
        background-color: $lightBorder !important;
        border-radius: 50% !important;

        span {
          color: $black;
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    width: 100%;

    .name {
      color: $black;
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }

    .completeName {
      color: $darker;
      font-size: 14px;
    }

    .info {
      display: flex;
      padding-top: 3px;

      & > * {
        margin-right: 8px;
      }

      img {
        width: 20px;
      }

      span {
        color: $darker;
        font-size: smaller;
      }
    }
  }
}

.noAthleteSelected {
  padding-left: 25px;
  height: 90px;
}
