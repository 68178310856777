@import 'variables.scss';

.topBar {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100px;
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  overflow: hidden;
  position: fixed;
  left: 0;
  z-index: 2;
  padding-bottom: 20px;

  @media (max-width: $maxMedium) {
    flex-direction: column;
    height: 180px;
  }

  .pageBack {
    display: flex;
    align-items: center;
    padding: 0;

    button,
    a {
      background-color: transparent;
      border: none;
      font-size: xx-large;
      font-weight: 400;
      padding: 0 10px;
    }

    span {
      padding-left: 20px;
    }
  }

  .left {
    display: flex;
    align-items: center;
    min-width: 500px;
    width: 27%;

    @media (max-width: $maxMedium) {
      flex-wrap: wrap;
    }

    .navigateBefore {
      color: $dark;
      height: auto;
      width: 46px;
    }

    img {
      // max-width: 1.4em;
      height: auto;
    }

    .avatar {
      margin-top: 10px;
      transform: scale(1.15);
      // width: 80px;
      // height: 80px;
    }

    .shield {
      max-width: 46px;
      width: 100%;
      height: auto;
    }

    .clubShortName {
      margin: 0 0 0 0.5rem;
      color: $lightPurple1;
      font-size: larger;
      font-weight: 600;
      text-decoration: none;
      white-space: nowrap;
    }

    .separator {
      margin: 0 0.7rem;
      color: $lightPurple1;
      font-weight: 500;
      font-size: large;
    }

    .athleteInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 0.7rem;

      .athleteName {
        display: grid;
        margin-right: 0.5rem;
        align-items: center;

        @media (max-width: $maxMedium) {
          margin-top: 0.75rem;
        }

        h1 {
          margin: 0;
          font-size: 28px;
          color: $dark;
          font-weight: 700;
          letter-spacing: -1.2px;
          white-space: nowrap;
        }

        h2 {
          margin: 0;
          font-size: large;
          color: $lightPurple1;
          font-weight: 700;
          white-space: nowrap;
        }

        span {
          color: $black;
          font-weight: 400;
          font-size: 16px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
          height: 25px;

          @media (max-width: 1366px) {}
        }
      }

      .athleteDetails {
        display: flex;
        align-items: center;
        margin-top: 0.1rem;

        .country {
          width: 25px;
          margin-right: 0.5rem;
        }

        @media (max-width: $maxMedium) {
          margin-top: 0.5rem;
        }

        span {
          position: relative;
          color: $black;
          font-size: smaller;
          margin-right: 0.5rem;

          strong {
            font-weight: 700;
          }
        }
      }
    }
  }

  .right {
    display: flex;
    width: 73%;
    margin-right: 20px;
    justify-content: space-between;

    @media (max-width: $maxMedium) {
      align-items: center;
      justify-content: space-between;
      margin-top: 0.5rem;
      width: 100%;
      margin-left: 0;
      align-self: flex-start;
      padding: 0.5rem;
    }

    .menu {
      font-size: 12px;
      margin-right: 1.5rem;
    }

    .label {
      margin-right: 0.5rem;
      color: $dark;
      white-space: nowrap;
    }

    .buttons {
      display: flex;
      margin-right: 0.3rem;
      padding-left: 15px;
      box-shadow: none;
      width: 50px;
      height: 50px;
      border: 1px solid $lightPurple1;
      background: transparent;
      color: $lightPurple1;

      &:hover {
        color: $white;
        background: $lightPurple1;
      }

      @media (max-width: $maxMedium) {
        margin-top: 0.5rem;
        margin-right: 0.2rem;
      }

      .saveButton {
        margin-right: 1rem;
      }

      &:disabled {
        font-size: 1rem;
      }
    }

    .marketValue {
      flex-direction: column;
      margin: 10px 0;
      width: 202px;

      a {
        text-decoration: none;
      }

      .info {
        display: flex;

        h5 {
          color: $black;
          font-weight: 500;
          margin: 0 0 0.2rem;
          font-size: 12px;
          text-align: left;
        }

        .ico {
          margin-left: 13px;
          color: $lightPurple1;
        }
      }

      small {
        margin-left: 0.5rem;
        color: $dark;
        font-size: small;
      }

      .marketValueSpan {
        height: 32px;
        color: $lightPurple1;
        font-weight: 600;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          color: $black;
          font-weight: 600;
          font-size: 32px;
        }
      }
    }

    .buttonsContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 40px;
      margin-left: 50px;
      margin-top: 10px;
      gap: 15px;
      border-radius: 8px;

      @media (max-width: 1366px) {
        width: 340px;
      }

      a {
        text-decoration: none;
      }
    }

    .buttonsPerfil {
      color: $white;
      background-color: $lightPurple1;
      border-color: $lightPurple1;
      border-width: 2px;
      border-radius: 8px;
      font-size: 16px;
      width: 150px;
      height: 44px;
      display: flex;
      justify-content: center;
      gap: 10px;
      font-weight: 600;

      .icon {
        width: 22px;
        height: 22px;
        display: flex;
        align-items: flex-start;
      }
    }

    .teamsContainer {
      display: flex;
    }

    .teamContainer {
      margin-top: 10px;
      margin-bottom: 10px;

      .teamContent {
        width: 202px;

        h2 {
          margin: 0;
          padding: 0;
        }

        h5 {
          margin: 0 0 5px 0;
          font-weight: 500;
          font-size: 12px;
        }

        .teamDetails {
          display: flex;
          text-decoration: none;
          font-style: normal;
          color: $dark;
          align-items: center;

          .shield {
            height: 32px;
            width: auto;
            margin-top: 2px;
            border-radius: 0;
            align-items: baseline;
            background: transparent;

            &.withoutEmblem {
              width: 32px;
              background: $light;
              border-radius: 16px;
              align-items: center;
              font-size: 12px;
            }
          }

          .clubShortName {
            margin-top: 0.2rem;
            color: $lightPurple1;
            font-size: 0.9rem;
            font-weight: 600;
            text-decoration: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 6px;
            width: 164px;
          }

          .clubContractDate {
            font-size: 0.8rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 6px;
            width: 164px;
          }
        }

        .agencyDetails {
          display: flex;

          h5 {
            text-transform: uppercase;
          }

          img {
            margin-right: 6px;
          }

          .clubShortName {
            margin-left: 0;
          }
        }

        .withoutClub {
          margin-top: 0.2rem;
          font-size: 0.9rem;
          font-weight: 600;
          width: 164px;
        }
      }
    }
  }
}