@import 'variables.scss';

.dialogFour {
  height: 550px;

  p {
    font-size: 14px;
  }

  h5 {
    margin: 19px 0;
  }

  .athlete {
    border-top: 1px solid $lightBorder;
    border-bottom: 1px solid transparent;
    padding-left: 25px;
    background-color: $light;
  }

  .text {
    font-weight: 600;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 25px;
  }

  .cont {
    .c1 {
      display: flex;
      align-items: center;
      border-top: 1px solid $lightBorder;

      .inputContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $lightBorder;
        border-radius: 8px;
        background-color: white;
        margin-left: 10px;

        .calendarIcon {
          color: #3b4758;
          padding-right: 10px;
        }

        input {
          padding: 0 10px !important;
        }
      }

      .text1 {
        padding-left: 25px;
        font-weight: 600;
        align-items: center;
        max-width: 250px;
        min-width: 250px;
      }

      .text2 {
        padding: 0;
        font-weight: 600;
        border-top: transparent;
        color: $lightPurple1;
      }

      .inputUm {
        width: 170px;
        display: flex;
        align-items: center;
        border-top: transparent;
      }

      .inputDois {
        width: 170px;
        display: flex;
        align-items: center;
        border-top: transparent;
        margin-left: 62px;
      }
    }

    .textos {
      display: flex;
      align-items: center;
      width: auto;
      border-top: 1px solid $lightBorder;

      .text3 {
        margin-left: 25px;
        max-width: 250px;
        min-width: 250px;
      }

      .subtext {
        margin-left: 0px;
        padding: 0;
        border-top: transparent;
      }

      .subtextObs {
        display: flex;
        margin-left: 0px;
        padding: 0;
        border-top: transparent;
        height: 65px;
        width: 100%;
        overflow-y: auto;
        line-break: anywhere;
        padding: 10px 0;
      }
    }
  }
}