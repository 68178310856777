.container {
  padding: 30px;

  & .title {
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 10px;
  }

  & .subTitle {
    font-size: 16px;
  }

  & .group {
    font-size: 14px;
  }

  & .athleteCard {
    margin: 10px 0;
    box-shadow: none;
    border: 1px solid #aaa;

    &.groupWarning {
      border-left: 8px solid #f2994a;
    }

    &.groupDanger {
      border-left: 8px solid red;
    }
  }
}