.footerStyle {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: #999;
  color: #FFFFFF;

  @media screen and (max-width: 650px) {
    height: 90px;  
  }

  @media screen and (max-width: 300px) {
    height: 110px;  
  }
};

.footerSpan {
  height: 46px;
  @media screen and (max-width: 650px) {   
   height: 90px;  
  }
  @media screen and (max-width: 300px) {
    height: 110px;  
  }
}

.contentStyle {
  display: flex;
  align-items: center;
  gap: 9px;

  span {
    font-weight: 500;
    line-height: 19.2px;
    font-size: 16px;
  }
};

.iconStyle {    
  svg {
    width: 20px;
    height: 20px;  
  }    
};

.buttonStyle {  
  padding: 8px 15px;
  border: 1px solid #fff;
  border-radius: 8px;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  border-radius: '4px';

  &:active {
    background-color: #ffffff26
  }
};