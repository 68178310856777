@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(100vh - 60px);
  background-color: $white;

  .innerContainer {
    padding-top: 0px;
    background-color: $white;

    @media (max-width: $maxMedium) {
      padding-top: 220px;
    }
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 50vh;
}

.errorContainer {
  margin: 0.6rem;
  padding: 2rem;
  text-align: center;

  h2 {
    color: $dark;
    margin: 0;
  }
}

.contentCard {
  border: 1px solid $lightBorder;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0px !important;
  width: 100%;

  .body {
    height: 100%;

    @media (max-width: $maxSmall) {
      flex-wrap: wrap;
    }
  }

  .buttons {
    padding: 1rem;

    @media (max-width: $maxMedium) {
      margin-top: 0.5rem;
    }

    // .saveButton {
    //   // margin-right: 1rem;
    // }
  }
}

.noModeSelected {
  padding: 1rem;
}

.evaluationHeaderInfo {
  align-items: center !important;
  flex-direction: row;
  justify-content: center;
  display: flex;
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  text-align: start;
  gap: 5%;
}

.evaluationContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 0px 30px;
  margin-bottom: 30px;

  .matchInfo {
    display: flex;
    flex-direction: column;
    border: 1px solid $lightBorder;
    border-radius: 8px;
    min-width: 300px;
    padding: 30px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1rem 0 0 1rem;
  margin-bottom: 15px;
  margin-right: 30px;

  h1 {
    font-size: 24px;
    font-weight: 700;
    color: $darker;
  }

  h3 {
    color: $darker;
    margin: 0;
  }

  div {
    display: flex;
    align-items: flex-start;

    div {
      // margin-right: 0.5rem;
      // margin-bottom: 10px;

      span {
        font-size: small;
        color: $dark;
      }

      svg,
      img {
        margin-right: 0.25rem;
        color: $lightPurple1;
        width: 18px;
        height: 18px;
      }
    }
  }
}

h3 {
  font-size: 24px;
  font-weight: 700;
}
