@import 'variables.scss';

.toast {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  font-size: smaller;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  transform: translateY(150%);
  transition: transform 300ms ease-in-out;
  white-space: nowrap;
  padding: 10px;

  &.visible {
    transform: translateY(0%);
  }

  &.success {
    background-color: $lightPurple1;
  }

  &.warning {
    background-color: $warning;
  }

  &.danger {
    background-color: $danger;
  }

  &.info {
    background-color: $info;
  }

  &.default {
    background-color: $darker;
  }
}