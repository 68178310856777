@import 'variables.scss';

.sliderRoot {
  color: $darkPurple9 !important;

  & > span:nth-child(1) {
    height: 4px;
    background-color: $lightGrey;
  }
  & > span:nth-child(2) {
    height: 4px;
  }

  div > div {
    background-color: $lightPurple1;

    span {
      color: $lightPurple1;
    }
  }
}

.popoverContainer {
  overflow: unset !important;
  z-index: 999 !important;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 0px !important;
  box-sizing: border-box;
  border: 1px solid $lighterBorder;
  box-shadow: 0px 4px 10px $lighterBorder !important;
  border-radius: 8px;

  &.largeMenu {
    min-width: 200px;
  }
}

.sliderHeader {
  align-items: baseline;
  padding: 10px 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;

  & > span:last-child {
    margin-right: auto;
    padding: 0;
    color: $black;
  }

  & > span {
    padding: 0px 10px 0px 5px;
    vertical-align: middle;
  }

  & > span:first-child {
    padding: 5px;
    margin-top: 50px;
  }

  & button {
    padding: 2px 4px;
    font-size: 14px;
  }

  & .sliderValue {
    color: $lightPurple1;
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
    padding: 0px 3px 0px 5px;
  }
}

.sliderContainer {
  width: 15rem;

  .slider_mid_container {
    padding: 5px 15px;
  }

  .sliderFooter {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
    border-top: 1px solid $lightBorder;
    padding: 5px;

    span {
      color: $lightPurple1;
      font-weight: 600;
      text-transform: none;
    }
  }
}

.menuTitle {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid $lightBorder;
  border-right: 1px solid $lightBorder;
  background-color: $white;
  color: $black;
  font-size: 16px;
  font-weight: 600;
  width: -webkit-fill-available;
  min-width: max-content;

  svg {
    margin-left: 1rem;
    width: 20px;
    height: auto;
    color: $lightPurple1;

    &:hover {
      cursor: pointer;
    }
  }
}

.menu {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: $light;
  color: $dark;
  padding: 0.375rem 0.75rem;

  &.disabled {
    &:hover {
      cursor: unset !important;
    }
  }

  &.rounded {
    border-radius: 25px;
  }

  &:hover {
    cursor: pointer;
  }

  &.larger {
    // font-size: 12px;
    padding: 0.7rem;
    min-width: 150px;
  }

  &.darker {
    background-color: $darker;
  }

  &.warning {
    background-color: $warning;
  }

  &.danger {
    background-color: $danger;
  }

  &.info {
    background-color: $info;
  }

  span {
    margin-right: 0.5rem;
    line-height: 0;
    white-space: nowrap;
  }

  svg {
    transform: translateY(-2px);
  }
}
