@import 'variables.scss';

$bgPurple: rgba(36, 0, 102, 0.55);

.controlContainer {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    flex-direction: column;
    z-index: 1;
    display: flex;
    justify-content: space-between;

    span {
       color: $white;
       font-size: 0.8rem;    
    }
}
 
 .topContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 30px;
    
    h2 {
        margin-left: 15px;
        font-size: 1.875rem;
        color: $white;
    }

    .pageBack {
        background-color: $bgPurple;
        border-radius: 100px;        
        padding: 11px;
        line-height: 0px;
        a {
            color: $white;
        } 
    }
 }
 
 .midContainer {
    display: flex;
    justify-content: center;
    align-items: center;
 }
 
 .iconBtn {
    padding: 0px;
    color: $white;
    svg {
        margin: 0px 8px !important;
    }
 }
 
 .sliderContainer {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-left: -20px;
 }
 
 .controlBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $bgPurple;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 40px;
 }
 
 .innerControls {
    display: flex;
    padding: 10px 0;
    align-items: center;
    width: 100%;
    :first-child {
        margin-left: 20px;
    }
    :last-child {
        margin-right: 20px;
    }
 }

 .currentTime {
    width: 50px;
    text-align: center;
    color: $white;
    font-size: 0.8rem;
 }
 
 .secondControl {
  display: flex;
  align-items: center;
 }
 
 .volumeSlider {
    width: "100px";
    color: $white;
  }

  .bottomIcons {
    color: "#999";
    padding: 12px 8px;

    &:hover {
      color: "#fff";
    }
  }
