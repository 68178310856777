@import 'variables.scss';

.loaned {
  padding: 0 !important;
  height: calc(100% + 100px);

  .tableContainer {
    max-width: 100%;
    overflow-x: auto;
  }

  .targetingHeader {
    display: flex;
    text-align: left;
  }

  .loanedsTable {
    thead tr th {
      padding: 5px !important;

      div {
        font-weight: 600;
        font-size: 14px;
        justify-content: left !important;
        margin-left: 3px;
      }
    }

    tbody tr td div {
      text-align: left;
    }
  }

  .targetingRow {
    display: flex;
    align-items: center;

    .menu {
      margin-right: 0.5rem;
    }

    button {
      font-size: small;
      padding: 0.3rem;
      white-space: nowrap;
      box-shadow: none;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(50vh);
}

.nocontent {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.lowPriority {
  @extend .circle;

  background-color: $info;
}

.mediumPriority {
  @extend .circle;

  background-color: $warning;
}

.highPriority {
  @extend .circle;

  background-color: $danger;
}

.buttonContainer {
  max-width: 193px;

  div {
    height: 28px;
  }
}

.errorContainer {
  p {
    margin: 0;
  }
}

.teamContainer {
  text-align: left;

  .teamName {
  }

  .contractDate {
    color: black;
    font-weight: normal;
    font-size: 12px;
  }
}

.nationality {
  text-align: center;
}

.loanedContract {
  text-align: left;
}

.athleteImg {
  max-width: 30px;
  max-height: 30px;
}

.nationalityFlag {
  text-align: left;

  img {
    height: 20px;
    width: auto;
  }
}
