@import 'variables.scss';

.selectionBar {
  display: flex;
  height: 100%;
  align-items: center;

  label {
    color: $dark;
    font-size: medium;
    margin-right: 0.5rem;
  }

  .item {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.3rem;
    border: 1px solid $lightBorder;
    transition: all 300ms;

    &.disabled {
      &:hover {
        cursor: unset !important;
      }
    }

    span {
      margin-right: 0.3rem;
    }

    &:hover {
      cursor: pointer;
    }

    &.between {
      border-left: none;
    }

    &.selected {
      color: $light;
      &.warning {
        background-color: $warning;
      }

      &.danger {
        background-color: $danger;
      }

      &.info {
        background-color: $info;
      }
    }

    .circle {
      width: 10px;
      height: 10px;
      margin-right: 0.3rem;
      border-radius: 100%;
      border: 1px solid $light;

      &.warning {
        background-color: $warning;
      }

      &.danger {
        background-color: $danger;
      }

      &.info {
        background-color: $info;
      }
    }
  }
}