@import 'variables.scss';

.matchItem {
    height: 150px;
    border: solid 1px $lighterBorder;
    border-radius: 5px;
    background-color: #ffffff;

    .matchItemInfo {
        align-content: space-evenly;
        display: grid;
        height: 118px;
        border-radius: 5px;
    }

    .title {
        margin-left: 10px;
        font-size: 12px;
    }

    a {
        color: $darkPurple9;
        font-weight: 500;
        text-decoration: none;
    }

    .avatar {
        margin-left: 10px;
        margin-right: 15px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img,
        div {
            height: 30px;
            width: 30px;
        }

        a {
            font-size: 14px;
            margin-left: 10px;
            margin-right: auto;
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
        }

        span.score {
            font-size: 18px;
            font-weight: 900;
            float: right;
        }
    }

    .bottomLinks {
        border-top: solid 1px $lighterBorder;
        background-color: $light;
        padding-left: 10px;
        height: 30px;
        display: flex;
        align-items: center;
        color: $darkPurple9;
        font-size: 12px;

        a {
            margin-left: 5px;
            margin-right: 15px;
        }
    }
}