@import 'variables.scss';

.bgPurple {
  background-color: $lightPurple1 !important;
}

.bgGreen {
  background-color: $green3 !important; 
}

.bgOrange {
  background-color: $orange2 !important;
}

.bgBrown {
  background-color: #755632 !important;
}

.card {
    background-color: #fff;
    border-radius: 8px;
    max-width: 440px;
    width: 100%;
    height: 510px;
    padding: 15px 0;
    border: 1px solid $lightBorder;
    overflow: hidden;
  
    h3 {
      line-height: 21.6px;
      font-size: 18px;
      margin: 0px;
      font-weight: 600;
      color: $black;
      padding: 0px 15px;
    }
  
    .info {
      display: flex;
      align-items: center;
      background-color: #f0f0f0;
      padding: 10px;      
      border-radius: 8px;
      margin: 15px;      
      margin-bottom: 20px;
      height: 55px;
  
      .icon {
        display: flex;
        height: 40px;
        width: 40px;
        font-size: 2rem;
        margin-right: 12px;
        background-color: $gray;
        border-radius: 4px;
        text-align: center;
        justify-content: center;
        padding: 5px;

        img {
          height: 30px;
          align-self: center;
        }
      }      
  
      .stats {
        display: flex;
        flex-direction: column;
        .number {
          font-size: 32px;          
          font-weight: 400;
          line-height: 38.4px;
        }
  
        .subtitle {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          color: $black;
        }
      }
    }
  
    .tabs {
      display: flex;      
      margin-bottom: 16px;
      border-bottom: 1px solid transparent;
      border-bottom-color: $lightBorder;
      overflow-x: auto;
      overflow-y: hidden;
  
      .tab {
        padding: 8px 16px;
        cursor: pointer;        
      }

      .activeTab {
        padding: 8px 16px;
        cursor: pointer;
        border-bottom: 3px solid transparent;
        border-bottom-color: $lightPurple1;
        margin-bottom: -1px;
      }
    }
    
    .tabs::-webkit-scrollbar {
      width: 8px;
    }

    .tabs::-webkit-scrollbar-track {
      background: transparent;
    }

    .tabs::-webkit-scrollbar-thumb {
      background-color: $lightGrey2;
      border-radius: 10px;
      border: 2px solid transparent;
      background-clip: content-box;
    }
  
    .tabContent {
      display: flex;
      flex-direction: column;
      max-height: 340px;
      overflow-y: auto;
      padding: 0px 15px;
      gap: 15px;      

      .barContent {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      
      .progressBarContainer{
        display: flex;
        flex-direction: row;

        .bgGrey {
          background-color: $lightGrey2;
        }

        .progressBar {          
          border-radius: 4px;
          width: 100%;
    
          .progress {
            background-color: $lightPurple1;
            height: 19px;
            border-radius: 4px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: bold;
          }
    
          .label {
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            color: $black;
            height: 17px;
          }                  
        }

        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          float: right;
          text-align: right;
          width: 58px;
        }
      }
    }

    .tabContent > div:last-child {      
      margin-bottom: 15px;      
    }

    /* Estilo para navegadores WebKit (Chrome, Safari) */
    .tabContent::-webkit-scrollbar {
      width: 8px; /* Largura da barra de rolagem */
    }

    .tabContent::-webkit-scrollbar-track {
      background: transparent; /* Cor de fundo do trilho da barra */
    }

    .tabContent::-webkit-scrollbar-thumb {
      background-color: $lightGrey2; /* Tom de cinza translúcido */
      border-radius: 10px; /* Bordas arredondadas */
      border: 2px solid transparent; /* Espaço entre a barra e o conteúdo */
      background-clip: content-box; /* Ajusta o espaço ao redor da barra */
    }
  }

.select {
  width: calc(100% - 30px);
  max-width: 113px;  
  height: 17px;
  padding: 10px 20px;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $lightBorder;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  span {
    width: 100%;
    height: 17px;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.switchContainer {
  display: flex;

  .switch {    
    border: 1px solid $lightPurple1;
    border-radius: 100px;
    width: 42px;
  }

  span {
    align-self: center;
    margin-left: 10px;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 400;
  }
}

.loader {
  display: flex;
  margin: 0 auto;
  height: 400px;
  div {
    display: flex;
    svg {
      align-self: center;
    }
  }
}