@import 'variables.scss';

.container {
  display: flex;
  align-items: center;
  font-size: small;
  color: $darker;
  //---letter-spacing: -0.5px;
  user-select: none;

  .checkbox {
    width: 15px;
    height: 15px;
    margin-right: 0.5rem;
    background-color: $light;
    border: 1px solid $lightBorder;
    border-radius: 3.5px;
    transition: all 300ms;

    &.small {
      transform: scale(0.8);
    }

    &.dark {
      background-color: #fff;
      border-color: $dark;
    }

    &.checked {
      background-color: $lightPurple1;
      position: relative;
      border-color: $lightPurple1;

      &:after {
        content: '';
        position: absolute;
        left: 4.5px;
        top: 2px;
        width: 2.5px;
        height: 6px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }

  input {
    display: none;
  }
}
