@import 'variables.scss';

.title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $lighterBorder;
    align-items: center;

    h3 {
        margin: 0.8rem;
        color: $darker;
    }

    span {
        color: $darkPurple9;
        transition: text-shadow 300ms;
        margin-right: 0.5rem;

        &:hover {
            cursor: pointer;
        }
    }
}

.resultItem {
    display: flex;
    padding: 0.8rem;
    border-bottom: 1px solid $lighterBorder;

    &:hover {
        cursor: pointer;
    }

    .generalInfo {
        display: flex;
        flex-direction: column;
        margin: 0 1rem;
        justify-content: flex-end;

        h5 {
            margin: 0;
            font-weight: 500;
            color: $darker;
        }

        span {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
            //---letter-spacing: -0.4px;

            @media (max-width: $maxSmall) {
                font-size: 10px;
            }
        }

        // .rateDiv {
        //   display: flex;
        //   align-items: center;

        //   span {
        //     font-size: 12x;
        //     color: $dark;
        //     font-weight: 500;
        //     margin-left: 0.2rem;

        //     @media (max-width: $maxSmall) {
        //       font-size: 10px;
        //     }
        //   }
        // }
    }

    .rightInfo {
        display: flex;
        align-items: flex-end;
        margin-left: auto;

        >*:not(:first-child) {
            margin-left: 0.5rem;
        }

        span {
            font-size: 12px;
            font-weight: 500;
            color: $dark;
            //---letter-spacing: -0.9px;

            &.flags {
                display: flex;
                flex-direction: column;

                img:not(:first-of-type) {
                    margin-top: 2px;
                }
            }

            @media (max-width: $maxSmall) {
                font-size: 10px;
            }
        }
    }
}