@import 'variables.scss';

.checkboxDiv,
.dataDiv {
    h4 {
        font-weight: 500;
        margin: 0;
    }
}

.checkboxDiv {
    ul {
        padding-left: 0;

        .checkboxItem {
            display: flex;
            list-style-type: none;
            margin-bottom: 8px;

            label {
                font-size: 1em;
            }
        }
    }
}

.dataDiv {
    h5 {
        font-size: 1em;
        margin-bottom: 5px;
    }
}

.generateReportDiv {
    text-align: center;
    padding: 10px;

    h4 {
        margin-top: 0;
    }

    span {
        display: block;
        font-size: .8em;
        margin-bottom: 15px;
    }

    .reportButton {
        display: block;
        margin: 0 auto;
        width: 300px;

        &:first-of-type {
            margin-bottom: 10px;
        }
    }
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-height: 25vh;
}