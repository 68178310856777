@import 'variables.scss';

.seasonContainer {
  .seasonMenu {
    background-color: #ffffff;
    margin-right: 10px;
    border: 1px solid $lightBorder;
    height: 15px;
    width: 50px;
    font-size: 0.8rem;
    border-radius: 8px;
    padding: 10px 20px 10px 10px;
  }
}

.customMenu {
  background-color: #ffffff;
  margin-right: 2px;
  // width: 60px;
}

.menuTitle {
  float: left;
  height: 20px;
  padding-left: 10%;
  font-size: 0.8rem;
  line-height: 1.5;
}

.placeholderTitle {
  color: black;
  border-left: 1px solid black;
}

.customMenuLabel {
  margin-right: 5px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  flex-direction: row;
}

.errorMessage {
  color: $red;
  font-size: 1rem;
  text-align: center;
  padding-top: .3125rem;
  min-height: 1.1875rem;
}