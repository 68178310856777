@import 'variables.scss';

.paymentRequiredContainer *:hover {
  .paymentRequired {
    height: 16px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.7rem 1rem;

  .iconColor {
    box-shadow: none;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    img {
      position: absolute;
      margin-bottom: 10px;
    }
  }

  .iconPurple {
    background-color: $lightPurple1;
  }

  .iconPink {
    background-color: $pink1;
  }

  .iconGreen {
    background-color: #6fcf97;
  }

  .iconBlue {
    background-color: #56ccf2;
  }

  .iconBrown {
    background-color: #755632;
  }

  .iconYellow {
    background-color: #f2994a;
  }

  .iconYellow2 {
    background-color: #f2c94c;
  }

  .iconRed {
    background-color: #eb5757;
  }

  .iconGray {
    background-color: #62747c;
  }

  .paymentRequiredBackground {
    background-color: $gray5;
  }

  .paymentRequired {
    background-color: $lightPurple1;
    font-size: 10px;
    font-weight: 600;
    border-radius: 0 0 8px 8px;
    transition: height 300ms;
    height: 0;
    width: 50px;
    text-align: center;
    position: relative;
    color: $white;
  }

  .sectionTitle {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 15px;

    span {
      margin-left: 1rem;
      color: black;
      font-weight: 600;
      transition: color 300ms;
      font-size: 16px;
      line-height: 19px;

      &:hover {
        cursor: pointer;
        color: $lightPurple1;
      }
    }
  }

  ul {
    list-style: none;
    color: $lightPurple1;
    padding: 0;
    margin: 0;

    li {
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      margin: 0rem 3rem;
      transition: color 300ms;

      &:hover {
        cursor: pointer;
        color: $lightPurple1;
      }
    }
  }
}
