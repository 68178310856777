@import 'variables.scss';

.slider {
    margin: 20px 0;

    .values {
        display: flex;
        height: 30px;
        position: relative;

        div {
            position: absolute;
            width: 20px;
            text-align: center;

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: end;
                right: -3px;
            }
        }
    }
}