.clickAwayListener {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: transparent;
  z-index: 100;

  &:hover {
    cursor: default;
  }
}