@import 'variables.scss';

.overlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $lighterGrey;
  position: absolute;
  opacity: 0.6;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(50vh);
}

.contractDate {
  display: flex;
  align-items: center;
  margin-top: 5px;

  .calendar_before {
    cursor: pointer;
    background-color: #ffffff;
    margin-right: 10px;
    border: 1px solid #bdbdbd;
    height: 18px;
    width: 90px;
    font-size: 0.8rem;
    line-height: 1.5;
    border-radius: 8px;
    padding: 10px 15px 10px 15px;
    margin-left: 10px;
  }

  .calendar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $lightBorder;
    border-radius: 8px;
    background-color: white;
    margin-left: 10px;

    input {
      padding: 4px;
    }

    .calendarIcon {
      color: #3b4758;
      padding-right: 10px;
    }
  }
}

.menuMinMaxContainer {
  display: flex;
  align-items: center;
}

.collapseWithMessage{
  margin-bottom: 10px;
}

.minMaxDate {
  display: flex;
}

.minMaxDateChildren {
  display: inline-block;
  border: 1px solid $lightBorder;
  margin-right: 10px;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  width: 120px;
  text-align: center;
  padding: 5px 0;
}

.inputNumCBF {
  width: 245px;
}

.largeSelect {
  background-color: #ffffff;
  border: 1px solid $lightBorder;
  border-radius: 8px;
  width: calc(100% - 50px);
  padding: 10px 15px 10px 15px;
  font-size: 0.8rem;
  line-height: 1.5;
}

.filterInnerContainer {
  display: flex;
  width: 130%;

  .svgContainer {
    display: flex;

    svg {
      position: relative;
      right: 25px;
      top: 8px;
    }
  }

  .filterInput {
    transition: border-color 300ms, background-color 300ms, box-shadow 200ms;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $lightBorder;
    height: 30px;
    min-width: 65%;
    padding-left: 10px;

    &:focus {
      outline: none;
      border-color: $lightPurple1;
    }
  }

  .container {
    border-right: 1px solid $lightBorder;
  }
}

.filterInnerContainer2 {
  display: flex;

  .applyButton {
    position: relative;
    right: 10px;
  }

  .svgContainer2 {
    display: flex;

    svg {
      position: relative;
      right: 25px;
      top: 8px;
    }
  }

  .filterInput {
    transition: border-color 300ms, background-color 300ms, box-shadow 200ms;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $lightBorder;
    height: 30px;
    min-width: 65%;
    padding-left: 10px;

    &:focus {
      outline: none;
      border-color: $lightPurple1;
    }
  }
}

.clearButton {
  margin-top: 5px;
}