.container {
  width: 100%;

  .tooltip {
  }

  .geography {
    outline: none !important;
  }
}
