@import 'variables.scss';

.athleteList {
  padding: 0 !important;
  height: calc(100% + 100px);

  .tableContainer {
    padding: 25px;
    max-width: 100%;
    overflow-x: auto;

    .link {
      color: $dark;
      text-decoration: none;
    }
  }

  .targetingHeader {
    display: flex;
    text-align: left;
  }

  thead tr th {
    &:nth-child(3) {
      padding-left: 0 !important;
    }
    &:nth-child(n + 3):nth-child(-n + 12) {
      div {
        justify-content: center;
        padding-left: 0;
      }
    }

    &:nth-child(n + 1):nth-child(-n + 4) div {
      justify-content: left;
      padding-left: 10px !important;
      font-weight: 600;
    }
  }

  tbody tr {
    &:last-child {
      td {
        &:first-child {
          border-radius: 0 0 0 8px;
        }

        &:last-child {
          border-radius: 0 0 8px 0;
        }
      }
    }

    td {
      &:nth-child(n + 3):nth-child(-n + 12) {
        div {
          text-align: center;
          padding-left: 0;
        }
      }

      &:nth-child(3) div {
        padding-left: 0;
      }

      &:nth-child(4) div {
        text-align: right;
      }

      div {
        text-align: left;
        padding-left: 10px;
        font-weight: 400;
        font-size: 14px;
        color: $black;

        div {
          padding-left: 0;
        }
      }
    }
  }

  .checkbox {
    color: $light;
    transform: translateY(-1px);
  }

  .targetingRow {
    display: flex;
    align-items: center;

    .menu {
      margin-right: 0.5rem;
    }

    button {
      font-size: small;
      padding: 0.3rem;
      white-space: nowrap;
      box-shadow: none;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(50vh);
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.lowPriority {
  @extend .circle;

  background-color: $info;
}

.mediumPriority {
  @extend .circle;

  background-color: $warning;
}

.highPriority {
  @extend .circle;

  background-color: $danger;
}

.errorContainer {
  p {
    margin: 0;
  }
}

.actionBar {
  padding: 0;
}

.buttonContainer {
  max-width: 193px;

  div {
    border-radius: 4px !important;
    height: 28px;
  }
}
