@import 'variables.scss';

.label {
    display: flex;
    flex-direction: column;
    color: $dark;
    font-weight: 500;
    font-size: 14px;
}

.textArea {
    box-sizing: border-box;
    margin-top: 0.5rem;
    padding: 0.6rem;
    width: 350px;
    border: 1px solid $lightBorder;
    background-color: $light;
    outline-color: $lightPurple1;
    font-family: inherit;

    &.fluid {
        width: 100%;
    }
}