@import 'variables.scss';

.container {
  display: flex;
  flex-direction: row;

  .right {
    flex-grow: 1;
    padding: 20px 20px 0 20px;
    background-color: #fff;
    position: fixed;
    top: 206px;
    left: 315px;
    right: 0;
    bottom: 60px;
    overflow-y: scroll;

    .athleteList {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .excededPlanLimit {
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        margin-top: 30px;

        @media (min-height: 800px) {
          top: calc((100vh - 600px) / 2);
        }
      }

      a {
        text-decoration: none;
      }

      .moreInfo {
        background-color: $white;
        border: 1px solid $lightPurple1;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $lightPurple1;
        font-weight: 600;
        padding: 5px 14px;
        width: 150px;
      }

      .athletesTable {
        thead tr th {
          background-color: $white;
          padding: 15px 10px;
          border-top: 1px solid $lightBorder;
          border-bottom: 1px solid $lightBorder;
          border-right: none;
          border-left: none;

          &:first-child {
            border-left: 1px solid $lightBorder;
          }

          &:last-child {
            border-right: 1px solid $lightBorder;
          }

          div {
            justify-content: left;
            color: $black;
            font-weight: 600;
          }
        }

        tbody tr td {
          &:last-child {
            width: 0;
          }

          div {
            text-align: left;
          }
        }
      }
    }
  }
}

.filter {
  position: fixed;
  bottom: 0;
  left: 0;
  top: 206px;
  width: 315px;
}

.activeFilters {
  margin: 10px 0;
}

.loader {
  position: absolute;
  top: 50%;
  right: 50%;
}
