@import 'variables.scss';

.actionBar {
  display: flex;
  padding: 10px 0;
  align-items: center;
  flex-wrap: wrap;
  background: $white;

  & > * {
    margin-top: 0.5rem;
  }

  .actionButtons {
    display: flex;
    gap: 10px;
    margin-right: 10px;

    .iconButton {
      font-weight: 700;
      font-size: 14px;
      color: $white;
      width: 149px;
      height: 35px;
      padding: 10px 10px 10px 15px;
      border-radius: 8px;
      background-color: $lightPurple1 !important;
      display: flex;

      span {
        font-weight: 700;
        font-size: 14px;
      }

      svg {
        width: 15px;
        margin-left: 10px;
      }
    }

    .deleteButton {
      font-weight: 700;
      font-size: 14px;
      color: $white;
      width: 100px;
      height: 35px;
      padding: 10px 10px 10px 15px;
      border-radius: 8px;
      background-color: $lightPurple1 !important;
      display: flex;

      span {
        font-weight: 700;
        font-size: 14px;
      }

      svg {
        width: 15px;
        margin-left: 10px;
      }
    }
  }

  .inputContainer {
    max-width: 200px;
    margin-right: 1rem;

    input {
      background-color: #fff;
      line-height: 1;
    }
  }

  .icon {
    top: 25% !important;
  }

  .menu {
    margin-right: 0.5rem;
    background-color: $white;
    border: 1px solid $lightBorder;
    border-radius: 8px !important;
    font-size: 12px;
    font-weight: 500;
    padding: 0.65rem 0.95rem;
  }
}

.checkbox {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}
