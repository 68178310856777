@import 'variables.scss';

.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid $lightBorder;
  box-sizing: border-box;
  height: 100%;

  .header {
    display: flex;
    box-sizing: border-box;
    height: 30px;


    .headerItem {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      box-sizing: border-box;
      padding: 0.25rem 0.5rem;
      font-size: small;
      white-space: nowrap;
      border-bottom: 1px solid $lightBorder;
      border-right: 1px solid $lightBorder;
      transition: 300ms;

      &.active {
        background-color: $lightPurple1;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    padding: 1rem;
    transition: 350ms;

    &.fadeIn {
      animation-name: fadeInAnim;
      animation-duration: 0.35s;
    }

    &.fadeOut {
      opacity: 0;
    }
  }
}

@keyframes fadeInAnim {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}