@import 'variables.scss';

.container {
  display: flex;
  margin: 0 auto;
  padding: 15px 0 15px 0;
  height: calc(100vh - 90px);
  max-width: 1720px;

  @media (max-width: 1720px) {
    margin-left: 15px;
    margin-right: 15px;
  }

  @media (max-width: 960px) {
    flex-direction: column;    
  }
}

.spinner {
  position: relative;
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  top: 40px;
}
