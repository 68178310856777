@import 'variables.scss';

.container {
    width: calc(100% - 15px);
    min-width: 600px;
    max-width: 1624px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid $lightBorder;
    border-radius:  8px;
    background-color: $lightPurple4;
    margin: auto;
    margin-top: 10px;

    .menuMode{
        width: calc(100% - 30px);
        font-size: 14px;
        line-height: 16.8px;
        font-weight: 600;
        display: flex;
        flex-direction: row;            
        align-items: left;        
        margin: 15px;
        margin-top: 5px;

        span {
            margin-right: 10px;
            padding: 15px 0px;
        }

        .mode {
            color: $lightPurple1;
            font-size: 14px;
            font-weight: 600;
            line-height: 16.8px;
            padding: 15px;            
            cursor: pointer;
            white-space: nowrap;                
        }

        .selectedMode {
            border-bottom: 2px solid $lightPurple1;
        }

        .modeList {
            overflow-x: auto;
            display: flex;
            flex-direction: row;
        }
    }

    .cards {
        display: flex;
        flex-direction: row;
        @media (max-width: 1150px) {
            flex-direction: column;
        }
    }

    .cards-sm {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));;
        @media (max-width: 600px) {
            flex-direction: column;
        }
    }

    .card-md {
        background-color: $white;
        border: 1px solid $lightBorder;
        border-radius: 8px;
        padding: 15px;
        margin: 0px 10px 10px 10px;
        width: 50%;
        min-width: 500px;
        
        @media (max-width: 1150px) {
            width: calc(100% - 50px);
        }

        h3 {
            font-weight: 600;
            font-size: 18px;
            line-height: 21.6px;
            text-align: center;

        }        
    }

    .card-sm {
        background-color: $white;
        border: 1px solid $lightBorder;
        border-radius: 8px;
        padding: 15px;
        margin: 0px 10px 10px 10px;
        display: flex;
        flex-direction: column;

        h3 {
            font-weight: 600;
            font-size: 18px;
            line-height: 21.6px;
            text-align: center;

        }
    }

    .averageAspect {
        display: flex;
        align-items: center;
        justify-content: center; 
        span {
            margin-left: 10px;
        }
    }

    .aspecto {
        ul {
            list-style-type: decimal;
        }

        .stars {
            display: flex;            
            span {
                margin-left: 10px;
            }
        }
    }

    .visaoGeral {              
        display: flex; 
        flex-direction: row;
        .section {
            width: 50%;
            align-self: center;
            h3 {
                text-align: left;
            }

            .average{
                display: flex;
                flex-direction: row;                
                align-items: center;                
                span {
                    font-size: 18px;
                    line-height: 21.6px;
                    margin-left: 10px;
                }
            }
        }
        table {
            .stars {
                display: flex;
                flex-direction: row;
                margin-left: 15px;
                align-items: center;
                span {
                    margin-left: 10px;
                }
            }
        }

        ul {
            list-style-type: none;
            padding-inline-start: 0px;
            li {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .stars {
                    display: flex;
                    flex-direction: row;
                    margin-left: auto;
                    align-items: center;
                    span {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}