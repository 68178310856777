@import 'variables.scss';

.container {
  height: auto;
  min-height: 100%;
  margin: 0 1rem;
  h3 {
    margin-bottom: 0px !important;
  }
}

.matches {
  height: 150px;
  margin-bottom: 30px;
  width: 100%;
}

.score {
  display: flex;
  justify-content: space-between;
}

.footer {
  border: 2px solid grey;
  border-radius: 5px;
  margin-bottom: 50px;
}

.content {
  box-sizing: border-box;
  margin: 0.6rem 0.6rem 0;
  margin-top: 0;
  padding-top: 65px;

  @media (min-width: $minVeryLarge) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: $maxMedium) {
    padding-top: 150px;
  }

  .tableContainer {
    max-width: 100%;
    overflow-x: auto;
  }

  .mediaIdade {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    border: 1px solid #bdbdbd;
    padding: 10px;
    width: auto;
    gap: 10px;
    display: flex;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;

    svg {
      height: 24px;
      width: 24px;
    }
  }
}

.subscribed_table {
  thead tr th {
    &:first-child {
      div {
        justify-content: left !important;
      }
    }
    &:nth-child(2) {
      div {
        justify-content: left !important;
      }
    }
    &:nth-child(3) {
      div {
        justify-content: left !important;
      }
    }
    &:nth-child(n + 3):nth-child(-n + 12) {
      div {
        justify-content: center;
        padding-left: 0;
      }
    }
    &:nth-child(n + 1):nth-child(-n + 4) div {
      justify-content: left;
      margin-left: 5px !important;
      font-weight: 600;
    }
  }

  tbody tr {
    &:last-child {
      td {
        &:first-child {
          border-radius: 0 0 0 8px;
        }

        &:last-child {
          border-radius: 0 0 8px 0;
        }
      }
    }

    td {
      &:nth-child(n + 3):nth-child(-n + 12) {
        div {
          text-align: center;
          padding-left: 0;
        }
      }

      &:nth-child(3) div {
        padding-left: 0;
      }

      &:nth-child(4) div {
        text-align: right;
      }

      div {
        text-align: left;
        padding-left: 10px;
        font-weight: 400;
        font-size: 14px;
        color: $black;

        div {
          padding-left: 0;
        }
      }
    }
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 50vh;
}

.errorContainer {
  margin: 0.6rem;
  padding: 2rem;
  text-align: center;

  h2 {
    color: $dark;
    margin: 0;
  }
}

.buttonContainer {
  max-width: 193px;

  div {
    height: 28px;
  }
}
