@import 'variables.scss';

.performance {
  display: flex;
  flex-direction: column;
  margin: 30px 30px 0 0;

  .interestedVisualizedContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .progressBarContainer {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
  }

  .progressBar {
    height: 100%;
    background-color: $lightPurple1;
    transition: width 0.3s ease-in-out;
  }

  h3 {
    color: $black;
    margin: 10px 0;
  }

  .withImage {
    display: flex;
    align-items: center;

    img {
      width: 25px;
      height: auto;
      margin-right: 0.3rem;
    }
  }

  .link {
    cursor: pointer;
    color: $lightPurple1;
    font-size: 16px;
    //---letter-spacing: -1px;
    font-weight: 700;
    transition: color 300ms;

    &:hover {
      color: $lightPurple1;
    }
  }

  .competition {
    border: 1px solid $lightBorder;
    border-radius: 8px;

    thead {
      tr th {
        font-weight: 600;
        font-size: 14px;
        border-bottom: 1px solid $lightBorder;
        background-color: $white !important;
        color: $black !important;

        &:nth-child(n+3):nth-child(-n+12) div {
            justify-content: center;
            margin-left: 5px;
        }

        &:nth-child(n+1):nth-child(-n+3) div {
            justify-content: left;
            padding: 0 7px !important;
        }
      }
    }

    tbody {
      tr td:first-child {
        border-left: none !important;
      }

      tr td {
        padding: 0 10px;
        font-size: 14px;

        div {
          text-align: left;
        }
        
        &:nth-child(n+3):nth-child(-n+12) div {
          text-align: center;
        }
      }

      tr td:last-child {
        border-right: none !important;
      }
    }

    tfoot {
      border: none !important;

      tr th {
        background-color: $white;
        border: none;
        padding: 1rem !important;

        a {
          color: $lightPurple1;
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
  }
}
