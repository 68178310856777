@import 'variables.scss';

.validationMsg {
    margin-bottom: 20px;
    width: auto;
    position: inherit;
}

.dialogContainer {
    width: 740px;
}

.sliderRoot {
    color: $red !important;
    opacity: 1 !important;
}

.dialogContent {
    margin: 0 6px;
    font-size: 14px;

    .dotRisk {
        display: flex;
        margin: 8px 0;

        .dotDanger,
        .dotWarning,
        .dotOk {
            width: 15px;
            height: 15px;
            border-radius: 50%;
        }

        .dotDanger {
            background-color: $red;
        }

        .dotWarning {
            background-color: $orange2;
        }

        .dotOk {
            background-color: $green2;
        }

        .riskTitle {
            font-weight: 600;
            width: 86px;
            margin-left: 8px;
        }
    }
}

.content {
    display: flex;

    .athletePhotoDiv {
        margin-right: 30px;
        text-align: center;

        .athletePhoto {
            border-radius: 50%;
            margin-bottom: 10px;
            background-color: #fff;
            background-repeat: no-repeat;
            background-size: cover;
            height: 150px;
            min-height: 150px;
            min-width: 150px;
            -webkit-print-color-adjust: exact;
            align-self: center;
            border: 1px solid $lightBorder;
        }
    }

    .athleteInfo {
        width: 100%;

        .formGroup {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            label {
                min-width: 130px;
                text-align: right;
            }

            span {
                margin-left: 15px;
            }
        }
    }
}

.largeSelect {
    border: 1px solid $lightBorder;
    border-radius: 20px;
    padding: 0.55rem !important;
    width: 100%;
}

.loader {
    display: flex;
    justify-content: center;
}