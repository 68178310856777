@import 'variables.scss';

.container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;

  .leftContainer {
    background-color: $lightPurple4;
    display: flex;
    width: 651px;

    .footlinkLogo {
      position: fixed;
      top: 20px;
      left: 30px;

      & > div {
        margin: 0;
      }
    }

    .athleteImg {
      display: flex;
      margin: 0 auto;
      align-items: center;

      img {
        width: 524px;
        height: 564px;
        mix-blend-mode: hard-light;
      }
    }

    .videoSixPage {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      flex-direction: column;

      .videoContainer {
        background-color: #120033;
        margin-top: 5px;
        width: 500px;
        height: 271px;

        img {
          cursor: pointer;
          border: solid 1px $lightPurple3;
          
          &:hover {
            border: solid 1px $lighterBorder;
          }
        }
      }
    }
  }

  .rigthContainer {
    display: flex;

    .languageButton {
      position: fixed;
      top: 30px;

      .containerLanguageButton button {
        color: #6b1aff;
      }

      & > div {
        margin: 0;
      }
    }

    .btnLogin {
      display: flex;
      align-items: center;
      gap: 20px;
      position: fixed;
      top: 30px;
      right: 30px;
    }

    .contentContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      .cardContainer {
        display: flex;
        width: 781px;
        border-radius: 8px;
        overflow: hidden;
        padding: 20px 30px;
        margin-left: 60px;

        .firstPageContainer {
          display: flex;
          min-width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          transition: all 350ms;
          transform: translateX(-150%);

          .title {
            font-size: 42px;

            span {
              color: $lightPurple1;
            }
          }

          .subTitle {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .tokenError {
              margin-top: 3px;
              font-size: 13px;
              color: $red;
            }

            .text1 {
              font-size: 24px;
              font-weight: 400;
            }
            .text2 {
              font-size: 16px;
            }
            .text3 {
              font-weight: 700;
            }
          }

          .buttonContainer {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            margin-top: 10px;

            .btn {
              width: 30px;
              height: 20px;
            }
          }

          &.active {
            transform: translateX(0%);
          }
        }

        .secondPageContainer {
          display: flex;
          min-width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          transform: translateX(150%);
          transition: all 350ms;
          gap: 20px;

          .title {
            font-size: 42px;

            span {
              color: $lightPurple1;
            }
          }

          .content {
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 20px;

            .passwordLabel {
              margin-bottom: 5px;
            }
          }
          
          .passwordError {
            margin-top: 3px;
            font-size: 13px;
            color: $red;
          }

          &.active {
            transform: translateX(-100%);
          }
        }

        .thirdPageContainer {
          display: flex;
          min-width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          transform: translateX(150%);
          transition: all 350ms;
          gap: 20px;

          .title {
            font-size: 42px;

            span {
              color: $lightPurple1;
            }
          }

          .content {
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 15px;

            label {
              line-height: 2;
            }

            button {
              margin-bottom: 5px;
            }
          }

          &.active {
            transform: translateX(-200%);
          }
        }

        .fourthPageContainer {
          display: flex;
          min-width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          transform: translateX(150%);
          transition: all 350ms;

          .title {
            font-size: 42px;

            span {
              color: $lightPurple1;
            }
          }

          .content {
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 20px;

            .teamContainer {
              display: flex;
              align-items: center;
              justify-content: space-between;
              box-sizing: border-box;
              width: 409px;
              padding: 1rem;
              background-color: #fff;
              overflow: hidden;
              border: 1px solid #6b1aff;
              margin: 10px 0 10px 0;
              border-radius: 10px;

              @media (max-width: $maxMedium) {
                flex-direction: column;
                height: 150px;
              }

              .conteudo {
                display: flex;
                align-items: center;
                margin-right: auto;

                .club_avatar {
                  width: 80px;
                  height: 80px;
                }

                .separator {
                  margin: 0 1rem;
                  color: $lightPurple1;
                  font-weight: 500;
                  font-size: x-large;

                  @media (max-width: $maxMedium) {
                    display: none;
                  }
                }

                .clubInfo {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  margin-left: 0.7rem;
                  //---letter-spacing: -1px;

                  .address {
                    margin-top: 10px;

                    span {
                      font-weight: 400;
                      font-size: 14px;
                    }

                    .country_flag {
                      margin-right: 10px;
                      border-radius: 3px;
                    }
                  }

                  h1 {
                    margin: 0;
                    font-size: 26px;
                    color: $black;
                    font-weight: 700;
                    letter-spacing: -1.2px;
                  }

                  h2 {
                    margin: 0;
                    font-size: x-large;
                    color: $dark;
                    font-weight: 500;
                  }

                  img {
                    box-shadow: 1px 1px 3px $lighterBorder;
                    max-width: 1.4em;
                  }

                  div {
                    display: flex;
                    align-items: center;

                    span {
                      color: $dark;
                      font-size: smaller;
                      margin-right: 0.5rem;
                      font-weight: 400;
                      white-space: nowrap;

                      strong {
                        font-weight: 500;
                      }
                    }
                  }
                }
              }
            }

            .contactUs {
              font-weight: 500;

              span {
                color: $lightPurple1;
                cursor: pointer;
              }
            }
          }

          &.active {
            transform: translateX(-300%);
          }
        }

        .fifthPageContainer {
          display: flex;
          min-width: 100%;
          height: 740px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          transform: translateX(150%);
          transition: all 350ms;

          .title {
            font-size: 42px;

            span {
              color: $lightPurple1;
            }
          }

          &.active {
            transform: translateX(-400%);
          }

          .createProfile {
            margin-top: 10px;
          }
        }

        .sixthPageContainer {
          display: flex;
          min-width: 100%;
          gap: 20px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          transform: translateX(150%);
          transition: all 350ms;

          .title {
            font-size: 42px;

            span {
              color: $lightPurple1;
            }
          }

          .sixPageSubTitle {
            font-size: 24px;
          }

          .sixPageText {
            font-size: 18px;
          }

          .content {
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 20px;
          }

          &.active {
            transform: translateX(-500%);
          }
        }
      }
    }
  }
}
