.withImg {
  display: flex;  
  align-items: center;

  img {
    margin-right: 0.25rem;
    max-width: 2em;
    max-width: 30px;

    :last-of-type {
      margin-right: 0;
    }
  }
}
