@import 'variables.scss';

.container {
  @media print {
    page-break-after: avoid;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 5px;

  .darkPurple9 {
    color: $darkPurple9 !important;
  }

  .rightItems {
    padding-left: 5px;

    .brandLogo {
      width: 150px;
    }
  }

  .centerItems {
    .brandTag {
      border-radius: 20px;
      padding: 8px 19px;
      border: 1px solid $lightBorder;

      span {
        color: $dark;
        font-style: italic;
        font-size: 14px;
      }
    }
  }

  .leftItems {
    display: flex;
    color: $dark;
    text-align: left;
    gap: 30px;

    h1 {
      font-size: 0.9em;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 5px;
    }

    span {
      display: block;
      margin-bottom: 5px;
      font-size: small;
      font-weight: 500;
    }

    .brandLogo {
      width: 75px;
    }

    .relatDetails {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
