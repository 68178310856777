@import 'variables.scss';

.carousel_container {
  width: 380px;
  transition: height 0.5s;
  text-align: center;

  .image {
    height: 200px;
    width: 100%;
  }

  .agency_general_no_image {
    background-color: $lightPurple3;
    width: 100%;
  }

  img {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    object-fit: contain;
  }

  .btnUpload {
    display: flex;
    width: 93%;
    justify-content: flex-end;
    align-items: center;
    transition: opacity 0.3s;

    .reports_pro_card_button {
      background-color: #5a00ff;
      height: 50px;
      width: 50px;
      position: absolute;

      svg {
        width: 20px;
        height: auto;
        color: white;
        stroke-width: 2.5;
      }
    }
  }
}

.images_dots {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  transition: opacity 0.3s;

  .image_dot {
    width: 10px;
    cursor: pointer;
    height: 10px;
    border-radius: 50%;
    background-color: $white;
    border: 1px solid #5a00ff;

    &.selected {
      background-color: #5a00ff;
    }
  }
}