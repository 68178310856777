@import 'variables.scss';

.teamRow {
  display: flex;
  align-items: center;
  height: 32px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  span {
    white-space: normal;
  }
}

.backgroundContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: $light;

  @media print {
    background-color: $white;
  }
}

.dotIcon {
  background-color: $lighterGrey;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.redDot {
  background-color: #f97171;
}

.yellowDot {
  background-color: #f5cc23;
}

.purpleDot {
  background-color: $lightPurple1;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(100vh - 60px);
}

.container {
  width: 21cm;
  //height: calc(29.7cm - 1px);
  padding: 40px;
  margin: 20px auto;
  background: $white;
  border: 1px solid $lighterGrey;

  &:last-child {
    margin: 20px;
  }

  @media print {
    page-break-after: always;
    -webkit-print-color-adjust: exact;

    max-width: none;
    min-height: auto;
    padding: 0;
    margin: 0;
    border: none;
  }

  & > div {
    margin-bottom: 15px;
  }

  .formGroup {
    margin-bottom: 10px;

    label {
      color: $dark;
      font-size: 14px;
      margin-right: 5px;
    }

    span {
      font-size: small;
      font-weight: 500;
    }

    &.starRating {
      display: flex;
      justify-content: space-between;
    }
  }

  .athletePositionName {
    font-size: 24px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 4px 0;
  }

  .allInfo {
    border: solid $lightGrey 1px;
    border-spacing: 0;
    border-collapse: collapse;

    @media print {
      border: none;
    }
  }

  .mainInfo {
    display: flex;
    align-items: center;
    page-break-after: avoid;
    break-after: avoid;
    page-break-after: avoid;
    -webkit-break-after: avoid;
    box-shadow: none;
    padding: 12px 0 0 0;
    margin: 0 20px;
    border-bottom: 1px solid #bdbdbd;

    &:hover {
      box-shadow: none;
    }

    .athletePhotoDiv {
      margin-right: 30px;
      text-align: center;

      .athletePhoto {
        border-radius: 50%;
        margin-bottom: 10px;
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: cover;
        height: 150px;
        min-height: 150px;
        min-width: 150px;
        -webkit-print-color-adjust: exact;
        align-self: center;
      }
    }

    .athleteInfo {
      width: 100%;

      .generalData {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        padding-bottom: 5px;

        .leftContent {
          .athleteName {
            display: flex;
            align-items: baseline;

            p {
              font-size: 28px;
              font-weight: 700;
              margin-right: 10px;
              margin-bottom: 5px;
              margin-top: 0;
              line-height: 28px;
            }

            span {
              font-size: 16px;
              margin-right: 10px;
              font-weight: 400;
              line-height: 19.2px;
            }
          }

          .athleteAge {
            // span {
            //   margin-right: 5px;
            // }

            img {
              height: 15px;

              &:not(:last-child) {
                margin-right: 5px;
              }
            }

            .formGroup {
              display: flex;
              gap: 7px;
              align-items: center;
              font-size: 14px;
              line-height: 16.8px;

              :first-child {
                margin: 1px solid red;
              }

              div {
                display: flex;
                gap: 5px;
              }

              p {
                font-weight: 400;
              }

              span {
                font-size: 14px;
                font-weight: 600;
                margin-right: 0;
              }
            }
          }
        }

        .rightContent {
          .formGroup {
            display: flex;

            label {
              margin-left: 30px;
            }
          }
        }
      }

      .aditionalData {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;

        > div {
          display: inline-block;
          flex-direction: column;
          justify-content: center;
          margin-right: 10px;
          width: 33%;
        }

        // > div:last-child {
        //     .formGroup:nth-of-type(even):not(:last-child) {
        //         border-bottom: solid $lightGrey 1px;
        //     }
        // }

        .formGroup {
          margin-bottom: 10px;
          line-height: 16.8px;
          font-size: 14px;
          white-space: nowrap;
          display: flex;
          flex-direction: column;

          p {
            font-weight: 400;
          }

          span {
            font-weight: 600;
          }

          .flags {
            color: black;
            font-weight: 500;
            font-size: small;
            display: flex;
            align-items: center;
            width: 100%;
            height: 36px;

            img {
              max-width: 21px;
              max-height: 20px;
              margin-right: 5px;
            }

            span {
              max-height: 36px;
            }
          }
        }

        .avatar {
          max-width: 25px;
          max-height: 25px;
          margin-right: 5px;

          span {
            margin: 2px;
          }
        }
      }
    }
  }

  .athletePerformance {
    .generalData {
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 30% 70%;

      label {
        font-size: 24px;
        font-weight: bold;
        padding-right: 50px;
      }

      span {
        color: $dark;
        font-size: smaller;
        line-height: 1.5rem;
        white-space: pre-wrap;
      }

      b {
        line-height: 1.5rem;
      }
    }

    .generalDataEvaluation {
      display: flex;
      flex-direction: column;
      grid-template-columns: 25% 75%;
      margin: 25px 0;

      .labelTitle {
        font-size: 20px;
        margin-bottom: 12px;
        font-weight: bold;
      }

      .generalEvaluationInside {
        display: grid;
        grid-template-columns: 49% 49%;
        gap: 10px;

        .evaluationResume {
          display: flex;
          flex-direction: column;

          h1 {
            font-weight: bold;
            font-size: 16px !important;
          }

          p {
            font-size: 13px;
            margin-bottom: 5px;
          }

          .generalEvaluationResume {
            display: flex;
            gap: 5px;
          }
        }
      }
    }

    .imagesContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      align-items: flex-start;

      figure {
        background-color: $light;
        margin: 0;
        padding: 12px;
        text-align: center;

        img {
          max-width: 100%;
          width: auto;
        }

        figcaption {
          color: $dark;
          font-weight: bold;
          font-size: smaller;
          text-align: center;
        }
      }
    }

    .negotiation {
      display: flex;
      margin: 20px 10px;

      div.info {
        width: 40%;

        img {
          margin-right: 5px;
          width: 20px;
        }

        p {
          padding: 0;
          margin: 0;
        }
      }

      div.inputs {
        width: 60%;
      }

      span {
        font-size: medium;
        display: block;
      }
    }
  }

  .evaluation {
    h3 {
      font-size: unset;
      color: #000;
    }
  }

  .performanceByTeam {
    table {
      tr {
        td {
          padding: 0.45rem !important;

          div {
            justify-content: center !important;
          }

          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            div {
              justify-content: left !important;
              text-align: left !important;
            }
          }
        }

        th {
          justify-content: left;

          div {
            justify-content: center !important;

            img {
              @media print {
                height: auto;
                max-width: 1rem;
              }
            }
          }

          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            div {
              justify-content: left !important;
              text-align: left !important;
            }
          }
        }
      }
    }
  }

  .transfers {
    .athleteTransfers {
      .flagsDiv {
        display: flex;
        align-items: center;

        svg {
          font-size: 25px;

          @media print {
            width: 18px;
          }
        }

        .countryFlag {
          margin-left: 10px;
          margin-right: 8px;
          width: 25px;

          @media print {
            width: 18px;
          }
        }
      }
    }
  }

  .contracts {
    .athleteContracts {
      .contractStatus {
        display: flex;
        align-items: center;

        .dotIcon {
          margin-right: 5px;
          width: 15px;
          height: 15px;
        }
      }

      .contractActive {
        color: $darker;
      }

      .contractInactive {
        color: $lightGrey;
      }
    }
  }

  table th h2 {
    margin-bottom: 10px;
  }

  .tableCard {
    // @media print {
    //     break-inside: avoid-page;
    // }

    h2 {
      @media print {
        page-break-after: avoid;
      }

      &:first-of-type {
        margin-top: 0;
      }
    }

    div {
      @media print {
        page-break-before: avoid;
      }
    }

    table {
      thead {
        display: table-header-group;

        @media print {
          > * {
            // break-inside: avoid;
            break-after: avoid-page;
            break-before: avoid-page;
          }
        }
      }

      tr {
        td {
          @media print {
            padding: 0.6em;
            font-size: 0.9rem;
          }

          &:last-child {
            width: auto;
          }
        }

        th {
          div {
            img {
              @media print {
                height: auto;
                max-width: 1rem;
              }
            }
          }

          @media print {
            &:first-child {
              border-bottom: none !important;
            }

            &:last-child {
              border-right: none !important;
              border-bottom: none !important;
            }
          }
        }
      }

      tbody {
        tr {
          height: 1.35cm;
        }
      }
    }

    @media print {
      padding: 0;
      font-size: 0.7rem;
    }
  }
}

.evaluationHeaderInfo {
  align-items: center !important;
  flex-direction: row;
  justify-content: center;
  display: flex;
  background-color: #f2f2f2;
  padding: 0.8rem;
  border-radius: 8px;
  gap: 5%;

  p {
    font-weight: bold;
    font-size: 14px;
  }

  span {
    font-size: 14px;
  }

  div {
    display: flex;
    gap: 10px;
    align-items: center !important;
    color: $lightPurple1;
  }
}

.evaluationHeaderSubInfo {
  align-items: center !important;
  flex-direction: row;
  justify-content: center;
  display: flex;
  border: solid 1px #f2f2f2;
  padding: 0.8rem;
  border-radius: 8px;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 13px;

  > div {
    display: flex;
    gap: 10px;
    align-items: center !important;
  }

  p {
    font-weight: bold;
    font-size: 14px;
  }
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.priority {
  display: flex;
  align-items: center;
  flex-direction: row;

  div {
    margin-right: 5px;
  }
}

.lowPriority {
  @extend .circle;

  background-color: $info;
}

.mediumPriority {
  @extend .circle;

  background-color: $warning;
}

.highPriority {
  @extend .circle;

  background-color: $danger;
}

.customFields {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 15px;

  .formGroup {
    margin-bottom: 0;
  }
}

.assignsContainer {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: center;
  grid-column-gap: 16px;
  column-gap: 16px;
  grid-row-gap: 50px;
  row-gap: 63px;
  margin: 55px 0px 20px;

  .assignsContents {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    gap: 7px;

    .assignsContentsLabel {
      display: flex;
      border-top: 1px solid #bdbdbd;
      width: 100%;
      justify-content: center;
      padding-top: 3px;
    }

    span {
      display: flex;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
      display: flex;
      text-align: center;
    }
  }
}

.assignsContainerPalmeiras {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  grid-column-gap: 16px;
  column-gap: 16px;
  grid-row-gap: 50px;
  row-gap: 63px;
  margin: 55px 0px 20px;

  .assignsContents {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    gap: 7px;

    .assignsContentsLabel {
      display: flex;
      border-top: 1px solid #bdbdbd;
      width: 100%;
      justify-content: center;
      padding-top: 3px;
    }

    span {
      display: flex;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
      display: flex;
      text-align: center;
    }
  }
}
