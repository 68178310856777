@import 'variables.scss';

.athleteContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $lighterBorder;

  .link {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    position: relative;
    text-decoration: none;
    padding: 10px 5px;
    cursor: pointer;
    background: #f2f2f2;
    width: 100%;

    &:hover {
      background: $white;
    }

    &:active {
      color: $darker;
    }

    &:visited {
      color: $darker;
    }
  }

  &.athleteSelected .link {
    background: $white;
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 80px;

    .clubContainer {
      display: flex;

      .athleteImg {
        img {
          width: 60px;
          height: 60px;
        }
      }

      .clubFrom {
        position: absolute;
        height: 20px;
        width: 20px;
        bottom: -25px;
        right: -10px;
      }
      .clubTo {
        position: absolute;
        height: 20px;
        width: 20px;
        bottom: -25px;
        left: 0px;
      }
      .buy {
        position: absolute;
        bottom: -22px;
        left: 50%;
        margin-left: -3px;
        color: green;
      }
      .sell {
        position: absolute;
        bottom: -22px;
        left: 50%;
        margin-left: -3px;
        color: red;
      }
    }
  }

  .center {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 5px;
    width: 230px;

    span {
      font-style: italic;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 115%;
      letter-spacing: 0px;
      font-size: 14px;
    }

    .name {
      color: $lightPurple1;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      margin: 0;
      letter-spacing: 0px;
    }
  }

  .right {
    align-self: flex-start;
    display: block;
    text-align: right;
    margin-left: 10px;

    .date {
      display: block;
      font-size: 12px;
      margin: 0 0 25px;
      letter-spacing: 0px;
    }

    .alert {
      background: red;
      font-size: 11px;
      border-radius: 8px;
      display: inline-block;
      padding: 1px 5px;

      span {
        color: #fff;
      }
    }
  }
}
