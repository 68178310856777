@import 'variables.scss';

.fieldList2 {
  flex-basis: 50%;
  align-self: stretch;
  padding: 1rem;
  border-left: 1px solid $lightBorder !important;

  @media (max-width: $maxSmall) {
    flex-basis: 100%;
  }
}

.fieldList {
  flex-basis: 65%;
  align-self: stretch;
  padding: 1rem;
  border-left: 1px solid $lightBorder !important;
  padding-left: 15px;

  @media (max-width: $maxSmall) {
    flex-basis: 100%;
  }
}

.evaluationHeaderInfo {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  text-align: start;
}

.evaluationContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 0px 30px;
  margin-bottom: 30px;

  .matchInfo {
    display: flex;
    flex-direction: column;
    border: 1px solid $lightBorder;
    border-radius: 8px;
    min-width: 300px;
    padding: 30px;
  }
}

.container {
  display: grid;
  grid-template-columns: 29% 42% 29%;
  align-items: start;

  h3 {
    margin-top: 10px;
  }
}

.opinionFields {
  grid-column: 1; // Left section
  border-right: 1px solid #bdbdbd;
  padding: 1rem 1.875rem;
  height: calc(100% - 2rem);
}

.evaluationFields {
  grid-column: 2; // Center section
  padding: 1rem 1.875rem;
  height: calc(100% - 2rem);
}

.contextFields {
  grid-column: 3; // Right section
  border-left: 1px solid #bdbdbd;
  padding: 1rem 1.875rem;
  height: calc(100% - 2rem);
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1rem;

  .formLabel {
    display: flex;
    margin-right: 1rem;
    font-weight: 500;
    font-size: 14px;
    align-self: flex-start;
    margin-top: 12px;
  }
}

.textAreaField {
  background-color: white !important;
  resize: none;
  border-radius: 8px;
}
