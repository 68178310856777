@import 'variables.scss';

.clubDiv {
  display: flex;
  align-items: center;
  // justify-content: center;

  .club_image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    margin-right: 10px;

    .avatar {
      height: 30px;
      width: auto;

      @media print {
        display: none;
      }
    }
  }
}

.sigiloso {
  .no_link {
    color: $gray5 !important;
  }

  svg {
    color: $gray5;
    font-size: 30px;
    width: 35px;
    margin-right: 10px;
  }
}

.link {
  display: inline;
  text-decoration: none;
  color: $lightPurple1;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;

  @media print {
    color: $dark;
    font-size: 9pt;
  }

  &[data-text-break='true'] {
    white-space: normal;
  }
}

.no_link {
  display: inline;
  text-decoration: none;
  color: $black;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;

  span {
    &:first-child {
      color: $lightPurple1;
      font-weight: 600;
    }
  }

  @media print {
    color: $dark;
    font-size: 9pt;
  }

  &[data-text-break='true'] {
    white-space: normal;
  }
}

.contractDate {
  :first-child {
    color: $lightPurple1;
    font-size: 14px;
    font-weight: 500;
  }
  :last-child {
    display: block;
  }
}