@import 'variables.scss';

.container {
    display: flex;
    max-width: 100%;
    background-color: $white;
    border-top: 1px solid $lightBorder;
    bottom: 0;

    .content {
        margin-top: 15px;
        padding-bottom: 25px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .pagination {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-size: smaller;
            }

            .divButtons {
                margin-left: 10px;

                button {
                    background-color: $lightPurple1;
                    border: none;
                    color: $white;
                    border-radius: 4px;
                    padding: 8px;
                    line-height: 0.5rem;
                    margin-right: 5px;

                    &:disabled {
                        background-color: #EEE5FF;
                        border: 1px solid #CEB3FF;
                        color: $lightGrey;
                        border-radius: 4px;

                        &:hover {
                            opacity: inherit;
                        }
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }

        .itensPerPage {
            display: flex;
            align-items: center;

            span {
                font-size: smaller;
            }

            select {
                border: 1px solid $lightBorder;
                background-color: #fff;
            }
        }
    }
}

.spacer {
    height: 65px;
}