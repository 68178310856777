@import 'variables.scss';

.career {
  margin: 25px 30px 30px 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      color: $black;
      margin: 10px 0;
      font-size: 24px;
      font-weight: 700;
    }

    p {
      margin: 0;
    }

    @media (max-width: $maxMedium) {
      p {
        margin-left: 2.5rem;
        text-align: right;
        font-size: small;
      }
    }
  }

  .withImage {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .arrow {
      width: 32.5px;
      height: auto;
    }

    img {
      width: 25px;
      height: auto;
      margin: 0 0.3rem;
    }

    span {
      white-space: nowrap;
    }
  }

  .link {
    cursor: pointer;
    color: $lightPurple1;
    font-size: 16px;
    //---letter-spacing: -1px;
    font-weight: 700;
    transition: color 300ms;

    &:hover {
      color: $lightPurple1;
    }
  }
}

.tableCarrer {
  border: 1px solid $lightBorder;
  border-radius: 8px;

  thead {
    tr th {
      font-weight: 600 !important;
      font-size: 14px !important;
      border-bottom: 1px solid $lightBorder;
      background-color: $white !important;
      color: $black !important;
    }
  }

  tbody {
    tr td:first-child {
      border-left: none !important;
    }

    tr td:last-child {
      border-right: none !important;
    }
  }

  tfoot {
    border: none !important;

    tr th {
      background-color: $white !important;
      border: none !important;
      padding: 1rem !important;

      a {
        color: $lightPurple1 !important;
        font-weight: 700 !important;
        font-size: 14px !important;
      }
    }
  }
}
