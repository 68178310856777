@import 'variables.scss';

.container {
  margin-right: 10px;

  & .panelTitle {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  & .group {
    font-size: 14px;
    border-radius: 8px;
    border: solid 1px $lightBorder;
    width: 280px;
    height: 50px;
    align-items: center;
    display: flex;
    margin-bottom: 10px;

    .groupColor {
      width: 11px;
      height: 52px;
      top: 0;
      bottom: 0;
      position: relative;
      left: -1px;
      border-radius: 8px 0 0 8px;
      margin-right: 10px;

      &.groupColor_finished {
        background-color: $gray3;
      }

      &.groupColor_danger {
        background-color: #eb5757;
      }

      &.groupColor_warning {
        background-color: #f2994a;
      }
    }

    .athlete_length {
      font-weight: 600;
      color: $lightPurple1;
    }
  }

  & .moreAthletes {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 15px;
  }

  & .athleteCard {
    margin: 10px 0;
    box-shadow: none;
    border: 1px solid #aaa;

    &.groupWarning {
      border-left: 8px solid #f2994a;
    }

    &.groupDanger {
      border-left: 8px solid red;
    }
  }

  .button {
    font-weight: 700 !important;
    font-size: 14px !important;
  }
}