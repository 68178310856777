@import 'variables.scss';

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(100vh - 60px);
}

.container {
  @media (max-width: $maxMedium) {
    padding-top: 150px;
  }
}

.transferWindows {
  padding: 0 !important;
  height: calc(100% + 100px);

  .tableContainer {
    max-width: 100%;
    overflow-x: auto;

    .link {
      color: $dark;
      text-decoration: none;
    }
  }

  .targetingHeader {
    display: flex;
    text-align: left;
  }

  .listTable {

    .switch {
      border: solid 1px $lightPurple1;

    }

    thead tr th {
      padding: 5px !important;

      &:nth-child(1) {
        div {
          justify-content: center !important;
          margin-left: 10px;
        }
      }

      &:nth-child(5),
      &:nth-child(6) {
        div {
          justify-content: center !important;
        }
      }

      div {
        font-weight: 600;
        justify-content: left !important;
      }
    }

    tbody tr td {
      padding: 5px !important;

      &:nth-child(5),
      &:nth-child(6) {
        div {
          text-align: center !important;
        }
      }

      div {
        text-align: left;

        >img {
          height: 20px !important;
          width: auto;
        }
      }
    }
  }

  .checkbox {
    color: $light;
    transform: translateY(-1px);
  }

  .targetingRow {
    display: flex;
    align-items: center;

    .menu {
      margin-right: 0.5rem;
    }

    button {
      font-size: small;
      padding: 0.3rem;
      white-space: nowrap;
      box-shadow: none;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(50vh);
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.errorContainer {
  p {
    margin: 0;
  }
}

.actionBar {
  padding: 0;
  background: none;
}

.windowPast {
  color: $lighterGrey;
}

.windowCurrent {
  color: $darkPurple9;
}

.windowFuture {
  color: $dark;
}

.windowNotDefined {
  color: $dark;
}

.countryRowContainer {
  margin-right: 20px;
}