@import 'variables.scss';

.divider {
  background-color: $lightBorder;
  width: 100%;
  height: 1px;

  span {
    color: transparent;
  }

  &.vertical {
    height: 100%;
    width: 1px;
  }
}