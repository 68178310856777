@import 'variables.scss';

.container {
  border-bottom: 1px solid $lighterBorder;

  .container_row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 5px;

      .avatar_container {
        display: flex;
        margin: 0 10px;

        .athlete_image_container {
          display: flex;

          .athlete {
            width: 60px;
            height: 60px;
          }
        }
      }

      .info_container {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 4px;

        .name {
          color: $black;
          font-size: 18px;
          font-weight: 700;
          margin: 0;
        }

        .fullname {
          color: $black;
          font-size: 14px;
          font-weight: 400;
        }

        .general_info {
          display: flex;
          align-items: center;
          gap: 5px;

          span {
            font-weight: 400;
            font-size: 14px;
          }

          .country_info {
            display: flex;
            gap: 8px;

            img {
              height: 15px;
              width: 21px;
              border: 1px solid $lighterBorder;
              border-radius: 4px;
            }
          }
        }
      }
    }

    .center {
      display: flex;
      flex-direction: column;
      padding: 10px 5px;
      gap: 5px;

      .club_container_header {
        align-self: self-start;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
      }

      .club_container {
        display: flex;
        align-items: center;

        .club_avatar_container {
          display: flex;
          margin-right: 8px;

          .club {
            height: 30px;
            width: 30px;
          }
        }

        .club_info_container {
          display: flex;
          flex-direction: column;

          .club_name {
            color: $lightPurple1;
            font-size: 16px;
            font-weight: 600;
          }

          .club_contract {
            color: $black;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }

    .right {
      display: flex;
      align-self: center;
      margin-right: 20px;

      .button {
        width: 190px;
        height: 40px;
        padding: 10px;
        background-color: $white;
        border: 2px solid $lightPurple1;
        border-radius: 8px;
        flex-direction: row-reverse;
        gap: 10px;

        span {
          font-size: 14px;
          font-weight: 700;
          color: $lightPurple1;
        }

        img {
          filter: invert(80%) sepia(100%) saturate(8000%) hue-rotate(255deg) brightness(100%) contrast(126%);
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}