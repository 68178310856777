@import 'variables.scss';

.container {
  display: flex;
  flex-flow: column;
  padding: 0 20px;

  .title {
    display: flex;
    flex-flow: column;
    margin: 30px 0;

    h1 {
      margin: 0 0 10px 0;
      font-size: 24px;
      font-weight: 700;
    }
  }

  .athleteGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    grid-gap: 15px;

    .athleteCard {
      width: 310px;
      border: 1px solid $lightBorder;
      border-radius: 8px;

      .left {
        display: flex;
        padding: 15px 0;
        margin-left: 5px;

        .avatar {
          min-width: 80px;
          min-height: 80px;
          margin-right: 5px;
        }
      }

      .right {
        margin-top: 5px;

        .name {
          color: $lightPurple1;
          font-size: 18px;
          font-weight: 600;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 210px;
          display: block;
          overflow: hidden;
        }
      }

      .completeName {
        color: $darker;
        font-size: smaller;
        margin: 0 0 5px 0;
      }

      .info {
        display: flex;
        flex-direction: row;
        margin: 1px 0 3px 0;

        .occupation {
          color: #4a4a4a;
          font-size: 16px;
          // font-weight: bold;
        }

        & > * {
          margin-right: 8px;
        }

        img {
          width: 20px;
          height: 20px;
        }

        span {
          color: $darker;
          font-size: smaller;
        }
      }

      .infoCountry {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;

        & > * {
          margin-right: 8px;
        }

        img {
          width: 20px;
          height: 20px;
        }

        span {
          color: $darker;
          font-size: smaller;
        }
      }

      .remove {
        width: 100%;
        height: 35px;
        padding: 10px;
        background-color: $white;
        border: 1px solid $lightPurple1;
        border-radius: 8px;
        flex-direction: row;
        gap: 15px;
        align-items: center;

        span {
          font-size: 12px !important;
          font-weight: 700;
          color: $lightPurple1;
          align-self: start;
        }

        svg {
          color: $lightPurple1;
          width: 15px;
          height: 15px;
        }
      }

      .editPermition {
        width: 168px;
        height: 35px;
        padding: 10px;
        background-color: $lightPurple1;
        border: 1px solid $lightPurple1;
        border-radius: 8px;
        flex-direction: row;
        gap: 15px;
        align-items: center;

        span {
          font-size: 12px !important;
          font-weight: 700;
          color: $white;
          align-self: start;
        }

        svg {
          color: $white;
          width: 15px;
          height: 15px;
        }
      }

      .bottons {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }

      .bottons2 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin: 10px 10px 0 10px;
      }
    }
  }
}
