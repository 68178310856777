@import 'variables.scss';

.monitoringTitle {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  padding-bottom: 10px;
  background-color: $white;  
  color: $black;
  font-size: 16px;
  font-weight: 600;
  width: 100%;

  &.largeMenu {
    min-width: 250px;
  }

  svg {
    width: 20px;
    height: auto;
    color: $lightPurple1;

    &:hover {
      cursor: pointer;
    }
  }
}

.borderBottom {
  border-bottom: 1px solid $lightBorder;
  padding-bottom: 15px;
}

.listCheckboxs {
  display: flex;

  .divDelete {
    margin-right: 5px;
    width: 16px;

    svg {
      cursor: pointer;
    }
  }

  .divDisable {
    margin-right: 5px;
    width: 16px;
  }

  small {
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
  }
}

.cursorPointer {
  cursor: pointer;
}

.spinnerSaving {
  margin-right: 9px;
  margin-bottom: 8px;
}

.bodyDialog {
  margin: 0 6px;

  .inputCreate {
    margin-top: 5px;

    &::placeholder {
      color: $darker;
    }
  }
}


.card {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  box-sizing: border-box;
  border-radius: 8px;
  max-height: 80vh;
  overflow: hidden;
  border: 1px solid $lighterGrey;
  box-shadow: 1px 1px 3px $lighterBorder !important;

  &.largeMenu {
    width: 330px;
    margin-right: 15px;
  }

  .contentContainer {
    height: 250px;
    overflow-y: scroll;
    padding: 15px;

    &::-webkit-scrollbar {
      display: none;
    }

    .inputContainer {
      margin-bottom: 20px;

      input {
        height: 18px;
      }

      svg {
        color: $lightPurple1;
        transform: scaleX(-1);
      }
    }

    .checkboxItem, .checkboxItemSaving {
      font-size: medium;
      margin-bottom: 10px;
    }

    .checkboxItemSaving {
      display: none;
    }

    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 19.2px;
      margin-bottom: 10px;
    }
  }

  .contentContainerLarge {
    height: 291.8px;
  }

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid lightgrey;
    min-height: 40px;

    .add_button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
      gap: 10px;
      border: 1px solid $lightPurple1;
      border-radius: 8px;
      width: 177px;
      height: 40px;

      span {
        color: $lightPurple1;
        font-weight: 700;
        font-size: 14px;
      }

      svg {
        color: $lightPurple1;
        height: 22px;
        width: 22px;
      }
    }

    .save_button {
      background-color: $lightPurple1;
      color: $white;
      width: 69px;
      height: 39px;
      padding: 11px 15px;
      font-weight: 700;
      font-size: 14px;
      border: 1px solid $lightPurple1;
    }
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 433.8px;
}

.errorContainer {
  margin: 0.6rem;
  padding: 2rem;
  text-align: center;

  h2 {
    color: $dark;
    margin: 0;
  }
}

.validationMessage {
  color: $danger;
  font-size: 12px;
}

.tabs {
  width: 100%;
  display: flex;
  flex-direction: row; 
  border-bottom: 1px solid $lightBorder;

  .tab {
    padding: 14px 20px 14px 20px;
    text-align: center;
    width: 50%;
    border-bottom: 3px solid transparent;
    margin-bottom: -1px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;
    cursor: pointer;
  }

  .tab:hover{
    border-bottom: 3px solid $lightPurple1;
  }

  .active {
    border-bottom: 3px solid $lightPurple1;
  }
}

.radioContainer {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  width: 100%; 

  .projectsBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    gap: 10px;
    border: 1px solid $lightPurple1;
    border-radius: 8px;
    width: 79px;
    height: 37px;

    span {
      color: $lightPurple1;
      font-weight: 700;
      font-size: 14px;
    }

    svg {
      color: $lightPurple1;
      height: 22px;
      width: 22px;
    }
  }
}

.customContainerWidth {
  max-width: 380px;
}

.radioDelete {
  font-weight: 600;
  color: #EB5757;
}