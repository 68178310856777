@import 'variables.scss';

.container {
  background-color: white;
  padding: 30px;

  & .panelTitle {
    padding: 0px;
  }

  & .title {
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 10px;
  }

  & .subTitle {
    font-size: 16px;
  }
}

.graph_container {
  background-color: $darkPurple2;
  display: flex;
  padding: 50px;
  width: fit-content;
}

.profileCast {
  padding: 0 !important;
  height: calc(100% + 100px);
}

.filtros {
  display: flex;
  margin-top: 18px;
  margin-bottom: 20px;

  .menus {
    display: flex;
    align-items: center;
    margin-left: 12px;

    .menu {
      border: 1px solid $lightBorder;
      margin-left: 10px;
      background-color: $white;
      font-size: 14px;
      font-weight: 500;
      height: 13.6px;
    }
  }

  .button {
    display: flex;
    height: 37px;
    margin: 10px;
    font-size: 14px;
    font-weight: 600;
    gap: 10px;

    div {
      cursor: pointer;
      color: $black;
      background-color: $white;
      border: 1px solid $lightBorder;
      padding: 10px 20px;
      border-radius: 8px;
      align-self: baseline;
    }

    .selected {
      border-color: $lightPurple4;
      background-color: $lightPurple4;
      color: $lightPurple1;
    }
  }
}