@import 'variables.scss';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: none;
  width: 33px;
  height: 33px;
  padding: 0.5rem;
  border-radius: 100%;
  background-color: $light;
  outline: none;
  font-weight: 700;
  //box-shadow: 1px 1px 3px $lighterBorder;
  //transition: box-shadow 300ms ease, background 300ms ease;
  color: $dark;

  &.green {
    background-color: $darkPurple9;
    color: $light;
  }

  &.purple {
    color: $white;
    background-color: #5900ff;
    //box-shadow: none;
  }

  &.lightPurple1 {
    //margin-bottom: 15px;
    width: 184px;
    height: 40px;
    padding: 15px;
    border-radius: 8px;
    background-color: $lightPurple1 !important;

    span {
      font-size: 13px;
      color: $white;
    }

    svg {
      margin-left: 15px;
      color: $white;
      width: 18px;
      height: 18px;
    }

    &:hover {
      background-color: $lightPurple2 !important;
    }
  }

  &.lightBlue {
    background-color: $lightBlue;
    color: $light;
  }

  &.orange {
    background-color: $warning;
    color: $light;
  }

  &.dark {
    background-color: $lighterGrey;
  }




  &:hover {
    cursor: pointer;
    //box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

    &.green,
    &.lightPurple1 {
      box-shadow: 0px 0px 3px 1px $white;
      background-color: $lightPurple2;
    }

    &.lightBlue {
      box-shadow: 0px 0px 3px 1px $white;
    }

    &.white {
      box-shadow: 0px 0px 3px 1px $white;
    }
  }

  &:disabled {
    background-color: rgb(216, 216, 216);
    border: 1px solid transparent;
    color: rgb(182, 182, 182);
    cursor: default;
    box-shadow: none;

    &.outline {
      background-color: transparent;
      border: 1px solid rgb(170, 170, 170);
      color: rgb(170, 170, 170);
    }

    &:hover {
      box-shadow: none;
    }
  }

  svg {
    width: 100%;
    height: auto;
    color: inherit;
  }
}

.tooltip {
  opacity: 1 !important;
}