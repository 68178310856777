@import 'variables.scss';

.actionBar:first-child,
:not(.actionBar)+.actionBar {
  border-radius: 8px 8px 0 0;
}

.navigationBar {
  padding: 0;
  margin: 0;

  .offeredStatusItem span {
    padding: 0 6px 1px 6px;
    margin-left: 8px;
    line-height: 1;
    font-size: 12px;
    background-color: $lightPurple2;
    border-radius: 20px;
    color: $white;
  }
}

.multipleButtons {
  display: flex;
  gap: 10px;
}

.container {
  flex-grow: 1;
  padding: 15px 20px;
  padding-left: 0px;
  margin-top: 15px;
  background-color: #ffffff;
  right: 0;
  bottom: 60px;

  span {
    font-size: 10pt;
  }

  .registersCounter {
    margin-bottom: 2px;
    font-size: 17px !important;

    strong {
      color: black;
    }
  }

  .flag {
    div {
      justify-content: left;
    }

    img {
      max-width: 24px;
    }
  }

  .button {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  table {
    tbody {
      td {
        background-color: #fff;
      }
    }
  }

  .iconButton {
    font-weight: 700;
    font-size: 14px;
    color: $white;
    width: 149px;
    height: 40px;
    padding: 10px 10px 10px 15px;
    border-radius: 8px;
    margin-right: 20px;
    background-color: $lightPurple1 !important;

    .text {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        height: 19px;
      }
    }

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  .topBar {
    display: flex;
    align-items: center;
  }

  .actionBar {
    height: 60px;
    border: 1px solid $lightBorder;
    border-bottom: none;
    display: flex;
    align-items: center;

    .icon {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin: 0 10px;
      font-size: large;
      color: $white;
    }

    .textTransfer {
      font-size: 17px;
    }

    .actionBarButton {
      float: right;
      cursor: pointer;
      margin-left: auto;
      margin-right: 20px;

      .text {
        color: $lightPurple1;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          margin-right: 7px;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .athleteTableAdOwner th {
    border-radius: 0 !important;    
  }

  .athleteTable {
    padding-bottom: 25px;

    td {
      padding: 0.45rem !important;
      text-align: left !important;
    }

    th {
      justify-content: left;

      div {
        font-size: 14px;
        font-weight: 600;
        justify-content: left !important;
      }

    }

    // tbody{
    //     tr{

    //         &:hover{
    //             opacity: 0.8;
    //             cursor: pointer;
    //         }
    //     }
    // }

    tr {
      td {
        &:first-child {
          width: 10px;
          justify-content: center;

          label {
            display: block;
          }
        }
      }
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 160px;
  left: 315px;
  right: 0;
  bottom: 60px;
  align-items: center;
}

.errorContainer {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 160px;
  left: 315px;
  right: 0;
  bottom: 60px;
  align-items: center;
}

.buttonContainer {
  position: relative;
  margin-left: auto;
  width: min-content;
  text-wrap: nowrap;
  z-index: 2;

  div {
    height: 28px;
  }
}

.position{
  color: $lightPurple1;
}

.moreInfoButton{
  background-color: #fff;
  border: solid 1px $lightPurple1;
  color: $lightPurple1;
  font-weight: 600;
  border-radius: 4px;
  height: auto;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 10px;
  cursor: pointer;

  &:hover{
    background-color: $lightPurple4;
  }

  &:active {
    background-color: $lightPurple3;
  }
}

.purpleButton{
  background-color: $lightPurple1;
  border-color: $lightPurple1;
  color: $white;
  font-weight: bold;
  border-radius: 4px;
  height: auto;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 15px;
  width: 150px;

  &:hover{
    background-color: $lightPurple3;
    color: $lightPurple1;
  }

  &:active {
    background-color: $lightPurple4;
  }
}

.iconCheck {
  margin-left: 5px;
  font-size: 10px;
}

.dialogConfirmBody {
  font-weight: 400;
  font-family: 'Barlow';
  font-size: 14px;
  padding: 15px 20px 20px 20px !important;
}


.dialogConfirmTitle {
  font-family: 'Barlow';
  font-weight: 600;
  font-size: 16px;
  color: $black;
  display: flex;

  svg {
    color: $orange2;
    font-size: 20px;
    margin-right: 15px;
  }
}

.messageTabChange {
  color: $lightPurple1;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  margin-right: 10px;

  b {
    font-weight: 600;
  }
}

.trDisabled {
  position: relative;

  td {
    background-color: rgba(238, 229, 255, 0.5) !important;
  }

  td:not(:last-child) div {
    opacity: 0.67;
  }
}

.overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1000;
}

.dialogContainer {
  height: 170px;
  width: 740px;
  z-index: 2;
}