// color pallete
$black: #000000;
$darker: #363636;
$dark: #4a4a4a;
$dark2: #3b4758;
$gray: #62747c;
$gray2: #4f4f4f;
$gray3: #828282;
$gray5: #e0e0e0;
$gray6: #cfd4d7;
$warning: #fb3;
$danger: #ff5252;
$info: #29b6f6;
$dangerBg: #ffdfdf;
$white: #ffffff;
$light: #f5f5f5;
$lightBorder: #bdbdbd;
$lighterBorder: rgba(0, 0, 0, 0.25);
$lightGrey: #a9a9a9;
$lightGrey2: #f2f2f2;
$lighterGrey: #d7d6d2;
$lightGrey6: #f2f2f2;
$spanishWhite: #f9f6e7;

$orange: #f5a623;
$orange2: #f2994a;
$orange3: #fbe0c9;
$orangeText: #ce9232;

$lightBlue: #4fc3f7;
$blue3: #56ccf2;

$green1: #219653;
$green2: #27ae60;
$green3: #6fcf97;
$green4: #25d366;
$green5: #bde6ce;

$red: #eb5757;
$red2: #f8cbcb;

$pink1: #cc00ff;
$pink2: #f0b2ff;

$purple7: #BD99FF;
$purple9: #deccff;
$lightPurple1: #5a00ff;
$lightPurple2: #6b1aff;
$lightPurple3: #deccff;
$lightPurple4: #eee5ff;
$lightPurple8: #CEB3FF;
$darkPurple1: #09001A;
$darkPurple2: #120033;
$darkPurple5: #2D0080;
$darkPurple7: #3f00b3;
$darkPurple9: #5100e5;
$blueTwitter: #00aced;
$blueLinkedin: #0077b5;
$pinkInstagram: #cf4176;

// media queries
$maxSmall: 499px;
$maxMedium: 769px;
$minVeryLarge: 1920px;