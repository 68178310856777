@import 'variables.scss';

.container {
    margin-bottom: 30px;
    margin-top: 10px;
}

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */

    .icon {
        margin-right: 20px;

        svg {
            width: auto;
        }
    }

    .title {
        font-size: medium;
        font-weight: 500;
    }
}

.contentContainer {
    max-height: 0;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: max-height 0.25s ease-in-out;
}

.showMore {
    font-size: 14px;
    font-weight: 500;
    color: $lightPurple1;
    cursor: pointer;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */

    &:hover {
        opacity: 0.8;
    }
}