@import 'variables.scss';

.athleteContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $lighterBorder;

  .link {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    position: relative;
    text-decoration: none;
    padding: 10px 5px;
    cursor: pointer;
    background: #f2f2f2;

    &:hover {
      background: #ffffff;
    }

    &:active {
      color: $darker;
    }

    &:visited {
      color: $darker;
    }
  }

  &.athleteSelected .link {
    background: #ffffff;
  }

  .left {
    margin-right: 5px;
    width: 65px;

    .clubContainer {
      position: relative;
      height: 40px;
      width: 50px;
      margin-bottom: 25px;

      .athleteImg {
        position: absolute;
        top: -5px;
        left: 5px;
      }
      .clubFrom {
        position: absolute;
        height: auto;
        width: 20px;
        bottom: -25px;
        right: -10px;
      }
      .clubTo {
        position: absolute;
        height: auto;
        width: 20px;
        bottom: -25px;
        left: 0px;
      }
      .buy {
        position: absolute;
        bottom: -22px;
        left: 50%;
        margin-left: -3px;
        color: green;
      }
      .sell {
        position: absolute;
        bottom: -22px;
        left: 50%;
        margin-left: -3px;
        color: red;
      }
    }
  }

  .center {
    display: flex;
    flex: 1;
    flex-direction: column;

    .name {
      color: $lightPurple1;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      letter-spacing: 0px;
    }

    .completeName {
      color: $darker;
      font-size: 14px;
      letter-spacing: 0px;
    }

    .info {
      display: flex;
      padding-top: 3px;

      & > * {
        margin-right: 8px;
      }

      img {
        width: 20px;
      }

      span {
        color: $darker;
        font-size: 12px;
        letter-spacing: 0px;
      }
    }
  }

  .right {
    align-self: flex-start;
    display: block;
    text-align: right;
    margin-left: 10px;

    .date {
      display: block;
      font-size: 12px;
      margin: 0 0 25px;
      letter-spacing: 0px;
    }

    .alert {
      background: red;
      font-size: 11px;
      border-radius: 8px;
      display: inline-block;
      padding: 1px 5px;

      span {
        color: #fff;
      }
    }
  }
}
