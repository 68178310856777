.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  gap: 20px;

  .text {
    h1 {
      display: flex;
      justify-content: center;
    }
    p {
      display: flex;
      justify-content: center;
    }
  }

  img {
    width: 70px;
    height: 90px;
  }

  .buttonContainer {
    margin-top: 30px;
  }
}
