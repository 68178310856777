@import 'variables.scss';

.competition {
  padding: 15px !important;
  height: auto;
  background-color: #ffffff;
  border: solid 1px #dddddd;

  .reportContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    .itemContainer {
      width: 300px;
      height: 280px;
      padding: 8px;
      margin: 4px;
      box-sizing: border-box;

      img {
        width: 100%;
        height: 160px;
        object-fit: contain;
        transition: .5s;
      }

      h3 {
        font-weight: 500;
        margin: 10px 0 10px 0;
        padding-right: 55px;
        transition: color .5s;
      }

      div {
        font-size: 13px;
      }

      button {
        position: relative;
        bottom: 20px;
        float: right;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        border: none;
        font-size: 17px;
        vertical-align: middle;

        span {
          color: #ffffff;
        }
      }

      button:hover {
        cursor: pointer;
      }
    }
  }

  .title {
    color: $dark;
    height: 80px;
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    img {
      margin-left: 5px;
      margin-right: 15px;
    }
  }

}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(50vh);
}

.errorContainer {
  p {
    margin: 0;
  }
}