@import 'variables.scss';

.container {
    background-color: white;
    min-height: 100%;
    padding: 0 37px;
    padding-top: 20px;
    width: 350px;

    h4 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
    }

    .checkboxDiv {
        padding-top: 20px;

        ul {
            padding-left: 0;

            .checkboxItem {
                display: flex;
                list-style-type: none;
                margin-bottom: 8px;
                overflow: hidden;
                white-space: nowrap;

                label {
                    font-size: 0.8em;
                }
            }
        }
    }

    .selectedAthletes {
        h4 {
            margin-top: 30px;
            margin-bottom: 10px;
        }

        .athleteContainer {
            display: flex;
            padding: 0.5rem 1rem 0.5rem 0.5rem;
            border: 1px solid $lightBorder;
            align-items: center;
            position: relative;
            margin-bottom: 20px;

            .removeBtn {
                position: absolute;
                top: -8px;
                right: -8px;

                .iconButton {
                    height: 30px;
                    width: 30px;
                    padding: 7px;
                    margin: 0;
                    border-radius: 100%;
                    display: block;
                }

                .icon {
                    margin: 0;
                    width: 15px;
                    height: 15px;
                }
            }

            .left {
                margin-right: 5px;
                width: 65px;
            }

            .right {
                display: flex;
                flex-direction: column;
                width: 100%;

                .name {
                    h3 {
                        color: $lightPurple1;
                        font-size: 1rem;
                        margin: 0;
                    }
                }

                .completeName {
                    font-size: smaller;
                }

                .info {
                    display: flex;
                    justify-content: space-between;

                    img {
                        margin-right: 5px;
                        width: 20px;
                    }

                    span {
                        font-size: smaller;

                        &.age {
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .addAthleteContainer {
            position: relative;
            display: flex;
            align-items: center;
        }
    }

    .formGroup {
        padding-top: 10px;
        margin-top: 0.5rem;

        label {
            font-size: 14px;
            font-weight: 500;
        }

        input {
            margin-top: 0.3rem;
        }

        textarea {
            resize: vertical;
            background-color: $white;
            border-radius: 8px;
            margin-top: 0.3rem;
        }

        .maxchar {
            text-align: right;
            font-size: 12px;
            margin-top: 2px;
        }

        .darkPurple9 {
            font-size: smaller;
            color: $darkPurple9;
            font-weight: 500;
        }
    }
}