@import 'variables.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
  grid-row-gap: 13px;
  grid-column-gap: 22px;
  justify-content: left;
  position: relative;
  top: 70px;
  padding-bottom: 20px;

  .gridItem {
    border: 1px solid $lightBorder;
    border-radius: 8px;
    background-color: white;
    letter-spacing: 0px;
    font-weight: 500;

    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */

    &:hover {
      //box-shadow: 1px 1px 5px 1px $lightPurple1;
      cursor: pointer;
      background-color: $lightPurple3;
      color: $lightPurple1;
    }
  }
}

.countryFlag {
  height: 35px;
  width: auto;
  display: inline-block;
  margin: 10px;
  vertical-align: middle;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.countryName {
  display: inline-block;
  vertical-align: middle;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    width: 200px;
  }
}