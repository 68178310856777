@import 'variables.scss';

.container {
    padding: 20px;
    width: 100%;

    .content {
        background-color: white;
        margin-bottom: 15px;
        padding: 0 15px 15px;

        & > div {
            margin-bottom: 20px;
        }
    }
}
