@import 'variables.scss';

.athleteContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $lighterBorder;

  .link {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    position: relative;
    text-decoration: none;
    padding: 10px;
    cursor: pointer;
    background: $lightGrey2;

    &:hover {
      background: $white;
    }

    &:active {
      color: $darker;
    }

    &:visited {
      color: $darker;
    }
  }

  &.athleteSelected .link {
    background: $white;
  }

  .left {
    display: flex;

    .avatar_container {
      display: flex;
      margin-right: 15px;

      .athlete_image_container {
        display: flex;

        .athlete {
          height: 60px;
          width: 60px;
        }
      }

      .athlete_club_container {
        display: flex;
        position: absolute;
        top: calc(100% - 30px);
        right: calc(100% - 70px);

        .athlete_club {
          min-height: 16px;
          height: auto;
          width: 20px;
        }
      }
    }
  }

  .center {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 5px;
    width: 230px;

    span {
      font-style: italic;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 115%;
      letter-spacing: 0px;
      font-size: 14px;
    }

    .name {
      color: $lightPurple1;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      margin: 0;
      letter-spacing: 0px;
    }
  }

  .right {
    align-self: flex-start;
    display: block;
    text-align: right;
    margin-left: 10px;

    .date {
      display: block;
      font-size: 12px;
      margin: 0 0 25px;
      letter-spacing: 0px;
    }

    .alert {
      background: red;
      font-size: 11px;
      border-radius: 8px;
      display: inline-block;
      padding: 1px 5px;

      span {
        color: #fff;
      }
    }
  }
}