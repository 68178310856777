@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.selectChat {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.noChatSelected {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.panel {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;  
  background: #fff;
  min-height: calc(100vh - 240px);
  overflow: auto;
}
