@import 'variables.scss';

.container {
    display: flex;
    align-items: center;
    font-size: small;
    color: $darker;
    //---letter-spacing: -0.5px;
    user-select: none;

    .radio {
        width: 15px;
        height: 15px;
        margin-right: 0.5rem;
        background-color: $light;
        border: 1px solid $lightBorder;
        border-radius: 15px;
        transition: all 300ms;

        &.small {
            transform: scale(0.8);
        }

        &.dark {
            background-color: #fff;
            border-color: $dark;
        }

        &.checked {
            background-color: $lightPurple1;
            position: relative;
            border-color: $lightPurple1;

        }
    }

    input {
        margin: 0 8px 0 0;
        width: 20px;
        height: 20px;

        &:checked {
            accent-color: $lightPurple1;
        }
        
    }
}