@import 'variables.scss';

.containerNegotiations {
  background-color: white;
  padding-bottom: 20px;
  // width: 100%;
  // height: 100%;

  .drawer_filter {
    width: 370px;

    .drawer_input {
      padding: 15px;

      input {
        background-color: $white;
      }
    }
  }

  .textHead {
    background-color: white;
    padding-left: 30px;
    padding-top: 30px;

    p {
      font-weight: bold;
      font-size: 25px;
      padding-bottom: 10px;
    }
  }

  div:has(.cards) {
    position: relative !important;
  }

  .cards {
    display: block;
    align-items: flex-start;
    white-space: nowrap;
    height: 100%;
    // display: flex;
    // overflow-x: scroll;
    // background-color: #5A00FF;
  }
}

.deleteMessage {
  display: block;
  padding: 0 8px;
}