@import 'variables.scss';

.fieldItem {
  margin-bottom: 2rem;

  h3 {
    font-size: 20px;
    margin-bottom: 12px;
    font-weight: bold;
    // color: $dark;
    margin: 1rem 0 0.5rem;
    // font-size: 18px;
    // font-weight: 600;
  }

  svg {
    margin-bottom: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  textarea {
    width: 90%;
    max-width: 100%;
  }

  .withFeedback {
    p {
      color: $dark;
      font-size: 0.9em;
      margin: 0 0 0.5rem;
      word-wrap: break-word;
    }
  }

  .feedbackTrigger {
    text-decoration: none;
    color: $lightPurple1;
    font-size: small;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }
  }

  .subfields {
    display: flex;
    flex-wrap: wrap;

    .subfield {
      margin: 1.5rem 3rem 0 0;
      max-width: 300px;
      min-width: 225px;

      .info {
        display: flex;
        align-items: center;
        gap: 5px;

        .tooltipContainer {
          width: 500px;
        }
      }

      .titleRating {
        display: flex;
        gap: 10px;
      }

      h5 {
        color: $dark;
        font-weight: 500;
        font-size: 0.9em;
        margin: 0 0 0.5rem;
      }

      svg {
        margin-right: 0.3rem !important;
      }
    }
  }
}
