@import 'variables.scss';

.avatar {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: $light;
    outline: none;
    transition: all 300ms linear;
    border: 2px solid #ffffff;
    background-size: cover;
    font-weight: 500;

    &.professionalHeader {
        width: 80px;
        height: 80px;
        background-color: $lightPurple3;
        font-weight: 600;
    }

    &.connectionsAvatar {
        background-color: $lightPurple3;
        font-weight: 600;
    }

    &.noBorder {
        border: none;
    }

    &.noRadius {
        border-radius: 0;
        background-color: transparent;
    }

    &.textAvatar {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $lightPurple1 !important;
        border-radius: 100%;
    }

    &.hoverable {
        &:hover {
            cursor: pointer;
            box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.2);
        }
    }
}
