@import 'variables.scss';

.agencyContent {
  margin-top: 20px;
  font-family: 'Barlow';
  font-weight: 600;
  font-size: 16px;

  h5 {
    margin: 0 0 6px 0;
    color: $black;
    font-size: 14px;
    font-weight: 400;
  }

  .withoutAgency {
    display: flex;
  }

  .agencyName {
    display: flex;
    color: $darkPurple9;

    &:hover {
      cursor: pointer;
      color: $lightPurple2;
    }

    span {
      display: flex;
      font-weight: 500;

      button: {
        width: 10px;
        padding-left: 10px;
      }
    }
  }

  .agencyButtonIco {
    cursor: pointer;
    margin-left: 5px;
    height: 22px;
    width: 22px;
    color: $darkPurple9;
  }

  .agencyButton {
    display: flex;
    justify-content: flex-end;
    position: relative;
    right: 0;
    top: -36px;
    margin-bottom: -36px;

    .button {
      display: flex;
      background-color: $white;
      color: $lightPurple1;
      border: 1px solid $lightPurple1;
      font-size: 14px !important;
      font-weight: 600;
      height: 40px;

      .ico {
        position: relative;
        right: 10px;
        font-size: 17px;
      }

      &:hover {
        background-color: $white;
        color: $lightPurple2;
        border: 1px solid $lightPurple2;
      }
    }
  }

  .agent_message {
    width: 170px;
    height: 40px;
    padding: 10px 15px;
    margin-top: 10px;
    background-color: $lightPurple1;
    border-radius: 8px;
    flex-direction: row-reverse;
    gap: 15px;

    span {
      text-wrap: nowrap;
      font-size: 14px;
      font-weight: 600;
      color: $white;
    }

    svg {
      color: $white;
      width: 16px;
      height: 16px;
    }

    &:hover {
      background-color: $lightPurple2;
      box-shadow: none;
    }
  }
}
