@import 'variables.scss';

.container {
  display: flex;
  align-items: center;
  padding: 10px;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  & > div {
    margin: 0 5px;
    @media (max-width: 960px) {
      margin-bottom: 5px;
    }
  }
}

.FilterInputContainer {
  flex: 1;

  svg {
    color: $lightPurple1;
    transform: scaleX(-1);
    width: 14px !important;
  }
}
.FilterMenuContainer {
  display: flex;
  @media (max-width: 960px) {
    width: 100%;    
  }
}
.FilterMenu {
  flex: 1;
  background-color: #fff;
  border: solid 1px #bdbdbd;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 8px;
  flex: 1;
  color: #000;
  margin-right: 10px;

  &:last-child {
    margin-right: 0px;
  }
}

.otherChatClose {
  cursor: pointer;
  padding: 5px;
}

.otherChatText {
  & b {
    margin-left: 5px;
  }
}
