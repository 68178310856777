@import 'variables.scss';

.navigationBar {
  display: flex;
  flex: 0 0 auto;
  //align-items: flex-end;
  box-sizing: border-box;
  //height: 60px;
  overflow: auto;
  border-bottom: 1px solid $lighterBorder;
  padding: 0px 20px;
  background-color: $white;
}

.filled {
  color: $white;
  position: relative;
  height: 40px !important;
  left: 12px;
  padding-right: 20px;
  border-bottom: 1px solid $white !important;
  color: $white;
  font-weight: 500;
}
