@import 'variables.scss';

.activeFilters {
    padding: 10px 0;

    span {
        display: inline-block;
        margin: 2px 10px;
        font-size: 10pt;

        &:first-child {
            font-weight: 500;
            font-size: 14px;
            margin-right: 5px;
        }

        &.badge {
            background-color: #EEE5FF;
            border-radius: 8px;
            color: $lightPurple1;
            padding: 8px;
            margin-right: 15px;
        }

        span {
            vertical-align: middle;
        }

        .deleteIcon {
            cursor: pointer;
            vertical-align: middle;
            width: 20px;
            height: auto;
            color: $lightPurple1;

            &:hover {
                color: $lightGrey;
            }
        }
    }

    .removeFilters {
        color: $lightPurple1;
        white-space: nowrap;
        font-size: smaller;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }
}

.overlay {
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $lighterGrey;
    position: absolute;
    opacity: 0.6;
    top: 0;
    left: 0;
}