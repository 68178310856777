@import 'variables.scss';

.container {
    background-color: $darkPurple2;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .innerContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: $maxSmall) {
            width: 85%;
        }

        .cardContainer {
            display: flex;
            border-radius: 8px;
            width: 470px;
            overflow: hidden;
            padding: 20px 30px;

            @media (max-width: $maxSmall) {
                width: 300px;
            }

            .loginContainer {
                display: flex;
                min-width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: all 350ms;
                transform: translateX(-150%);
                height: 10px;

                @media (max-width: $maxSmall) {
                    min-width: 235px;
                }

                &.active {
                    transform: translateX(0%);
                    height: 290px;
                }
            }

            .recoveryContainer {
                display: flex;
                min-width: 100%;
                height: 200px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transform: translateX(150%);
                transition: all 350ms;

                @media (max-width: $maxSmall) {
                    min-width: 235px;
                }

                &.active {
                    transform: translateX(-100%);
                }
            }

            .recoverySendedContainer {
                display: flex;
                min-width: 100%;
                height: 200px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transform: translateX(150%);
                transition: all 350ms;

                @media (max-width: $maxSmall) {
                    min-width: 235px;
                }

                &.active {
                    transform: translateX(-200%);
                }

                .recoverySendedContent {
                    width: 100%;
                    padding-bottom: 30px;
                }

                .recoverySendedIcon {
                    display: block;
                    color: $lightPurple1;
                    height: 60px;
                    width: 60px;
                    margin: 25px auto;
                }
            }
        }

        .purshaseText {
            color: #fff;
            font-family: 'Barlow', sans-serif;
            font-size: 18px;
            font-weight: 300;
            line-height: 21.6px;
            text-align: center;
            margin: 2.0rem 0 0.9rem 0;
        }

        .purshaseLink {
            display: block;
            width: 410px;
        }

        .feedback {
            font-size: small;
            margin: -1rem 0 0.5rem;
            color: $danger;
        }

        .icon {
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.passwordLabel {
    display: flex;
    justify-content: space-between;
}

.inputContainer {
    margin: 0 0 0.5rem;
    line-height: 30px;
}

.emailInput {
    min-height: 25px;
    background-color: $white !important;
}

.passwordInput {
    min-height: 25px;
    background-color: $white !important;
}

.checkbox {
    margin-top: 18.5px;
    margin-bottom: 18.5px;
    width: 100%;
}

.passwordContainer {
    width: 100%;
    position: relative;
}

.link {
    font-family: sans-serif;
    color: $lightPurple1;
    font-size: 12.8px;
    font-weight: 500;
    transition: color 350ms;

    &:hover {
        cursor: pointer;
        color: $lightPurple1;
    }
}

.spacer {
    margin-top: 100px;
    background-color: transparent;
}

.toastContent {
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;

    svg {
        margin-right: 0.5rem;
        width: 15px;
        height: 15px;
    }
}

.loading {
    margin-bottom: 1.5rem;
}

.text {
    color: #FFFFFF;
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    width: 700px;
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    text-align: center;

}

.textl {
    margin: 23px;
    margin-bottom: 38px;
}

.languageButton {
    position: fixed;
    top: 20px;
    right: 30px;

    & > div {
        margin: 0;
    }
}

.containerAlreadyLogged {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.7);

    .innerContainerAlreadyLogged {
        width: 641px;
        height: 373px;
        text-align: center;
        margin: auto;
        background-color: $white;
        border-radius: 8px;

        .closeButton {
            display: block;
            margin: 10px 10px 1px auto;
            font-size: 30px;
            color: $lightPurple1;
            cursor: pointer;
        }

        .textAlreadyLogged {
            line-height: 24px;
            font-size: 16px;
            font-weight: 500;
            padding: 21px 0 24px 0;
            color: $gray2;

            h5 {
                padding: 0;
                margin: 0 0 12px 0;
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
                color: $black;
            }
        }

        .buttonsAlreadyLogged {
            display: flex;
            justify-content: center;

            button {
                width: auto;
                font-size: 14px;
                font-weight: 700;
                padding: 10px 30px;
                margin: 0 10px;
            }
        }
    }
}