@import 'variables.scss';

.container {
  width: 100%;

  .titulo {
    margin-top: 30px;
    margin-left: 30px;

    .filterBar {
      margin-top: 10px;
    }
  }

  .content {
    width: 100%;
    display: grid;
    grid-template-columns: 8% 77% 15%;
    margin-top: 20px;
  }

  .filterContainer {
    text-align: left;
    background-color: $white;
    display: flex;
    align-items: center;

    .menu {
      background-color: $white;
      border: 1px solid $lightBorder;
      font-size: 12px;
      font-weight: 500;
      padding: 0 0.95rem;
      height: 37px;
      margin-left: 10px;

      @media (max-width: $maxSmall) {
        margin-bottom: 10px;
      }
    }

    .menu:first-child {
      margin-left: 0px;
    }
  }
}
