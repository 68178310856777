@import 'variables.scss';

.spinner {
  padding: 20px;
  text-align: center;
}

.card {
  padding: 0;
  overflow-y: scroll;
  height: 600px;
  border: 1px solid #bdbdbd;

  @media (max-width: $maxSmall) {
    width: calc(100vw - 30px);
    max-height: calc(100vh - 70px);
    overflow-y: auto;
  }

  .nothingFound {
    padding: 1rem;
    min-width: 100%;
    color: $darker;
    white-space: pre;

    @media (max-width: $maxSmall) {
      font-size: 15px;
    }
  }
}
