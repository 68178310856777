@import 'variables.scss';

.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  padding: 10px 5px;
  margin-top: 25px;

  &.typeSent {
    flex-direction: row-reverse;

    & .content {
      background: #eee5ff;
      margin-right: 0px;
      border-radius: 8px 0px 8px 8px;
    }

    & .content::before {
      content: '';
      position: absolute;
      top: 0;
      //right: -30px;
      //height: 30px;
      width: 30px;
      background: inherit;
      //clip-path: polygon(100% 0%, 0% 100%, 0% 0%);
    }
  }

  &.typeReceived {
    flex-direction: row;

    & .content {
      background: #f2f2f2;
      margin-left: 0px;
      border-radius: 0px 8px 8px 8px;
    }

    & .content::before {
      content: '';
      position: absolute;
      top: 0;
      left: -30px;
      height: 30px;
      width: 30px;
      background: inherit;
      //clip-path: polygon(100% 0%, 100% 100%, 0% 0%);
    }
  }

  & .avatar {
    margin: 0 15px;
    width: 50px;

    .clubContainer {
      position: relative;
      height: 50px;
      width: 50px;
      bottom: 25px;

      .club {
        position: absolute;
        height: auto;
        width: 20px;
        bottom: -3px;
        right: -3px;
        border-radius: 0;
        font-weight: 600;
        background-color: transparent;
      }
    }
  }

  .checkboxSelected {
    align-self: center;
    margin: 0 8px;
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 5px;
    max-width: 400px;
    color: $darker;
    padding: 15px 15px 10px 15px;
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 500;

    .name_container {
      display: flex;

      .name {
        font-weight: 600;
        color: $black;
        margin: 0;
        text-transform: capitalize;
      }
    }

    .photo_container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .photo {
        margin: 10px 0;
        height: 240px;
        width: 400px;
        object-fit: contain;
        align-self: center;
      }
    }

    .file_container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .file {
        padding: 10px 0;
        font-weight: 500;

        svg {
          margin-right: 5px;
        }
      }
    }

    .message {
      overflow-wrap: break-word;

      i {
        color: $purple7;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-top: 3px;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
      }

      a {
        color: $darkPurple9;
        font-weight: 500;

        &:hover {
          cursor: pointer;
        }

        &:visited {
          color: $darkPurple9;
        }
      }

      .extraInfoAthlete {
        margin: 2px 0 8px 0;
        box-shadow: none;
        background-color: $white;
        border: solid 1px $lightBorder;
      }

      .extraInfoTeam {
        display: flex;
        width: auto;
        margin: 2px 0 8px 0;
        background-color: $white;
        border: solid 1px $lightBorder;
        padding: 0.5rem;
        align-items: center;
        border-radius: 8px;

        .clubInfo {
          margin-left: 15px;

          h1 {
            color: #5a00ff;
            font-size: 16px;
            font-weight: 500;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          span {
            color: #363636;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1;
          }

          img {
            box-shadow: 1px 1px 3px $lighterBorder;
            max-width: 1.4em;
          }
        }
      }
    }

    .hour {
      position: relative;
      right: -370px;
      bottom: 6px;
      font-size: 12px;
    }
  }
}