@import 'variables.scss';

.container {
  border-bottom: 1px solid $lighterBorder;

  .container_row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .athleteCard {
      display: flex;
      // flex: 1;
      flex-direction: row;
      align-items: center;
      position: relative;
      text-decoration: none;
      padding: 10px 5px;
      background: #fff;

      & .left {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        width: 80px;

        .clubContainer {
          display: flex;

          .athleteImg {
            img {
              width: 60px;
              height: 60px;
            }
          }
        }
      }

      & .center {
        display: flex;
        flex: 1;
        flex-direction: column;

        .name {
          color: $black;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
        }

        .completeName {
          color: $darker;
          font-size: 14px;
        }

        .info {
          display: flex;
          padding-top: 3px;

          .address {
            display: flex;
            align-items: center;
          }

          & > * {
            margin-right: 8px;
          }

          img {
            width: 20px;
            border-radius: 2px;
          }

          span {
            color: $darker;
            font-size: 12px;
          }
        }
      }
    }

    .right {
      display: flex;
      align-self: center;
      margin-right: 20px;
    }
  }
}

.view_club_button {
  width: 200px;
  height: 40px;
  padding: 10px;
  background-color: $white;
  border: 2px solid $lightPurple1;
  border-radius: 8px;
  flex-direction: row-reverse;
  gap: 10px;

  span {
    font-size: 14px;
    font-weight: 700;
    color: $lightPurple1;
  }

  img {
    filter: invert(80%) sepia(100%) saturate(7070%) hue-rotate(255deg) brightness(100%) contrast(126%);
    width: 25px;
    height: 25px;
  }
}

.spinner {
  position: relative;
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  top: 40px;
}
